import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../constant/const.js';
import axios from 'axios';
import useUserStore from '../../store/UserStore.js';
import { t } from '../Language/Translator.js';

const Country = ({onCountrySelect, selectedvaluecountry}) => {

    const [countries, setCountries] = useState([]);
    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    useEffect(() => {
      const fetchCountries = async () => {
        const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": token,
            },
        };
        const response = await axios.get(`${BASE_URL}user/getCountry`, config);
      
        setCountries(response.data || []);
      };
  
      fetchCountries();
    }, []);

  return (
    <select className="form-control" value={selectedvaluecountry} onChange={(e) => {
      onCountrySelect(e.target.value);
    }}>
    {!selectedvaluecountry ? (
      <option value="">{t("addressdropdown.selectcountry")}</option>
    ) : null}
      {countries.map((country) => (
        <option key={country.id} value={country.id.toString()}>
          {country.name}
        </option>
      ))}
    </select>
  )
};

export default Country