import React, { useState } from 'react'
import { getArchivedTickets, restoreArchivedTickets } from '../../services/ticket_api';
import { MdRestore } from "react-icons/md"
import Pagination from '../commonForm/Pagination';
import { format } from 'date-fns';
import Loader from '../commonForm/Loader';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useUserStore from '../../store/UserStore';
import { toast, ToastContainer } from 'react-toastify';


const ArchivedTicket = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const queryClient = useQueryClient();
    const user_data = useUserStore((state) => state.user);
    const token = user_data;

    const { data: ticketsToDisplay, isLoading, error } = useQuery({ queryKey: ['tickets(archived)', currentPage], queryFn: () => getArchivedTickets(token, currentPage) })

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        queryClient.invalidateQueries({ queryKey: ['tickets(archived)', pageNumber] });
    };

    const { mutate: mutateArchivedTickets, isPending: isRestoring } = useMutation({
        mutationFn: (id) => restoreArchivedTickets(token, id),
        onSuccess: function (data) {
            queryClient.invalidateQueries({ queryKey: ['tickets(archived)'] });
            toast.success("Ticket restored successfully");
        },
        onError: function (error) {
            toast.error(error.message);
        },
    });

    return (
        <>
            <ToastContainer />
            <div className="top_section">
                <div className="pagesNamelink">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                            <li className="breadcrumb-item"><a href="#">Ticket</a></li>
                            <li className="breadcrumb-item"><a href="ticket.html">Archived Tickets</a></li>

                        </ol>
                    </nav>
                    <h4 className="text-white">Archived Tickets</h4>
                </div>
                {/* <div className='flex gap-4'>
                <Link to={'/archivedTickets'} className="delete_btn no-underline"><i className="fa-solid fa-trash me-2"></i>Archived Tickets</Link>
                <Link to={'/addTicket'} className="main_btn no-underline"><i className="fa-solid fa-plus"></i> Add Ticket</Link>
                </div> */}
            </div>
            <div className="mainSection">
                {!error &&
                    <>
                        <div className="mainTable table-responsive">

                            {isLoading && <Loader />}
                            <table id="example" className="table table-striped table-dark">
                                {ticketsToDisplay &&
                                    <>
                                        <thead>
                                            <tr>
                                                <th>Ticket ID</th>
                                                <th>Alert</th>
                                                <th>Customer</th>
                                                <th>Severity</th>
                                                <th>Analysts</th>
                                                <th>Status</th>
                                                <th>Timestamp</th>
                                                <th>Destination IP</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ticketsToDisplay.data.length > 0 && ticketsToDisplay.data.map((ticket, index) => {
                                                return (


                                                    <tr key={ticket.ticket_uuid}>
                                                        <td>{ticket.ticketId}</td>
                                                        <td>{ticket.alert}</td>
                                                        <td>{ticket.customer_first_name + " " + ticket.customer_last_name}</td>
                                                        <td>
                                                            <div className={`sevBtn Sev${ticket.severity}`}>{ticket.severity}</div>
                                                        </td>
                                                        <td>{ticket.analyst_first_name + " " + ticket.analyst_last_name}</td>
                                                        <td className={`status${(ticket.status).charAt(0).toUpperCase() + ticket.status.slice(1)}`}>{ticket.status}</td>
                                                        <td>{format(ticket.timestamp, "MM/dd/yyyy hh:mm a") || 'N.A'}</td>
                                                        <td>{ticket.destination_ip}</td>
                                                        <td>
                                                            <div className="actionBtn">
                                                                <button onClick={() => mutateArchivedTickets(ticket.ticket_uuid)} disabled={isRestoring}>
                                                                <MdRestore style={{ fontSize: '25px', color: '#1E90FF'}}/>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                );
                                            })}
                                            {ticketsToDisplay.data.length == 0 && <p className='text-center text-white mt-4'>No matching results found !</p>}

                                        </tbody>
                                    </>
                                }
                            </table>

                        </div>
                        {ticketsToDisplay?.data.length>0 &&  
                        <div>
                            <Pagination
                                itemsPerPage={10}
                                totalItems={ticketsToDisplay?.pagination?.totalItems}
                                paginate={paginate}
                                currPage={currentPage}
                            />
                        </div>
                        }
                    </>
                }
                {error &&
                    <p className='text-center text-white mt-4'>Something went wrong. Please try again later !</p>
                }
            </div>
        </>
    )
}

export default ArchivedTicket