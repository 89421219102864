import React, { useState } from 'react';
import { BASE_URL } from '../../constant/const.js';
import Loader from '../commonForm/Loader.js';

import { default as RightMenu } from "./RightMenu.js";
import logo from "../../assets/images/logo.png";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setemail] = useState('');
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(BASE_URL + 'auth/send-resetPasswordLink', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            if (data.status === 'success') {
                setLoading(false);
                toast.success(data.message);
            } else {
                toast.warning(data.message);
            }
        } catch (error) {
            toast.warning(error.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <body className="login_bg">
        <ToastContainer/>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 login_wrapper">
                        <div className="login-outer">
                            <div className="login_box ">
                                <div className="Main_logo text-center">
                                    <img src={logo} alt="logo image" className="logo_login img-fluid" />
                                </div>
                                <form onSubmit={handleSubmit} className="w-100">
                                    <h2 className="login_title">Forgot Password</h2>
                                    <div className="mb-xxl-4 mb-3">
                                        <label for="exampleInputEmail1" className="form-label">E Mail</label>
                                        <div className="position-relative">
                                            <input type="email" className="form-control" placeholder="Username"
                                                id="email"
                                                name="email"
                                                onChange={(event) => setemail(event.target.value)}

                                            />
                                        </div>

                                    </div>

                                    <button type="submit"
                                        class={`login_btn mt-3 mb-xxl-3 mb-xl-3 mb-lg-1 mb-md-2 mb-3 text-white ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={loading}
                                    >
                                        Request Reset Link</button>
                                    {loading ? <Loader /> : ''}
                                    <div className="d-flex align-items-center justify-content-end mb-xxl-3 mb-0">
                                        <Link className="forgot_a" to="/">
                                            Back to Login
                                        </Link>
                                    </div>
                                 

                                </form>

                            </div>
                        </div>
                    </div>
                    <RightMenu />
                </div>
            </div>

        </body>
    )
}

export default ForgotPassword