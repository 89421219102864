import React, { useEffect, useState } from 'react'
import MessageContainer from './MessageContainer';
import { FaCircleXmark, FaRegCircleXmark } from "react-icons/fa6"

const ChatModal = ({ isOpen, onClick, chatId  }) => {
//console.log('ticket_idticket_idticket_id',chatId );

    const [ticketid, setTicketId] = useState(chatId);

    useEffect(() => {
        //console.log('useEffect ran');
    }, [])

    // const handleSendMsg = (message) => {
    //     setMessages((prevMessage) => [...prevMessage, message])
    //     // postChatMsg(message, socket);

    // }

    return (
        <>
            {isOpen && (
                <div className="z-50 fixed inset-0 bottom-0 flex items-center justify-center md:justify-end backdrop-blur-[1px]">
                    <div className="bg-black md:w-full max-w-md rounded-lg shadow-lg border-2 border-slate-700 flex flex-col justify-between sm:me-5">
                        <div className="flex gap-2 items-center border-b border-slate-700 px-3 py-2">

                            <div className='flex flex-col flex-1'>
                                <h2 className="m-0 text-lg font-semibold text-slate-50">Ticket ID: {chatId}</h2>
                                <p className='m-0 text-green-500'>Online</p>
                            </div>
                            <button type="button"
                                onClick={onClick}
                                className="text-gray-500 hover:text-gray-700 flex-none"
                            >
                                <FaCircleXmark className='rounded-full' style={{ color: '#ca0000', background: 'white', fontSize: '30px' }} />
                            </button>
                        </div>
                        <MessageContainer chatId={chatId}></MessageContainer>
                    </div>
                </div>
            )}
        </>
    )
}

export default ChatModal