import React, { useEffect, useState } from 'react';
import EditIcon from "../../../assets/images/Icons/edit.svg"
import DeleteIcon from "../../../assets/images/Icons/delete.svg"
import { deleteVLANinfo, getCustomerInfraListings, getOneCustomerInfraListing, saveVLANinfo } from '../../../services/customer_infrastructure_api';
import useUserStore from '../../../store/UserStore';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Loader from '../../commonForm/Loader';
import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { t } from '../../Language/Translator';


const Step1 = ({ nextStep }) => {
    const [editVlanID, setEditVlanID] = useState(null);
    const queryClient = useQueryClient();

    const { c_id } = useParams();
    //console.log(c_id);

    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    // const userData = jwtDecode(token)?.user_data;

    const handleStep1Submit = (e, EditID = null) => {
        e.preventDefault();
        const formdata = new FormData(e.target);
        if (EditID) {
            formdata.append("id", EditID);
        }
        formdata.append("c_id", c_id);
        // else if (c_id) {
        //     formdata.append("c_id", c_id);
        // } else {
        //     formdata.append("c_id", userData?.userId);
        // }
        const jsonObject = {};
        formdata.forEach((value, key) => {
            jsonObject[key] = value;
        })

        mutateStep1(jsonObject);

    };

    const { data: customerInfraData, isLoading, error } = useQuery({ queryKey: ['customerInfraData', c_id], queryFn: () => getOneCustomerInfraListing(token, c_id) })

    const { mutate: mutateStep1, isPending: isAdding } = useMutation({
        mutationFn: (jsonObj) => saveVLANinfo(token, jsonObj),
        onSuccess: function (data) {
            // //console.log(data);
            if (data.vInfoExist) {
                document.getElementById("EDITvlanForm")?.reset();
                hideModal();
            }
            document.getElementById("ADDvlanForm")?.reset();
            toast.success("VLAN info successfully saved");
            queryClient.invalidateQueries({ queryKey: ["customerInfraData"] });

        },
        onError: function (error) {
            toast.error(error.message);
        },
    });

    async function deleteVLANalert(ID) {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this information!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#19C489',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sure !'
        });

        if (result.isConfirmed) {
            try {
                await deleteVLANinfo(token, ID);
                await queryClient.invalidateQueries({ queryKey: ['customerInfraData'] });
                Swal.fire('Deleted!', 'Deleted successfully', 'success');
            } catch (error) {
                Swal.fire('Error!', error.message, 'error');
            }
        }
    }

    const hideModal = () => {
        const modalElement = document.getElementById('editVLAN');
        const modalInstance = Modal.getInstance(modalElement) || new Modal(modalElement);
        modalInstance.hide();
    };

    return (
        <>
            <h3 className="wizardHead">{t("customerinfra.vlaninfo")}</h3>
            <div >
                <form className="row g-3" id='ADDvlanForm' onSubmit={handleStep1Submit}>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("customerinfra.vlanname")}</label>
                        <input type="text" placeholder={t("customerinfra.vlanname")} className="form-control" name='vlan_name' />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("customerinfra.vlanipmask")}</label>
                        <input type="text" placeholder={t("customerinfra.vlanipmask")} className="form-control" name='ip_add' />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <label className="form-label">{t("customerinfra.description")}</label>
                        <input type="text" placeholder={t("customerinfra.description")} className="form-control" name='discription' />
                    </div>
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                        <span className='me-3'>{isAdding && <Loader />}</span>
                        <button type='submit' className="secondary_btn" disabled={isAdding}>{t("customerinfra.add")}</button>
                    </div>
                </form>
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                    {isLoading && <Loader />}
                    <div className="mainTable table-responsive">
                        {!isLoading && !error && customerInfraData?.vlaninfo?.length > 0 &&
                            <table className="table table-striped table-dark">
                                <thead>
                                    <tr>
                                        <th>{t("customerinfra.vlanname")}</th>
                                        <th>{t("customerinfra.vlanipmask")}</th>
                                        <th>{t("customerinfra.description")}</th>
                                        <th>{t("customerinfra.actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customerInfraData?.vlaninfo?.map((data, i) => {
                                        return (
                                            <tr>
                                                <td>{data.vlan_name}</td>
                                                <td>{data.ip_add}</td>
                                                <td>{data.v_dis}</td>
                                                <td>
                                                    <div className="actionBtn flex justify-start gap-2 items-center">
                                                        <button className="Edit m-0" data-bs-toggle="modal" data-bs-target="#editVLAN" onClick={() => setEditVlanID(data.id)}><img
                                                            src={EditIcon} alt="Edit" />
                                                        </button>
                                                        <button type='button' className="Delete" onClick={() => deleteVLANalert(data.id)}>
                                                            <img src={DeleteIcon} alt="Delete" /> </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}

                                </tbody>
                            </table>
                        }
                        {customerInfraData?.vlaninfo?.length == 0 &&
                            <p className='text-center text-white mt-4'>{t("customerinfra.noresultfound")}</p>
                        }
                        {error &&
                            <p className='text-center text-white mt-4'>{t("customerinfra.somethingwentwrong")}</p>
                        }
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
                {/* <a className="cancel_btn previous me-3" id="bservice">Previous</a> */}
                <button className="secondary_btn next" id="service" type='button' onClick={nextStep} disabled={isLoading}>{t("customerinfra.next")}</button>
            </div>


            <EditVlanModal token={token} id={editVlanID} handleStep1Submit={handleStep1Submit} isAdding={isAdding}></EditVlanModal>

        </>
    )
}

export default Step1;

// /////////////////// Edit Modal component 

const EditVlanModal = ({ token, id, handleStep1Submit, isAdding }) => {
    const { c_id } = useParams();
    const { data: customerInfraData, isLoading, error } = useQuery({ queryKey: ['customerInfraData', c_id], queryFn: () => getOneCustomerInfraListing(token, c_id), enabled: !!id })

    let currentVlANrow = null;
    if (customerInfraData && id) {
        currentVlANrow = customerInfraData.vlaninfo.find((obj) => obj.id == id)
    }
    return (
        <div className="modal fade addModel" id="editVLAN" tabIndex="-1" aria-labelledby="editLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content text-wrap">
                    <div className="modal-header">
                        <h5 className="modal-title" id="viewLabel">{t("customerinfra.editVLAN")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {

                            <div className="editVLAN row g-3">
                                {isLoading && <Loader />}

                                {customerInfraData &&
                                    <>
                                        <form className="row g-3" id='EDITvlanForm' onSubmit={(e) => handleStep1Submit(e, id)}>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <label className="form-label">{t("customerinfra.vlanname")}</label>
                                                <input type="text" placeholder={t("customerinfra.vlanname")} className="form-control" name='vlan_name' defaultValue={currentVlANrow?.vlan_name} />
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <label className="form-label">{t("customerinfra.vlanipmask")}</label>
                                                <input type="text" placeholder={t("customerinfra.vlanipmask")} className="form-control" name='ip_add' defaultValue={currentVlANrow?.ip_add} />
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                <label className="form-label">{t("customerinfra.description")}</label>
                                                <input type="text" placeholder={t("customerinfra.description")} className="form-control" name='discription' defaultValue={currentVlANrow?.v_dis} />
                                            </div>
                                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                                                <span className='me-3'>{isAdding && <Loader />}</span>
                                                <button type='submit' className="secondary_btn" disabled={isAdding}>{t("customerinfra.update")}</button>
                                            </div>
                                        </form>
                                    </>
                                }
                                {error && <p>{t("customerinfra.somethingwentwrong")}</p>}

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}