import { toast } from "react-toastify";
import { BASE_URL } from "../constant/const";

export async function getCustomers(token){
    try {

        const customerResponse = await fetch(`${BASE_URL}customer/getAllCustomers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!customerResponse.ok) {
            throw new Error(`HTTP Error: ${customerResponse.status}-${customerResponse.statusText}`);
        }
        const customerData = await customerResponse.json();
        if (customerData.status == 'error') {
            throw new Error(customerData.message);
        }
        return customerData;
    }
    catch (error) {
        toast.error('An unexpected error occured '+error.message);
        return null;
    }
}
