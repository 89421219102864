
// import React, { useEffect, useState } from 'react';
// import logo from "../../assets/images/dummypro.jpg";
// import Logout from '../Auth/Logout';
// import { ToastContainer, toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';
// import useUserStore from '../../store/UserStore';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import { BASE_URL, url } from '../../constant/const';
// import { t } from '../Language/Translator';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLanguage } from '@fortawesome/free-solid-svg-icons';



// const Header = ({ toggleSidebar }) => {

//   const userInfo = useUserStore((state) => state.userInfo);
//   const isNotCustomer = userInfo?.user_type !== 'customer';

//   const [language,setLanguage] = useState('en');
//   const [loading, setLoading] = useState(false);
//   const [profileImage, setProfileImage] = useState(logo);
//   const user_data = useUserStore((state) => state.user);
//   const token = user_data;


//   useEffect(()=>{
//     const storedLanguage = localStorage.getItem('language');
//     if(storedLanguage) {
//       setLanguage(storedLanguage);
//     }
//   },[]);


//   const handleLanguageChange = (lang)=> {
//     setLanguage(lang);
//     localStorage.setItem('language',lang);
//   }


//   async function fetchData() {

//     try {

//       setLoading(true);

//       const config = {
//         headers: {
//           'Content-type': 'application/json',
//           Authorization: token,
//         },

//       };

//       const response = await axios.get(`${BASE_URL}common/profile-view`, config);
//       toast.success(response.data.message);

//       const data = response.data.data;
//       if (data.profilePic) {
//         // setProfileImage(`${BASE_URL}${data.profilePic}`);
//         setProfileImage(data.profilePic);
//       } else {
//         setProfileImage(logo); // Fallback to a default logo if no image
//       }
//       setLoading(false);

//     } catch (error) {

//       if (error.response) {
//         toast.warning(error.response.data.message);
//       } else {
//         toast.error(error.message);
//       }
//       setLoading(false);

//     }

//   };

//   useEffect(() => {
//     fetchData(); // Fetch data on component mount
//   }, []);

//   return (
//     <div className="">
//       <header>

//         <div className="sidebar_close_btn">
//           <i className="fa-solid fa-bars text-white" id="sidebar_toggle-btn" onClick={toggleSidebar}></i>
//         </div>
//         <div className="rightSide cursor-pointer">
//           <ToastContainer />
//           <div className="dropdown">
//             <div className="profileDrop" id="dropdownMenuButton1" data-bs-toggle="dropdown">
//               <i className="profileImg text-white h-10 w-10"><FontAwesomeIcon icon={faLanguage} /></i>
//             </div>
//             <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
//               <li><Link className="dropdown-item" to=""onClick={() => handleLanguageChange('en')}>{t("languagedropdown.eng")}</Link></li>
//               <li><Link className="dropdown-item" to=""onClick={() => handleLanguageChange('es')}>{t("languagedropdown.span")}</Link></li>
//             </ul>
//           </div>
//           <div className="dropdown">
//             <div className="profileDrop" id="dropdownMenuButton1" data-bs-toggle="dropdown">
//               <img className="profileImg" src={`${url}${profileImage}` ||logo} alt="selectimage" />
//               <p className='text-wrap capitalize'>{userInfo.first_name}
//                 <br />
//                 <span className="role capitalize">
//                   {isNotCustomer && userInfo.user_type === 'super_admin' ? 'Super Admin' : ''}
//                   {isNotCustomer && userInfo.user_type === 'admin' ? 'Admin' : ''}
//                   {isNotCustomer && userInfo.user_type === 'analyst' ? 'Analyst' : ''}
//                 </span>
//               </p>
//             </div>
//             <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
//               <li><Link className="dropdown-item" to="/viewprofile">{t("dashboardheaderdropdown.profile")}</Link></li>
//               <li><Link className="dropdown-item" to="/updatepassword">{t("dashboardheaderdropdown.password")}</Link></li>
//               <li><a className="dropdown-item" href="#" onClick={() => Logout()}>{t("dashboardheaderdropdown.logout")}</a></li>
//             </ul>
//           </div>

//         </div>
//       </header>

//     </div>


//   )
// }

// export default Header;




import React, { useEffect, useState } from 'react';
import logo from "../../assets/images/dummypro.jpg";
import Logout from '../Auth/Logout';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useUserStore from '../../store/UserStore';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, url } from '../../constant/const';
import { t } from '../Language/Translator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';

const Header = ({ toggleSidebar }) => {
  const userInfo = useUserStore((state) => state.userInfo);
  const isNotCustomer = !['customer', 'secondary_customer'].includes(userInfo?.user_type);

  const [language, setLanguage] = useState('en');
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(logo);
  const [profiledata,setProfiledata] = useState();
  const user_data = useUserStore((state) => state.user);                 
  const token = user_data;

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setLanguage(storedLanguage); // Load language setting from localStorage
    }
  }, []);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    localStorage.setItem('selectedLanguage', lang); // Update localStorage with new language
    window.location.reload(); // Refresh the page to apply changes
  };

  // Fetch user profile data
  async function fetchData() {
    try {
      setLoading(true);
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: token,
        },
      };
      const response = await axios.get(`${BASE_URL}common/profile-view`, config);
      console.log(response,"RESPONSE")
      toast.success(response.data.message);
      const data = response.data.data;
      setProfiledata(data)
      if (data.profilePic) {
        setProfileImage(data.profilePic);
      } else {
        setProfileImage(logo); // Fallback to a default logo if no image is available
      }
      setLoading(false);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.error(error.message);
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  return (
    <div>
      <header>
        <div className="sidebar_close_btn">
          <i className="fa-solid fa-bars text-white" id="sidebar_toggle-btn" onClick={toggleSidebar}></i>
        </div>
        <div className="rightSide cursor-pointer">
          {/* <ToastContainer /> */}
          {/* <div className="dropdown">
            <div className="profileDrop" id="dropdownMenuButton1" data-bs-toggle="dropdown">
              <i className="profileImg text-white h-10 w-10"><FontAwesomeIcon icon={faLanguage} /></i>
            </div>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><Link className="dropdown-item" to="" onClick={() => handleLanguageChange('en')}>{t("languagedropdown.eng")}</Link></li>
              <li><Link className="dropdown-item" to="" onClick={() => handleLanguageChange('es')}>{t("languagedropdown.span")}</Link></li>
            </ul>
          </div> */}
          <div className="dropdown">
            <div className="profileDrop" id="dropdownMenuButton1" data-bs-toggle="dropdown">
              <img className="profileImg" src={`${url}${profileImage}` || logo} alt="profile" />
              <p className='text-wrap capitalize'>{userInfo.first_name}
                {/* <p className='text-wrap capitalize'>{profiledata.first_name}</p> */}
                {console.log(userInfo)}
                <br />
                <span className="role capitalize">
                  {isNotCustomer && userInfo.user_type === 'super_admin' ? 'Super Admin' : ''}
                  {isNotCustomer && userInfo.user_type === 'admin' ? 'Admin' : ''}
                  {isNotCustomer && userInfo.user_type === 'analyst' ? 'Analyst' : ''}
                </span>
              </p>
            </div>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><Link className="dropdown-item" to="/viewprofile">{t("dashboardheaderdropdown.profile")}</Link></li>
              <li><Link className="dropdown-item" to="/updatepassword">{t("dashboardheaderdropdown.password")}</Link></li>
              <li><a className="dropdown-item" href="#" onClick={() => Logout()}>{t("dashboardheaderdropdown.logout")}</a></li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;