import { toast } from "react-toastify";
import { BASE_URL } from "../constant/const";

export async function getAnalysts(token) {
    try {

        const userResponse = await fetch(`${BASE_URL}user/getAllUsers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!userResponse.ok) {
            throw new Error(`HTTP Error: ${userResponse.status}-${userResponse.statusText}`);
        }
        const userData = await userResponse.json();
        // await new Promise((resolve) => setTimeout(resolve, 1000))
        const analystData = userData.data.filter((item, index) => item.user_type === 'analyst')
        return analystData;
    }
    catch (error) {
        toast.error('An unexpected error occured ' + error.message);
        return null;
    }
}

export async function getAllUsers(token) {
    try {

        const userResponse = await fetch(`${BASE_URL}user/getAllUsers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!userResponse.ok) {
            throw new Error(`HTTP Error: ${userResponse.status}-${userResponse.statusText}`);
        }
        const userData = await userResponse.json();
        // await new Promise((resolve) => setTimeout(resolve, 1000))
       return userData;
    }
    catch (error) {
        toast.error('An unexpected error occured ' + error.message);
        return null;
    }
}

export async function checkActiveUser(userUUID) {
    try {
        const response = await fetch(`${BASE_URL}/common/user-status/${userUUID}`, {
            method: 'GET',
            // redirect:'follow'
        });
        const data = await response.json();
        console.log(data);
        
        if (response.ok && data.data.isActive == true) {
           return true;
        }
        else{
            return false;
        }
    }
    catch (error) {

    }
}