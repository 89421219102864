import English from "./English";
import Spanish from "./Spanish";


const translations = {
    en: English,
    es: Spanish,
};

const getLanguage = () => {
    const lang = localStorage.getItem("selectedLanguage");
    return lang ? lang : "en"; // Default to 'en' if none is found
};

export const t = (key) => {
    const lang = getLanguage();
    const keys = key.split(".");
    return keys.reduce((obj, k) => (obj && obj[k] !== undefined ? obj[k] : key), translations[lang] || key)
};