// import React, { useEffect, useState } from 'react'
// import { BASE_URL } from '../../../constant/const';
// import axios from 'axios';
// import useUserStore from '../../../store/UserStore';
// import { toast } from 'react-toastify';

// const ViewCustomer = ({ viewModalOpen, setViewModalOpen,customerUuid }) => {

//     const [customerData, setCustomerData] = useState([]);
//     const [locationData, setLocationData] = useState(null); // Initialize as null
//     //console.log(customerData, "CUSTOMER DATA")
//     const [loading, setLoading] = useState(true);

//     const customer_data = useUserStore((state) => state.user);
//     const token = customer_data;

//     useEffect(()=>{
//         if(customerUuid)
//         {
//             fetchData();
//         }
//     },[])

//  // -------------------------------------------------------VIEW CUSTOMER INFORMATION---------------------------------------


//     const fetchData = async () => {
//         try {
//             const config = {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': token,
//                 },
//             };
//             const response = await axios.get(`${BASE_URL}customer/view-customer/${customerUuid}`, config);
//             if (response.data && response.data.status === 'success') {
//                 const customerData = response.data.data[0]; // Assuming the first item is the customer data
//                 setLocationData(customerData.customerLocations || []);
//             } else {
//                 setLocationData([]);
//             }
//         } 
//         catch (error) 
//         {
//             toast.error(error.response ? error.response.data.message : error.message);
//         }
//     };











//     return (
//         <>
//             {viewModalOpen && (
//                 <div className="modal fade viewModel show" id="view" tabindex="-1" aria-labelledby="viewLabel" style={{ display: 'block' }} aria-modal="true" role="dialog">
//                     <div className="modal-dialog modal-xl modal-dialog-centered">
//                         <div className="modal-content">
//                             <div className="modal-header">
//                                 <h5 className="modal-title" id="viewLabel">View Customer</h5>
//                                 <button type="button" className="btn-close" onClick={() => setViewModalOpen(false)}></button>
//                             </div>

//                             <div className="modal-body">
//                                 {loading ? (
//                                     <p>Loading customer data...</p>
//                                 ) : customerData ? (
//                                     <div className="viewTicket row g-3">
//                                         <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12">
//                                             <h6>Company Name </h6>
//                                             <p>{customerData.first_name}</p>
//                                         </div>
//                                         <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12">
//                                             <h6>Address</h6>
//                                             <p>{customerData.last_name}</p>
//                                         </div>
//                                         <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12">
//                                             <h6>Address 2</h6>
//                                             <p>{customerData.last_name}</p>
//                                         </div>
//                                         <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12">
//                                             <h6>State</h6>
//                                             <p>{customerData.last_name}</p>
//                                         </div>
//                                         <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12">
//                                             <h6>City</h6>
//                                             <p>{customerData.last_name}</p>
//                                         </div>
//                                         <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12">
//                                             <h6>Zip code</h6>
//                                             <p>{customerData.last_name}</p>
//                                         </div>
//                                         <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12">
//                                             <h6>Country</h6>
//                                             <p>{customerData.last_name}</p>
//                                         </div>
//                                         <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12">
//                                             <h6>Email</h6>
//                                             <p>{customerData.last_name}</p>
//                                         </div>
//                                         <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12">
//                                             <h6>Phone Number</h6>
//                                             <p>{customerData.last_name}</p>
//                                         </div>
//                                         <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12">
//                                             <h6>Alternate Phone number</h6>
//                                             <p>{customerData.email}</p>
//                                         </div>

//                                     </div>
//                                 ) : (
//                                     <p>No customer data found.</p>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//             )}
//         </>
//     )
// }

// export default ViewCustomer



import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../constant/const';
import axios from 'axios';
import useUserStore from '../../../store/UserStore';
import { toast } from 'react-toastify';
import Loader from '../../commonForm/Loader';
import ToggleButton from '../../commonForm/ToggleButton';
import { t } from '../../Language/Translator';

const ViewCustomer = ({ viewModalOpen, setViewModalOpen, customerUuid }) => {
    const [customerData, setCustomerData] = useState(null);
    const [locationData, setLocationData] = useState([]); // Initialize as empty array
    const [contactInfoData, setContactInfoData] = useState([]);
    const [serviceContractInfo, setServiceContractInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState({ isActive: true });

    const customer_data = useUserStore((state) => state.user);
    const token = customer_data;


    const handleToggle = () => {
        setToggle(prevItem => ({ ...prevItem, isActive: !prevItem.isActive }));
    };

    useEffect(() => {
        if (viewModalOpen && customerUuid) {
            fetchData();
        } else {
            setLoading(false); // Reset loading when modal is closed
        }
    }, [viewModalOpen, customerUuid]);

    const fetchData = async () => {
        // setLoading(true);
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            };
            const response = await axios.get(`${BASE_URL}customer/view-customer/${customerUuid}`, config);
            if (response.data && response.data.status === 'success') {
                const customerData = response.data.data[0]; // Assuming the first item is the customer data
                setCustomerData(customerData); // Set customer data
                setLocationData(customerData.customerLocations || []); // Set location data
                setContactInfoData(customerData.customerContactInfo || []);
                setServiceContractInfo(customerData.customerServices || []);
            } else {
                setCustomerData(null);
                setLocationData([]);
                setContactInfoData([]);
                setServiceContractInfo([])
            }
        } catch (error) {
            toast.error(error.response ? error.response.data.message : error.message);
        }
    };
    if (!viewModalOpen) return null;

    return (
        <>
            {viewModalOpen && (
                <div className="modal fade viewModel show" id="view" tabIndex="-1" aria-labelledby="viewLabel" style={{ display: 'block' }} aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="viewLabel">{t("viewcustomer.viewcustomer")}</h5>
                                <button type="button" className="btn-close" onClick={() => setViewModalOpen(false)}></button>
                            </div>

                            <div className="modal-body">
                                {loading ? (
                                    <Loader />
                                ) : customerData ? (
                                    <div>
                                        {/* <Loader/> */}
                                        <h6 className='text-[#32CD32]'>{t("")}</h6>
                                        <div className="viewTicket row g-3">
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                <h6>{t("viewcustomer.companyname")}</h6>
                                                <p>{customerData.first_name}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                <h6>{t("viewcustomer.address")}</h6>
                                                <p>{customerData.address}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                <h6>{t("viewcustomer.address2")}</h6>
                                                <p>{customerData.address2}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                <h6>{t("viewcustomer.state")}</h6>
                                                <p>{customerData.stateInfo ? customerData.stateInfo.name : 'N/A'}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                <h6>{t("viewcustomer.city")}</h6>
                                                <p>{customerData.cityInfo ? customerData.cityInfo.name : 'N/A'}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                <h6>{t("viewcustomer.zipcode")}</h6>
                                                <p>{customerData.zip}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                <h6>{t("viewcustomer.country")}</h6>
                                                <p>{customerData.countryInfo ? customerData.countryInfo.name : 'N/A'}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                <h6>{t("viewcustomer.email")}</h6>
                                                <p>{customerData.email}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                <h6>{t("viewcustomer.phoneno")}</h6>
                                                <p>{customerData.phone}</p>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                <h6>{t("viewcustomer.alternatephoneno")}</h6>
                                                <p>{customerData.alternate_phone}</p>
                                            </div>
                                        </div>

                                        {/* Locations Information */}
                                        <h6 className="mt-4 text-[#32CD32]">{t("viewcustomer.customerlocations")}</h6>
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                                            <div className="mainTable table-responsive">
                                                <table id="example" className="table table-striped table-dark">
                                                    <thead>
                                                        <tr>
                                                            <th>{t("viewcustomer.locationtype")}</th>
                                                            <th>{t("viewcustomer.address")}</th>
                                                            <th>{t("viewcustomer.phoneno")}</th>
                                                            <th>{t("viewcustomer.email")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {locationData.length > 0 ? (
                                                            locationData.map(location => (
                                                                <tr key={location.id}>
                                                                    <td>{location.location_type}</td>
                                                                    <td>{location.address}</td>
                                                                    <td>{location.phone}</td>
                                                                    <td>{location.email}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center text-white">
                                                                    {t("viewcustomer.nolocation")}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>



                                        {/*Contact Information*/}
                                        <h6 className="mt-4 text-[#32CD32]">{t("viewcustomer.contactinformation")}</h6>
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                                            <div className="mainTable table-responsive">
                                                <table id="example" className="table table-striped table-dark">
                                                    <thead>
                                                        <tr>
                                                            <th>{t("viewcustomer.contactname")}</th>
                                                            <th>{t("viewcustomer.email")}</th>
                                                            <th>{t("viewcustomer.phoneno")}</th>
                                                            <th>{t("viewcustomer.alternatephoneno")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {contactInfoData.length > 0 ? (
                                                            contactInfoData.map(contact => (
                                                                <tr key={contact.id}>
                                                                    <td>{contact.contact_name}</td>
                                                                    <td>{contact.email}</td>
                                                                    <td>{contact.phone}</td>
                                                                    <td>{contact.alternate_phone}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center text-white">
                                                                    {t("viewcustomer.nocontact")}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* Service Contract Information  */}
                                        <h6 className="mt-4 text-[#32CD32]">{t("viewcustomer.servicecontract")}</h6>
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                                            <div className="mainTable table-responsive">
                                                <table id="example" className="table table-striped table-dark">
                                                    <thead>
                                                        <tr>
                                                            <th>{t("viewcustomer.servicecontract")}</th>
                                                            <th>{t("viewcustomer.startdate")}</th>
                                                            <th>{t("viewcustomer.enddate")}</th>
                                                            <th>{t("viewcustomer.status")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {serviceContractInfo.length > 0 ? (
                                                            serviceContractInfo.map(service => (
                                                                <tr key={service.id}>
                                                                    <td>{service.service_name}</td>
                                                                    <td>{new Date(service.service_start_date).toLocaleDateString('en-GB')}</td>
                                                                    <td>{new Date(service.service_end_date).toLocaleDateString('en-GB')}</td>
                                                                    <td>
                                                                        <ToggleButton
                                                                            item={{ isActive: service.service_status }}
                                                                            onToggle={service.isActive ? null : handleToggle} disabled={service.isActive} // Disable the toggle button if isActive is true
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center text-white">
                                                                    {t("viewcustomer.noservice")}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                ) : (
                                    <p className='text-white'>No customer data found.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ViewCustomer;