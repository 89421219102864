// import React from 'react';
// import Toggle from 'react-toggle';
// import 'react-toggle/style.css';


// const ToggleButton = ({ item, onToggle }) => {
  
//     return (
//       <Toggle
//       defaultChecked={item.isActive}
//       onChange={onToggle}
//       className="custom-toggle"
//       icons={false}
//     />
//     );
//   };
  
// export default ToggleButton



import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

const ToggleButton = ({ item, onToggle }) => {
    return (
        <Toggle
            checked={item.isActive}
            onChange={onToggle}
            className={`custom-toggle ${item.isActive ? 'disabled' : ''}`} // Add 'disabled' class if active
            icons={false}
            disabled={true} // Disable the toggle if the user is active
        />
    );
};

export default ToggleButton;