import React from 'react'
import { t } from '../Language/Translator'

function Footer() {
  return (
   
    <div>
       {t("footer.copyright")}
    </div>


  )
}

export default Footer