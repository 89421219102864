import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../commonForm/Loader';
import ViewIcon from "../../assets/images/Icons/view.svg"
import EditIcon from "../../assets/images/Icons/edit.svg"
import DeleteIcon from "../../assets/images/Icons/delete.svg"
import { Link, useNavigate } from 'react-router-dom';
import useUserStore from '../../store/UserStore.js';
import Pagination from '../commonForm/Pagination.js';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_URL } from '../../constant/const.js';
import ViewCustomer from './AddCustomer/ViewCustomer.js';
import ToggleButton from '../commonForm/ToggleButton.js';
import { t } from '../Language/Translator.js';


const CustomerList = () => {


  const [customerData, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false); // New loading state
  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [viewModalopen, setViewModalOpen] = useState(false);

  const [customerSearchData, setCustomerSearchData] = useState(null);
  const [customerId, setCustomerId] = useState('')
  // const customerId = localStorage.getItem('customerId');
  const [phoneNo, setPhoneNo] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [lastLoginDate, setLastLoginDate] = useState('');
  const [status, setStatus] = useState('')

  const navigate = useNavigate();

  const customer_data = useUserStore((state) => state.user);
  const token = customer_data;


  const paginate = async (pageNumber) => {
    setCurrentPage((pageNumber));
    if (customerSearchData) {
      await fetchSearchData();
      setCustomerSearchData(prev => ({ ...prev, page: currentPage }));
    }
  };

  useEffect(() => {
    console.log("Current Page:", currentPage);
    if (customerSearchData) {
      setLoading(true);
      fetchSearchData();
    }
    else {
      getAllCustomers();
    }
  }, [currentPage, customerSearchData])

  if (loading) {
    return <Loader />
  };





  //------------------------------GET ALL CUSTOMERS----------------------------------

  async function getAllCustomers() {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          "Authorization": token,
        },
      };
      const response = await axios.get(`${BASE_URL}customer/customers?page=${currentPage}&pageSize=${itemsPerPage}`, config);
      setData(response.data.data || []);
      setTotalItems(response.data.pagination.totalItems);
      setLoading(false);
    }
    catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };


  //----------------------------DELETE CUSTOMER RECORD---------------------------------

  async function handleDeleteCustomer(customerId) {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          "Authorization": token,
        },
      };
      const response = await axios.delete(`${BASE_URL}customer/delete-customer/${customerId}`, config);

      toast.success(response.data.message);
    }
    catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      }
      else {
        toast.error(error.message);
      }
    }
  };


  function deleteCustomeralert(customerId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this customer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#19C489',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteCustomer(customerId).then(() => {
          Swal.fire({
            title: 'Deleted!',
            text: 'Your customer has been deleted.',
            icon: 'success',
            confirmButtonColor: '#19C489',
          });
        });
        setData(prevData => prevData.filter(item => item.customerId !== customerId));
      }
    });
  }



  // ------------------------------------------SEARCH CUSTOMER DATA---------------------------------------

  async function fetchSearchData() {


    try {
      const searchPayload = {
        ...customerSearchData, page: currentPage
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      };
      const response = await axios.post(`${BASE_URL}customer/search-customer?page=${currentPage}&pageSize=${itemsPerPage}`, searchPayload, config);
      //console.log("API RESPONSE", response.data)
      setData(response.data.data || []);
      setTotalItems(response.data.pagination.totalItems || 0);
      setLoading(false);
    }
    catch (error) {
      setError(error.message);
      setLoading(false);

    }

  };


  // --------------------------------------------------SEARCH CUSTOMER--------------------------------



  const handleSearch = (event) => {
    event.preventDefault();
    const SearchData = {
      customer_id: customerId,
      phone: phoneNo,
      email: email,
      company_name: companyName,
      last_login: lastLoginDate,
      status: status || '',

    };
    setCurrentPage(1)
    setCustomerSearchData(SearchData)
  };

  function handleClearFilter(e) {
    e.preventDefault();
    setCustomerId('');
    setPhoneNo('');
    setEmail('');
    setCompanyName('');
    setLastLoginDate('');
    setStatus('');
    setCustomerSearchData(null);

    // Optionally reset the current page to 1 if you want to start from the first page
    setCurrentPage(1);
    getAllCustomers();
  }


  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  // const handleToggle = () => {
  //   setToggle(prevItem => ({ ...prevItem, isActive: !prevItem.isActive }));
  // };

  const handleToggle = async (customerId) => {
    const targetCustomer = customerData.find(customer => customer.customerId === customerId);
    const newStatus = !targetCustomer.isActive; // Toggle active state

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          "Authorization": token,
        },
      };

      // Update status to server
      const response = await axios.put(`${BASE_URL}customer/update-customer/${customerId}`, { isActive: newStatus }, config);

      if (response.data && response.data.status === 'success') {
        toast.success("Customer status updated successfully");
        // Update local state
        setData(prevData =>
          prevData.map(customer =>
            customer.customerId === customerId
              ? { ...customer, isActive: newStatus }
              : customer
          )
        );
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred: " + error.message);
      }
    }
  };

  const handleEditCustomer = (customer_uuid, customerId, companyname) => {
    // Store the customerId in local storage
    localStorage.setItem('customerId-edit', customerId);
    localStorage.setItem('companyname-edit', companyname)
    // Navigate to the edit customer route with the customer UUID
    navigate(`/edit-customer/${customer_uuid}`);
  };


  const handleAddcustomer = () => {
    navigate('/addcustomer')
  }


  const handleViewCustomer = (customer_uuid) => {
    setIsLoadingCustomer(true);
    setSelectedCustomerId(customer_uuid);
    setTimeout(() => {
      setViewModalOpen(true); // Open the view modal after loading
      setIsLoadingCustomer(false); // End loading
    }, 100);
  };




  return (
    <>
      <ToastContainer />
      <div className="top_section">

        <div className="pagesNamelink">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/dashboard">{t("admindashboard.dashboard")}</Link></li>
              <li className="breadcrumb-item text-white">{t("customers.customers")}</li>
            </ol>
          </nav>
          <h4 className="text-white">{t("customers.customerslist")}</h4>
        </div>
        <button onClick={() => handleAddcustomer()} className="main_btn no-underline"><i className="fa-solid fa-plus"></i>{t("customers.addcustomer")}</button>
      </div>

      <div className="mainSection">
        <div className='row g-3 align-items-end justify-end mb-3'>
          <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-12">
            <button className="cancel_btn ms-auto" onClick={handleClearFilter}>{t("customers.clearfilters")}</button>
          </div>
        </div>

        <div className="mainTable table-responsive">
          <form id="filterForm" onSubmit={handleSearch}>
            <table id="example" className="table table-striped table-dark">
              <thead>
                <tr>
                  <th>{t("customers.customerid")}</th>
                  <th>{t("customers.phone")}</th>
                  <th>{t("customers.email")}</th>
                  <th>{t("customers.companyname")}</th>
                  <th>{t("customers.lastlogindate")}</th>
                  <th>{t("customers.status")}</th>
                  <th>{t("customers.actions")}</th>
                </tr>
              </thead>
              <tbody disabled={loading}>
                {/* Search Inputs Row */}
                <tr>
                  <th>
                    <input type="text" className="form-control" placeholder="Customer ID" value={customerId} onChange={(e) => setCustomerId(e.target.value)} />
                  </th>
                  <th>
                    <input type="text" className="form-control" placeholder="Phone No"
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                    />
                  </th>
                  <th>
                    <input type="text" className="form-control" placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </th>
                  <th>
                    <input type="text" className="form-control" placeholder="Company Name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </th>
                  <th>
                    <input type="text" className="form-control" placeholder="Last Login Date"
                      value={lastLoginDate}
                      onChange={(e) => setLastLoginDate(e.target.value)}
                    />
                  </th>
                  <th>
                    <select className="form-select"
                      value={status}
                      onChange={handleStatusChange}
                    >
                      <option value={true}>Active</option>
                      <option value={false}>In Active</option>
                    </select>
                  </th>
                  <th>
                    <button type="submit" className="secondary_btn">Apply</button>
                  </th>

                </tr>

                {/* Customer Data Rows */}
                {customerData && customerData.length > 0 ? (
                  customerData.map((customer, index) => (
                    <tr key={index}>
                      <td>{customer.customerId}</td>
                      <td>{customer.phone || 'N/A'}</td>
                      <td>{customer.email}</td>
                      <td>{customer.company_name || 'N/A'}</td>
                      <td>{customer.last_login || 'N/A'}</td>
                      <td>
                        <ToggleButton
                          item={{ isActive: customer.isActive }}
                          onToggle={() => handleToggle(customer.customerId)} // Make it non-clickable if isActive is true
                          disabled={false} // Disable the toggle button if isActive is true
                        />
                      </td>
                      <td>
                        <div className="actionBtn flex justify-between gap-2 items-center">
                          <Link className="view" onClick={() => handleViewCustomer(customer.customer_uuid)}>
                            <img src={ViewIcon} alt="View" />
                          </Link>
                          <button onClick={() => handleEditCustomer(customer.customer_uuid, customer.customerId, customer.company_name)} className="Edit">
                            <img src={EditIcon} alt="Edit" />
                          </button>
                          <button className="Delete" onClick={() => deleteCustomeralert(customer.customerId)}>
                            <img src={DeleteIcon} alt="Delete" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">No customer data found.</td>
                  </tr>
                )}

              </tbody>
            </table>
          </form>
        </div>
        {customerData.length > 0 &&
          <div>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={paginate}
              currPage={currentPage}
            />
          </div>
        }
        <div>
          <ViewCustomer
            viewModalOpen={viewModalopen}
            setViewModalOpen={setViewModalOpen}
            customerUuid={selectedCustomerId}
            loading={isLoadingCustomer}
          />
        </div>
      </div>
    </>
  )
}

export default CustomerList;