import React, { useState } from 'react';
import AddCustomerStep1 from './AddCustomerStep1';
import AddCustomerStep2 from './Location/AddCustomerStep2';
import AddCustomerStep3 from './ContactInformation/AddCustomerStep3';
import AddCustomerStep4 from './AddCustomerStep4';

const AddCustomer = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [customerData, setCustomerData] = useState({
        company_name: '',
        address: '',
        address2: '',
        state: '',
        city: '',
        zip: '',
        country: '',
        phone: '',
        alternate_phone: '',
        email: '',
        // Add other fields as necessary
    });

    const handleCustomerDataChange = (data) => {
        setCustomerData((prevData) => ({
            ...prevData,
            ...data,
        }));
    }; 

    return (
        <div>
            {activeStep === 0 && (
                <AddCustomerStep1
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    onDataChange={handleCustomerDataChange}
                    customerData={customerData}
                    setCustomerData={setCustomerData}
                />
            )}
            {activeStep === 1 && (
                <AddCustomerStep2
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    customerData={customerData}
                />
            )}
            {activeStep === 2 && (
                <AddCustomerStep3
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                />
            )}
            {activeStep === 3 && (
                <AddCustomerStep4
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    customerData={customerData}
                />
            )}
        </div>
    );
};

export default AddCustomer;
