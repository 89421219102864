import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../constant/const';
import useUserStore from '../../store/UserStore';
import { t } from '../Language/Translator';

const AdminDashboardTable = () => {
    const [riskData, setRiskData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // const colors = ['#B22222', '#FF8C00', '#6B8E23', '#008080', '#008B8B'];
    const colors = ['#8B0000', '#D2691E', '#B8860B', '#2E8B57', '#1E90FF'];
    const table_Data = useUserStore((state) => state.user);
    const token = table_Data;

    useEffect(() => {

        fetchTableData();
    }, []);

    async function fetchTableData() {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.get(`${BASE_URL}/dashboard/getriskcounts`, config);
            setRiskData(response.data.result);
        }
        catch (error) {
            setError('Failed to fetch risk data'); // Handle errors
        } finally {
            setLoading(false); // Stop loading state
        }
    }

    if (loading) {
        return <div style={{ color: 'white' }}>Loading...</div>;
    }

    if (error) {
        return <div style={{ color: 'red' }}>{error}</div>;
    }

    return (
        <>
            <div className='mt-3'>
                <h3 style={{ color: 'white' }}>{t("admindashboardgraphs.tabletitle")}</h3>
                <div className='table-responsive'>
                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', height: '350px', border: '1px solid #ccc' }}>
                        <colgroup>
                            <col style={{ width: '20%' }} /> 
                            <col style={{ width: '20%' }} /> 
                            <col style={{ width: '20%' }} /> 
                            <col style={{ width: '20%' }} /> 
                            <col style={{ width: '20%' }} /> 
                        </colgroup>
                        <thead>
                            <tr>
                                <th style={{ padding: '5px', color: 'white', backgroundColor: '#1a1a1a', border: '1px solid #ccc',fontWeight:'600' }}>Severity</th>
                                <th style={{ padding: '5px', color: 'white', backgroundColor: colors[0], border: '1px solid #ccc',fontWeight:'600' }}>Very Frequent</th>
                                <th style={{ padding: '5px', color: 'white', backgroundColor: colors[1], border: '1px solid #ccc',fontWeight:'600' }}>Frequent</th>
                                <th style={{ padding: '5px', color: 'white', backgroundColor: colors[2], border: '1px solid #ccc',fontWeight:'600' }}>Sometimes</th>
                                <th style={{ padding: '5px', color: 'white', backgroundColor: colors[3], border: '1px solid #ccc',fontWeight:'600' }}>Rare</th>
                                {/* <th style={{ padding: '5px', color: 'white', backgroundColor: colors[4], border: '1px solid #ccc' }}>Occasionally</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {riskData.map((row, index) => (
                                <tr key={index}>
                                    <td style={{ padding: '5px', textAlign: 'center', backgroundColor: '#1a1a1a', color: 'white', border: '1px solid #ccc',fontWeight:'600' }} className='capitalize'>{row.severity}</td>
                                    <td style={{ padding: '5px', backgroundColor: colors[0], textAlign: 'center', color: 'white', border: '1px solid #ccc' }}>
                                        {row.very_frequent_count}
                                    </td>
                                    <td style={{ padding: '5px', backgroundColor: colors[1], textAlign: 'center', color: 'white', border: '1px solid #ccc' }}>
                                        {row.frequent_count}
                                    </td>
                                    <td style={{ padding: '5px', backgroundColor: colors[2], textAlign: 'center', color: 'white', border: '1px solid #ccc' }}>
                                        {row.sometime_count}
                                    </td>
                                    <td style={{ padding: '5px', backgroundColor: colors[3], textAlign: 'center', color: 'white', border: '1px solid #ccc' }}>
                                        {row.rare_count}
                                    </td>
                                    {/* <td style={{ padding: '5px', backgroundColor: colors[4], textAlign: 'center', color: 'white', border: '1px solid #ccc' }}>
                                        {row.occasionally_count}
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default AdminDashboardTable;

