import React, { useState } from "react"; import { Chart } from "react-google-charts"; import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, Legend } from "recharts";
import Loader from "../commonForm/Loader";
import Opened from "../../assets/images/Icons/OpenedTicket.png";
import Closed from "../../assets/images/Icons/ClosedTicket.png";
import Resolved from "../../assets/images/Icons/ResolvedTicket.png";
import Pending from "../../assets/images/Icons/PendingTicket.png";
import { useNavigate } from "react-router-dom";
import { t } from "../Language/Translator";

const STATUS_COLORS = {
    Opened: "url(#openedGradient)",
    Pending: "url(#pendingGradient)",
    Resolved: "url(#resolvedGradient)",
    Closed: "url(#closedGradient)"
};
const STATUS_COLORS2 = {
    Opened: "#03A9F4",
    Pending: "#FFEB3B",
    Resolved: "#4CAF50",
    Closed: "#F44336"
};
const STATUS_ICONS = {
    opened: Opened, pending: Pending, resolved: Resolved, closed: Closed
}
const SEVERITIES = ["Critical", "High", "Medium", "Low", "Info"];
const SEVERITY_COLORS = ["#D32F2F", "#F57C00", "#FFC107", "#388E3C", "#1976D2"];
const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const ReportsCharts = ({ reportsData, PieChartRef, BarChartRef }) => {
    const navigate = useNavigate();

    const ticketCounts = { opened: 0, pending: 0, resolved: 0, closed: 0 };
    reportsData?.allIncidentData?.forEach(({ status, statusCount }) => {
        const key = status.toLowerCase();
        if (ticketCounts[key] !== undefined) {
            ticketCounts[key] += statusCount;
        }
    });

    function handleBlockClick(status) {
        navigate(`/tickets?status=${status}`)
    }

    // Pie Chart Data (Severity)
    const pieChartData = [
        ["Severity", "Count"],
        ["Critical", reportsData?.criticalviolation || 0],
        ["High", reportsData?.highrisk || 0],
        ["Medium", reportsData?.mediumrisk || 0],
        ["Low", reportsData?.lowrisk || 0],
        ["Info", reportsData?.inforisk || 0]
    ];
    // reportsData.criticalviolation === reportsData.overallrisk 
    const pieChartOptions = {
        is3D: true,
        pieSliceText: "percentage",
        pieSliceTextStyle: { fontSize: 18 },
        focusTarget: "category", // Highlights the whole category on hover
        enableInteractivity: true,
        slices: {
            0: { color: "#D32F2F" }, // Dark Red
            1: { color: "#F57C00" }, // Orange
            2: { color: "#FFC107" }, // Yellow
            3: { color: "#388E3C" }, // Green
            4: { color: "#1976D2" }  // Blue
        },
        chartArea: { width: '80%', height: '80%' },
        backgroundColor: "transparent",
        legend: "none",
        tooltip: { textStyle: { fontSize: 16, bold: false } },

    };

    const handleChartClick = ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        //console.log(selection);

        if (selection.length > 0) {
            const sliceIndex = selection[0].row
            if (sliceIndex !== null || sliceIndex !== undefined) {
                const severity = SEVERITIES[sliceIndex];
                navigate(`/tickets?severity=${severity}`);
            }
        }
    };

    // Process data for the chart
    function processChartData(reportsData) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]; 

        const structuredData = months.map((month) => ({
            month,
            Opened: 0,
            Pending: 0,
            Resolved: 0,
            Closed: 0,
        }));

        reportsData?.incidentgraph?.forEach(({ monthName, statusCount, status }) => {
            const monthIndex = months.indexOf(monthName);
            if (monthIndex !== -1) {
                structuredData[monthIndex][status] += statusCount;
            }
        });
        return structuredData;
    };

    const barChartData = processChartData(reportsData);

    return (

        <section>
            <div className="ticketInfo">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                    {Object.keys(ticketCounts).map((status, index) => (
                        <div key={index} className="mainSection text-white p-4 rounded-xl shadow-md flex items-center justify-around cursor-pointer" onClick={() => handleBlockClick(status)}>
                            <img src={STATUS_ICONS[status]} className="" alt="ticket" />
                            <div>
                                <p className="text-gray-400 capitalize mb-1">{status} Tickets</p>
                                <h2 className="text-3xl font-semibold" style={{ color: STATUS_COLORS[status] }}>{ticketCounts[status]}</h2>
                            </div>
                        </div>
                    ))}
                </div>
            </div>


            <div className="flex flex-col xl:flex-row gap-4 items-center lg:items-start">
                {/* Pie Chart */}
                <figure className="pieChart mainSection w-full xl:w-1/3 h-[500px]" >
                    <h5 className="text-white text-center my-2">{t("report.piechart")}</h5>
                    <div ref={PieChartRef}>
                        <Chart chartType="PieChart" data={pieChartData} loader={<Loader />} options={pieChartOptions} width="100%" height="400px"
                            chartEvents={[
                                { eventName: "select", callback: handleChartClick },
                            ]} />
                    </div>
                    <div className="flex justify-center items-end xl:gap-3 gap-2 sm:flex-nowrap flex-wrap mx-auto">
                        {SEVERITIES.map((severity, index) => (
                            <div key={index} className="flex items-center space-x-2">
                                <span className="w-4 h-4 rounded-full" style={{ backgroundColor: SEVERITY_COLORS[index] }}></span>
                                <span className="text-sm font-medium capitalize text-white">{severity}</span>
                            </div>
                        ))}
                    </div>
                </figure>

                {/* Bar Chart */}
                <figure className="barGraph mainSection w-full lg:flex-1 min-w-0 min-h-[500px]" >
                    <h5 className="text-white text-center my-2">{t("report.bargraph")}</h5>
                    <ResponsiveContainer width="100%" height={400} ref={BarChartRef}>
                        <BarChart data={barChartData} margin={{ top: 20, right: 30, left: 0, bottom: 20 }}>
                            {/* Grid & Axis */}
                            <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                            <XAxis dataKey="month" tick={{ fill: "grey", fontSize: 18 }} />
                            <YAxis tick={{ fill: "grey", fontSize: 18 }} />
                            <Tooltip contentStyle={{ background: "#222", borderRadius: "8px", color: "white" }} />
                            {/* <Legend wrapperStyle={{ color: "white", fontSize: "14px" }}/> */}

                            {/* Gradient for Better Look */}
                            <defs>
                                <linearGradient id="openedGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#03A9F4" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#03A9F4" stopOpacity={0.2} />
                                </linearGradient>
                                <linearGradient id="pendingGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#FFEB3B" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#FFEB3B" stopOpacity={0.2} />
                                </linearGradient>
                                <linearGradient id="resolvedGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#4CAF50" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#4CAF50" stopOpacity={0.2} />
                                </linearGradient>
                                <linearGradient id="closedGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#F44336" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#F44336" stopOpacity={0.2} />
                                </linearGradient>
                            </defs>

                            {/* Bars with Gradient Colors */}
                            {Object.keys(STATUS_COLORS).map((status) => (
                                <Bar key={status} dataKey={status} stackId="a" fill={STATUS_COLORS[status]} barSize={40} />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                    <div className="flex justify-center gap-3 flex-wrap">
                        {Object.keys(STATUS_COLORS2).map((status, index) => (
                            <div key={index} className="flex items-center space-x-2">
                                <span className="w-4 h-4 rounded-full" style={{ backgroundColor: STATUS_COLORS2[status] }}></span>
                                <span className="text-sm font-medium capitalize text-white">{status}</span>
                            </div>
                        ))}
                    </div>
                </figure>
            </div>
        </section>
    );

};

export default ReportsCharts;
