import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom'
import { BASE_URL } from '../../constant/const.js';
import axios from "axios";
import { handleResponse } from "../utility/ExceptionHandel.js";
import Loader from "../commonForm/Loader.js";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Country from "../commonForm/Country.js";
import State from "../commonForm/State.js";
import City from "../commonForm/City.js";
import useUserStore from "../../store/UserStore.js";
import DatePicker from "react-datepicker";
import { t } from "../Language/Translator.js";

const EditUser = () => {
    const { user_uuid } = useParams();

    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, SetEmail] = useState('');
    const [username, setUsername] = useState('');
    const [phoneno, setPhoneno] = useState('');
    const [alternetphoneno, setAlternetPhoneno] = useState('');
    const [role, setRole] = useState('');
    const [addressone, setAddressone] = useState('');
    const [addresstwo, setAddresstwo] = useState('');
    const [dob, setDob] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [status, setStatus] = useState('');

    const user_data = useUserStore((state) => state.user);
    const token = user_data;

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleStatus = (event) => {
        setStatus(event.target.value);
    };

    const navigate = useNavigate();



    const handleSubmit = async (e) => {
        e.preventDefault();

        const userData = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            user_type: role,
            phone: phoneno,
            alternate_phone: alternetphoneno,
            dob: dob ? dob.toLocaleDateString('en-CA') : null,
            address: addressone,
            address2: addresstwo,
            state: selectedState.toString(),
            city: selectedCity.toString(),
            zip: zipcode,
            country: selectedCountry.toString(),
            isActive: status === 'Active',
        };


        setLoading(true); // Start loading

        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };

            const response = await axios.put(`${BASE_URL}user/update-user/${user_uuid}`, userData, config);

            if (response.data && response.data.status === 'success') {
                toast.success(response.data.message);
            } // Show success message
            await new Promise((resolve) => setTimeout(resolve, 2000));
            navigate('/userlist')
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        } finally {
            setLoading(false); // Stop loading
        }
    };

    //set edit data
    useEffect(() => {

        fetchData();
    }, []);

    // if (loading) {
    //     return <Loader />;
    // }

    async function fetchData() {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.get(`${BASE_URL}user/view-user/${user_uuid}`, config);
            toast.success(response.data.message);
            setFirstName(response.data.data.first_name);
            setLastName(response.data.data.last_name);
            setUsername(response.data.data.user_name);
            SetEmail(response.data.data.email);
            setPhoneno(response.data.data.phone);
            setAlternetPhoneno(response.data.data.alternate_phone);
            setRole(response.data.data.user_type);
            setAddressone(response.data.data.address);
            setAddresstwo(response.data.data.address2);
            // setDob(dayjs(response.data.data.dob));
            setDob(new Date(response.data.data.dob));
            setSelectedState(parseInt(response.data.data.state));
            setSelectedCountry(parseInt(response.data.data.country));
            setSelectedCity(parseInt(response.data.data.city));
            setZipcode(response.data.data.zip);
            setStatus(response.data.data.isActive ? 'Active' : 'Inactive'); // Set status based on isActive
            setLoading(false);
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }

            setLoading(false);
            // handleResponse(error);
        }
    }

    function handleCountrySelect(country) {
        setSelectedCountry(country);
        setSelectedState('');
        setSelectedCity('');
    }

    function handleStateSelect(state) {
        setSelectedState(state);
        setSelectedCity('');
    }

    return (
        <div>

            <div className="mainBody">
                <ToastContainer />
                <div className="top_section">
                    <div className="pagesNamelink">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/dashboard">{t("admindashboard.dashboard")}</Link></li>
                                <li className="breadcrumb-item"><Link to="/userlist">{t("users.user")}</Link></li>
                                <li className="breadcrumb-item active text-white" aria-current="page">{t("addeditusers.update")} {t("users.user")}</li>
                            </ol>
                        </nav>
                        <h4 className="text-white">{t("addeditusers.update")} {t("users.user")}</h4>
                    </div>
                </div>

                <div className="mainSection">
                    <form onSubmit={handleSubmit} className="row g-3">

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.firstname")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.firstname")}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.lastname")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.lastname")}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.username")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.username")}
                                readOnly
                                value={username}

                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.email")}</label>
                            <input type="email" className="form-control" placeholder={t("addeditusers.email")}
                                readOnly
                                value={email}
                                onChange={(e) => SetEmail(e.target.value)}
                            />
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.phoneno")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.phoneno")}
                                value={phoneno}
                                onChange={(e) => setPhoneno(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.alternatephoneno")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.alternatephoneno")}
                                value={alternetphoneno}
                                onChange={(e) => setAlternetPhoneno(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.dateofbirth")}</label>
                            <DatePicker
                                selected={dob}
                                onChange={(date) => setDob(date)}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                maxDate={new Date().setDate(new Date().getDate() - 1)}
                                dateFormat="MM/dd/yyyy"
                                wrapperClassName='w-full'
                                className="form-control"
                                placeholderText="MM/DD/YYYY"
                            />
                            {/* <input type="date" className="form-control" name='DOB'
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                            /> */}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.role")}</label>
                            <select className="form-control"
                                disabled
                                value={role}
                                onChange={handleRoleChange}
                            >
                                <option selected value="">{t("addeditusers.selectrole")}</option>
                                <option value="admin">Admin</option>
                                <option value="analyst">Analyst</option>
                            </select>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <label className="form-label">{t("addeditusers.address")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.address")}
                                value={addressone}
                                onChange={(e) => setAddressone(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <label className="form-label">{t("addeditusers.address2")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.address2")}
                                value={addresstwo}
                                onChange={(e) => setAddresstwo(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.status")}</label>
                            <select
                                className="form-control"
                                value={status}
                                onChange={handleStatus}
                            >
                                <option value="Active" selected={status === 'Active'}>Active</option>
                                <option value="Inactive" selected={status === 'Inactive'}>Inactive</option>
                            </select>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.country")}</label>
                            <Country onCountrySelect={handleCountrySelect} selectedvaluecountry={selectedCountry} />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.state")}</label>
                            <State countryId={selectedCountry} onStateSelect={handleStateSelect} selectedvaluestate={selectedState} />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.city")}</label>
                            <City stateId={selectedState} onCitySelect={setSelectedCity} selectedvaluecity={selectedCity} />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.zipcode")}</label>
                            <input type="text" className="form-control" placeholder="ZIP Code"
                                value={zipcode}
                                onChange={(e) => setZipcode(e.target.value)}
                            />
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                            {loading ? <Loader /> : ''}

                            <Link to={'/userlist'} className="cancel_btn me-3 no-underline">{t("addeditusers.cancel")}</Link>

                            <button type="submit"
                                class={`secondary_btn ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={loading}
                            >
                                {t("addeditusers.submit")}</button>

                        </div>
                    </form>
                </div>
            </div>
        </div>



    )
}

export default EditUser;