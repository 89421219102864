import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import EditIcon from "../../assets/images/Icons/edit.svg";
import DeleteIcon from "../../assets/images/Icons/delete.svg";
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { deletecustomerInfra, filterInfrastructure, getCustomerInfraListings } from '../../services/customer_infrastructure_api'
import Loader from '../commonForm/Loader';
import useUserStore from '../../store/UserStore';
import Swal from 'sweetalert2';
import { jwtDecode } from 'jwt-decode';
import { getCustomers } from '../../services/customer_api';
import Pagination from '../commonForm/Pagination';
import { t } from '../Language/Translator';

const CustomerInfraList = () => {
    const [filteredInfraList, setFilteredInfraList] = useState(null);
    const [filteredFormData, setFilteredFormData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    const userData = jwtDecode(token)?.user_data;
    const userInfo = useUserStore((state) => state.userInfo);
    const isNotCustomer = !['customer', 'secondary_customer'].includes(userInfo?.user_type);
    // !filteredFormData ? getTickets(token, currentPage) : null
    const { data: customerInfraData, isLoading, error } = useQuery({ queryKey: ['ALLcustomerInfraData', currentPage], queryFn: () => !filteredFormData ? getCustomerInfraListings(token, currentPage) : null, enabled: !filteredFormData })
    const { data: customerData, error: customerError } = useQuery({ queryKey: ['infrastructure(customers)'], queryFn: () => getCustomers(token), enabled: isNotCustomer })

    const { mutate: mutateFilter, isPending: isFiltering } = useMutation({
        mutationFn: (formData) => filterInfrastructure(token, formData),
        onSuccess: function (data) {
            //console.log(data);
            setFilteredInfraList(data)
            // queryClient.invalidateQueries({ queryKey: ['ALLcustomerInfraData'] });
        },
        onError: function (error) {
            toast.error(error.message);
        }
    });

    async function deleteInfraStructure(ID) {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this information!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#19C489',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sure !'
        });

        if (result.isConfirmed) {
            try {
                await deletecustomerInfra(token, ID);
                await queryClient.invalidateQueries({ queryKey: ['ALLcustomerInfraData'] });
                Swal.fire('Deleted!', 'Deleted successfully', 'success');
            } catch (error) {
                Swal.fire('Error!', error.message, 'error');
            }
        }
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        if (filteredFormData) {
            const newFormData = new FormData();
            for (let [key, value] of filteredFormData.entries()) {
                newFormData.append(key, value);
            }
            newFormData.set('page', pageNumber);
            mutateFilter(newFormData);
        }
        else {
            queryClient.invalidateQueries({ queryKey: ['ALLcustomerInfraData'] });
        }
    };

    function handleCustomerSelect(e) {
        e.preventDefault();
        const formdata = new FormData(e.target);
        const customerID = formdata.get('customer');
        if (customerID) {
            navigate(`/customerInfrastructure/add/${customerID}`);
        }
        else {
            toast.error('Please select a customer');
            return;
        }
    }

    function handleFiltering(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        //console.log(formData.get('device_type'));
        setFilteredFormData(formData);
        mutateFilter(formData);
    }

    function handleClearFilter(e) {
        e.preventDefault();
        document.getElementById("filterForm")?.reset();
        setFilteredInfraList(null);
        setFilteredFormData(null);
        setCurrentPage(1);
        queryClient.invalidateQueries({ queryKey: ['ALLcustomerInfraData'] });

    }

    const infraListToDisplay = filteredInfraList?.customerinfo?.length >= 0 ? filteredInfraList : customerInfraData;

    return (
        <>
            <ToastContainer />
            <div className="top_section flex-wrap">
                <div className="pagesNamelink">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/dashboard">{t("admindashboard.dashboard")}</a></li>
                            <li className="breadcrumb-item"><a href="">{t("customerinfra.customerinfrastructure")}</a></li>

                        </ol>
                    </nav>
                    <h4 className="text-white">{t("customerinfra.customerinfralist")}</h4>
                </div>
                {

                    <div>
                        <form className='flex gap-4' onSubmit={handleCustomerSelect}>
                            <div>
                                <select className="form-select sm:min-w-48" name='customer' defaultValue={isNotCustomer ? 'default' : userData.userId}>
                                    <option value='default' disabled={true}>{t("customerinfra.selectcustomer")}</option>
                                    {!isNotCustomer &&
                                        <option value={userData.userId}>{userInfo.first_name + ' ' + userInfo.last_name}</option>
                                    }
                                    {isNotCustomer && customerData && customerData.data.map((customer, index) => {
                                        return (
                                            <option key={index} value={customer.customerId}>{customer.company_name}</option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div>
                                <button type='submit' className="main_btn no-underline"><i className="fa-solid fa-plus"></i> {t("customerinfra.addinfrastructure")}</button>
                            </div>
                        </form>
                    </div>

                }

            </div>
            <div className="mainSection">
                <div className='row g-3 align-items-end justify-end mb-3'>
                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-12">
                        <button className="cancel_btn ms-auto" onClick={handleClearFilter}>{t("customerinfra.clearfilters")}</button>
                    </div>
                </div>
                {isLoading && <Loader />}
                {!isLoading && !error &&
                    <>

                        <div className="mainTable table-responsive">
                            <form id='filterForm' onSubmit={handleFiltering}>
                                {!isLoading && !error && infraListToDisplay &&
                                    <>
                                        <table className="table table-dark table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{t("customerinfra.serverdevicetype")}</th>
                                                    <th>{t("customerinfra.location")}</th>
                                                    <th>{t("customerinfra.machinedevicename")}</th>
                                                    <th>{t("customerinfra.role")}</th>
                                                    <th>{t("customerinfra.edrtype")}</th>
                                                    <th>{t("customerinfra.applications")}</th>
                                                    <th>{t("customerinfra.ipaddress")}</th>
                                                    <th>{t("customerinfra.decept10socinfo")}</th>
                                                    <th>{t("customerinfra.actions")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("customerinfra.devicetype")} name='device_type' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("customerinfra.location")} name='location' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("customerinfra.devicename")} name='device_name' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("customerinfra.role")} name='role' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("customerinfra.edrtype")} name='edr_type' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("customerinfra.application")} name='application' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("customerinfra.ipaddress")} name='ip_add' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("customerinfra.decept10info")} name='desct10_notes' />
                                                    </th>
                                                    <th>
                                                        <button type='submit' className="secondary_btn" disabled={isFiltering ? <Loader /> : ''}>Apply</button>
                                                    </th>

                                                </tr>
                                                {infraListToDisplay.customerinfo.map((data, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{data.device_type}</td>
                                                            <td>{data.location}</td>
                                                            <td>{data.device_name}</td>
                                                            <td>{data.role}</td>
                                                            <td>{data.edr_type}</td>
                                                            <td>{data.application}</td>
                                                            <td>{data.ip_add}</td>
                                                            <td>{data.desct10_notes}</td>
                                                            <td>
                                                                <div className="actionBtn flex gap-2 items-center">
                                                                    <Link to={`/customerInfrastructure/add/${data.c_id}`} className="Edit m-0" ><img
                                                                        src={EditIcon} alt="Edit" />
                                                                    </Link>
                                                                    <button type='button' className="Delete" onClick={() => deleteInfraStructure(data.c_id)}>
                                                                        <img src={DeleteIcon} alt="Delete" /> </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>

                                        </table>
                                    </>
                                }
                            </form>
                            {infraListToDisplay?.customerinfo?.length == 0 &&
                                <p className='text-center text-white mt-4'>No results found !</p>
                            }
                            {error &&
                                <p className='text-center text-white mt-4'>Something went wrong. Please try again later !</p>
                            }
                        </div>
                        <div>
                            <Pagination
                                itemsPerPage={10}
                                totalItems={infraListToDisplay?.pagination?.totalItems}
                                paginate={paginate}
                                currPage={currentPage}
                            />
                        </div>
                    </>

                }
                {error &&
                    <p className='text-center text-white mt-4'>Something went wrong. Please try again later !</p>
                }
            </div>


        </>
    )
}

export default CustomerInfraList