import React, { useState, useEffect } from 'react'
import Stepper from '../AddCustomer/Stepper';
import useUserStore from '../../../store/UserStore';
import axios from 'axios';
import { BASE_URL } from '../../../constant/const';
import { toast, ToastContainer } from 'react-toastify';
import DeleteIcon from '../../../assets/images/Icons/delete.svg';
import Loader from '../../commonForm/Loader';
import Swal from 'sweetalert2';
import EditStepper from './EditStepper';
import { t } from '../../Language/Translator';
// import EditIcon from '../../../assets/images/Icons/edit.svg'

const EditCustomerStep3 = ({ activeStep, setActiveStep, customerUuid }) => {


  const [contactInfoData, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contactName, setContactName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [alternatePhoneNo, setAlternatePhoneNo] = useState('');
  const [email, setEmail] = useState('');


  const location_data = useUserStore((state) => state.user);
  const token = location_data;

  const customer_Id = localStorage.getItem('customerId-edit');
  const companyname = localStorage.getItem('companyname-edit')

  useEffect(() => {
    fetchData();
  }, []);



  //--------------------------------------GET CONTACTINFO OF A PARTICULAR CUSTOMER------------------------


  async function fetchData() {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      };
      const response = await axios.get(`${BASE_URL}customer/view-customer/${customerUuid}`, config);
      //console.log(response, "===========CONTACTINFO RESPONSE================");

      if (response.data && response.data.status === 'success' && response.data.data.length > 0) {
        const customerData = response.data.data[0];
        setData(customerData.customerContactInfo || []);
        //console.log(customerData, "Fetched  Customer Data");
      }
      else {
        setData([]);
      }

    }
    catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.error(error.message);
      }

      setLoading(false);
    }
  }

  //--------------------------------------ADD NEW CONTACT INFO----------------------------------------------


  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true); // Start loading state


    const ContactInfo = {
      customer_id: customer_Id,
      contact_name: contactName,
      phone: phoneNo,
      alternate_phone: alternatePhoneNo,
      email: email,
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
      };
      const response = await axios.post(`${BASE_URL}customer/addCustomerContactInfo`, ContactInfo, config);
      //console.log("API Response:", response.data);

      if (response.data && response.data.status) {
        toast.success(response.data.message);
        // Update the contact info state with the new contact info including the contactInfoId
        setData(prevData => [
          ...prevData,
          {
            id: response.data.data.contactInfoId, // Use contactInfoId from the response
            contact_name: contactName,
            email: email,
            phone: phoneNo,
            alternate_phone: alternatePhoneNo,
          }
        ]);
      }

      setLoading(false);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.error(error.message);
      }
      setLoading(false);
    }
  };



  //--------------------------------------DELETE CONTACTINFO RECORD------------------------------------------


  async function handleDeleteCustomerContactInfo(id) {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          "Authorization": token,
        },
      };
      const response = await axios.delete(`${BASE_URL}customer/delete-customer-contactInfo/${customer_Id}/${id}`, config);
      toast.success(response.data.message);
    }
    catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      }
      else {
        toast.error(error.message);
      }
    }
  };


  function deleteCustomercontactinfoalert(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this customer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#19C489',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleDeleteCustomerContactInfo(id);
        setData(prevData => prevData.filter(item => item.id !== id));
        Swal.fire(
          'Deleted!',
          'Your item has been deleted.',
          'success'
        );
      }
    });
  };


  const handleNext = (event) => {
    event.preventDefault();
    setActiveStep(3);
  };

  const handlePrevious = () => {
    setActiveStep(1);
    // navigate('/locations')
  }

  return (

    <>
      <div className='mainBody'>
        <ToastContainer/>
        <div className="top_section">
          <div className="pagesNamelink">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/dashboard">{t("admindashboard.dashboard")}</a></li>
                <li className="breadcrumb-item"><a href="/customerlist">{t("customers.customers")}</a></li>
                <li className="breadcrumb-item active text-white" aria-current="page">{t("customerstep1.editcustomer")}</li>
              </ol>
            </nav>
            <h4 className="text-white">{t("customerstep3.editcontactinformation")} {t("customerstep3.for")} {companyname}</h4>
          </div>
        </div>
        <div className='mainSection mt-3 mb-20'>

          <div>
            <EditStepper activeStep={activeStep} setActiveStep={setActiveStep} />
          </div>


          <div className="tab-pane fade ml-4 mr-4 mt-5 text-white show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">
            <h3 className="wizardHead">{t("customerstep3.contactinformation")}</h3>

            <form className="row g-3" onSubmit={handleSubmit}>

              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <label className="form-label">{t("customerstep3.contactname")}</label>
                <input type="text" className="form-control" placeholder={t("customerstep3.contactname")} value={contactName} onChange={(e) => setContactName(e.target.value)} />
              </div>

              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <label className="form-label">{t("customerstep3.phoneno")}</label>
                <input type="" className="form-control" placeholder={t("customerstep3.phoneno")} value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} />
              </div>

              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <label className="form-label">{t("customerstep3.alternatephoneno")}</label>
                <input type="" className="form-control" placeholder={t("customerstep3.alternatephoneno")} value={alternatePhoneNo} onChange={(e) => setAlternatePhoneNo(e.target.value)} />
              </div>

              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <label className="form-label">{t("customerstep3.email")}</label>
                <input type="email" className="form-control" placeholder={t("customerstep3.email")} value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>

              <div className="col-xxl-12 col-xl-12 mt-20 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                <button className="add_btn">{t("customerstep3.add")}</button>
              </div>

            </form>



            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
              <div className="mainTable table-responsive">
                {/* {isLoading && <Loader />} */}
                <table id="example" className="table table-striped table-dark">
                  <thead>
                    <tr>
                      <th>{t("customerstep3.id")}</th>
                      <th>{t("customerstep3.contactname")}</th>
                      <th>{t("customerstep3.phoneno")}</th>
                      <th>{t("customerstep3.alternatephoneno")}</th>
                      <th>{t("customerstep3.email")}</th>
                      <th>{t("customerstep3.actions")}</th>
                    </tr>
                  </thead>
                  <tbody disabled={loading}>
                    {/* {loading && <Loader />} */}
                    {contactInfoData?.map((contactInfo, index) => (
                      <tr key={index}>
                        <td>{contactInfo.id}</td>
                        <td>{contactInfo.contact_name}</td>
                        <td>{contactInfo.phone}</td>
                        <td>{contactInfo.alternate_phone}</td>
                        <td>{contactInfo.email}</td>

                        <td>
                          <div className="actionBtn flex gap-3 items-center">
                            {/* <button className="Edit"><img
                              src={EditIcon} alt="Edit" />
                            </button> */}
                            <button className="Delete">
                              <img src={DeleteIcon} alt="Delete" onClick={() => deleteCustomercontactinfoalert(contactInfo.id)} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end justify-content-end gap-3 mt-20">
              <button onClick={handlePrevious} type="submit" className={`cancel_btn`}>
                {t("customerstep3.previous")}
              </button>
              <button onClick={handleNext} type="submit" className={`secondary_btn`}>
                {t("customerstep3.next")}
              </button>

            </div>
          </div>


        </div>

      </div>
    </>
  )
}

export default EditCustomerStep3
