import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import UserStore from '../../src/store/UserStore';
import { jwtDecode } from 'jwt-decode';
import { BASE_URL } from '../constant/const';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth_data = UserStore((state) => state.user);
  let token = auth_data || null;
  const decodedToken = jwtDecode(token);
    // console.log(decodedToken);

    // useEffect(() => {
  
    //   try {
    //     // decodedToken = jwtDecode(auth_data);
    //     console.log(decodedToken);
        
    //     async function fetchUserStatus() {
    //       try {
    //         const response = await fetch(`${BASE_URL}/common/user-status/${decodedToken.user_data.user_uuid}`);
    //         const data = await response.json();
    //         // setIsActive(response.ok && data.data.isActive);
    //       } catch (error) {
    //         // setIsActive(false);
    //       }
    //     }
  
    //     fetchUserStatus();
    //   } catch (error) {
    //     // setIsActive(false);
    //   }
    // });

  const handleLogout = () => {
    const clearUser = UserStore.getState().clearUser;
    clearUser();
    localStorage.clear();
    window.location.href = '/';
  };

  if (!token) {
    handleLogout();
    return <Navigate to="/" />;
  }

  try {
    
    const currentTime = Math.floor(Date.now() / 1000);

    if (decodedToken.exp < currentTime || decodedToken?.user_data?.isActive==false) {
      handleLogout();
      return <Navigate to="/" />;
    }

  } catch (error) {
    // console.error("Invalid token", error);
    handleLogout();
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};


export default PrivateRoute;