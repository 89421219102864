import React, { useState } from 'react'
import EditIcon from "../../../assets/images/Icons/edit.svg";
import DeleteIcon from "../../../assets/images/Icons/delete.svg";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useUserStore from '../../../store/UserStore';
import { deleteAppInfo, getCustomerInfraListings, getOneCustomerInfraListing, saveApplicationInfo } from '../../../services/customer_infrastructure_api';
import { toast } from 'react-toastify';
import Loader from '../../commonForm/Loader';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';
import { jwtDecode } from 'jwt-decode';
import { t } from '../../Language/Translator';


const Step3 = ({ prevStep }) => {
    const { c_id } = useParams();
    const [editAppID, setEditAppID] = useState(null);
    const queryClient = useQueryClient();

    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    const userData = jwtDecode(token)?.user_data;
    //console.log(userData);

    const handleStep3Submit = (e, EditID = null) => {
        e.preventDefault();
        const formdata = new FormData(e.target);
        if (EditID) {
            formdata.append("id", EditID);
        }
        formdata.append("c_id", c_id);

        // formdata.append("updatedBy", 0);
        const jsonObject = Object.fromEntries(formdata.entries());
        mutateStep3(jsonObject);

    };

    const { data: customerInfraData, isLoading, error } = useQuery({ queryKey: ['customerInfraData', c_id], queryFn: () => getOneCustomerInfraListing(token, c_id) })

    const { mutate: mutateStep3, isPending: isAdding } = useMutation({
        mutationFn: (jsonObj) => saveApplicationInfo(token, jsonObj),
        onSuccess: function (data) {
            if (data.CustomerAppSchemaExist) {
                document.getElementById("EDITappForm")?.reset();
                hideModal();
            }
            document.getElementById("ADDappForm")?.reset();
            toast.success("Application info successfully saved");
            queryClient.invalidateQueries({ queryKey: ["customerInfraData"] });

        },
        onError: function (error) {
            toast.error(error.message);
        },
    });

    async function deleteAppInfoalert(ID) {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this information!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#19C489',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sure !'
        });

        if (result.isConfirmed) {
            try {
                await deleteAppInfo(token, ID);
                await queryClient.invalidateQueries({ queryKey: ['customerInfraData'] });
                Swal.fire('Deleted!', 'Deleted successfully', 'success');
            } catch (error) {
                Swal.fire('Error!', error.message, 'error');
            }
        }
    }

    const hideModal = () => {
        const modalElement = document.getElementById('editAPP');
        const modalInstance = Modal.getInstance(modalElement) || new Modal(modalElement);
        modalInstance.hide();
    };

    return (
        <>
            <h3 className="wizardHead">{t("customerinfra.application")}</h3>
            <form className="row g-3" id='ADDappForm' onSubmit={handleStep3Submit}>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <label className="form-label">{t("customerinfra.websitename")}</label>
                    <input type="text" placeholder={t("customerinfra.websitename")} className="form-control" name='app_name' />
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <label className="form-label">{t("customerinfra.vlanallowed")}</label>
                    <input type="text" placeholder={t("customerinfra.vlanallowed")} className="form-control" name='ip_add' />
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <label className="form-label">{t("customerinfra.publicprivate")}</label>
                    <input type="text" placeholder={t("customerinfra.publicprivate")} className="form-control" name='internal_detail' />
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                    <label className="form-label">{t("customerinfra.description")}</label>
                    <input type="text" placeholder={t("customerinfra.description")} className="form-control" name='discription' />
                </div>
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                    <span className='me-3'>{isAdding && <Loader />}</span>
                    <button className="secondary_btn" disabled={isAdding}>{t("customerinfra.add")}</button>
                </div>
            </form>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                {isLoading && <Loader />}
                <div className="mainTable table-responsive">
                    {!isLoading && !error && customerInfraData?.customerapp?.length > 0 &&
                        <table className="table table-dark table-striped">
                            <thead>
                                <tr>
                                    <th>{t("customerinfra.websitename")}</th>
                                    <th>{t("customerinfra.vlanallowed")}</th>
                                    <th>{t("customerinfra.publicprivate")}</th>
                                    <th>{t("customerinfra.description")}</th>
                                    <th>{t("customerinfra.actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customerInfraData?.customerapp?.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{data.app_name}</td>
                                            <td>{data.ip_add}</td>
                                            <td>{data.internal_detail}</td>
                                            <td>{data.discription}</td>
                                            <td>
                                                <div className="actionBtn flex justify-start gap-2 items-center">
                                                    <button className="Edit m-0" data-bs-toggle="modal" data-bs-target="#editAPP" onClick={() => setEditAppID(data.id)}><img
                                                        src={EditIcon} alt="Edit" />
                                                    </button>
                                                    <button type='button' className="Delete" onClick={() => deleteAppInfoalert(data.id)}>
                                                        <img src={DeleteIcon} alt="Delete" /> </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    }
                    {customerInfraData?.customerapp?.length == 0 &&
                        <p className='text-center text-white mt-4'>{t("customerinfra.noresultfound")}</p>
                    }
                    {error &&
                        <p className='text-center text-white mt-4'>{t("customerinfra.somethingwentwrong")}</p>
                    }
                </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
                <button className="cancel_btn previous me-3" id="bservice" onClick={prevStep}>{t("customerinfra.previous")}</button>
                <Link to={'/customerInfrastructure'} className="secondary_btn next no-underline" id="service">{t("customerinfra.submit")}</Link>
            </div>


            <EditAppModal token={token} id={editAppID} handleStep3Submit={handleStep3Submit} isAdding={isAdding}></EditAppModal>

        </>
    );
}

export default Step3

const EditAppModal = ({ token, id, handleStep3Submit, isAdding }) => {
    const { c_id } = useParams();
    const { data: customerInfraData, isLoading, error } = useQuery({ queryKey: ['customerInfraData', c_id], queryFn: () => getOneCustomerInfraListing(token, c_id), enabled: !!id })

    let currentAPProw = null;
    if (customerInfraData && id) {
        currentAPProw = customerInfraData.customerapp.find((obj) => obj.id == id)
    }
    return (
        <div className="modal fade addModel" id="editAPP" tabIndex="-1" aria-labelledby="editLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content text-wrap">
                    <div className="modal-header">
                        <h5 className="modal-title" id="viewLabel">{t("customerinfra.editApp")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {

                            <div className="editAPP row g-3">
                                {isLoading && <Loader />}

                                {customerInfraData &&
                                    <>
                                        <form className="row g-3" id='EDITappForm' onSubmit={(e) => handleStep3Submit(e, id)}>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <label className="form-label">{t("customerinfra.websitename")}</label>
                                                <input type="text" placeholder={t("customerinfra.websitename")} className="form-control" name='app_name' defaultValue={currentAPProw?.app_name} />
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <label className="form-label">{t("customerinfra.vlanallowed")}</label>
                                                <input type="text" placeholder={t("customerinfra.vlanallowed")}className="form-control" name='ip_add' defaultValue={currentAPProw?.ip_add} />
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <label className="form-label">{t("customerinfra.publicprivate")}</label>
                                                <input type="text" placeholder={t("customerinfra.publicprivate")} className="form-control" name='internal_detail' defaultValue={currentAPProw?.internal_detail} />
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <label className="form-label">{t("customerinfra.description")}</label>
                                                <input type="text" placeholder={t("customerinfra.description")} className="form-control" name='discription' defaultValue={currentAPProw?.discription} />
                                            </div>
                                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                                                <span className='me-3'>{isAdding && <Loader />}</span>
                                                <button type='submit' className="secondary_btn" disabled={isAdding}>{t("customerinfra.update")}</button>
                                            </div>
                                        </form>
                                    </>
                                }
                                {error && <p>{t("customerinfra.somethingwentwrong")}</p>}

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}