import React, { useEffect, useState } from 'react'
import Stepper from './Stepper'
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../../store/UserStore';
import axios from 'axios';
import { BASE_URL } from '../../../constant/const';
import { toast, ToastContainer } from 'react-toastify';
import DeleteIcon from '../../../assets/images/Icons/delete.svg'
import Swal from 'sweetalert2';
import ToggleButton from '../../commonForm/ToggleButton';
import { t } from '../../Language/Translator';

const AddCustomerStep4 = ({ activeStep, setActiveStep }) => {
    const [loading, setLoading] = useState(false);
    const customer_Id = localStorage.getItem('customerId-add');
    const companyname = localStorage.getItem('companyname-add')


    const navigate = useNavigate();
    const customerService_data = useUserStore((state) => state.user);
    const token = customerService_data;

    const [serviceInfo, setServiceInfo] = useState([]);
    const [serviceName, setServiceName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [status, setStatus] = useState('');   
    const [toggle, setToggle] = useState({ isActive: false });

    useEffect(() => {
        getallServiceInfo();
    }, [])


    // -----------------------------------------GET ALL CUSTOMER SERVICE DATA-------------------------
    async function getallServiceInfo() {
        setLoading(true)

        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token
                },
            };
            const response = await axios.get(`${BASE_URL}customer/fetchCustomerService/${customer_Id}`, config);
            console.log(response.data.data)
            setServiceInfo(response.data.data || []);
            setLoading(false);
        }
        catch (error) {
            //console.log(error);
            setLoading(false);
        }
    }


    // ----------------------------------------CREATE NEW CUSTOMER SERVICE---------------------------------

    const handleSubmit = async (event) => {
        event.preventDefault();
        let serviceStatus;
        if (status === "Active") {
            serviceStatus = true;
        } else if (status === "In Active") {
            serviceStatus = false;
        } else {
            toast.warning("Please Provide Status.");
            setLoading(false);
            return;
        }
        const customerServiceContractData = {
            customer_id: customer_Id,
            service_name: serviceName,
            service_start_date: startDate,
            service_end_date: endDate,
            service_status: serviceStatus
        };
        //console.log(customerServiceContractData, "DATA")

        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.post(`${BASE_URL}customer/addCustomerService`, customerServiceContractData, config);
            if (response.data && response.data.status) {
                toast.success(response.data.message);
                setServiceInfo(prevData => [
                    ...prevData,
                    {
                        id: response.data.data.serviceId,
                        service_name: serviceName,
                        service_start_date: startDate,
                        service_end_date: endDate,
                        status:serviceStatus
                    }
                ]);
                await getallServiceInfo();
                setServiceName('');
                setStartDate('');
                setEndDate('');
                setStatus('');
            }
            setLoading(false);

        } catch (error) {
            if (error.response) {
                toast.warning(error.response.data.message);
            } else {
                toast.error(error.message);
            }

            setLoading(false);
            // handleResponse(error);
        }
    }



    //-----------------------------------------DELETE SERVICE CONTRACT------------------------------------------


    async function handleDeleteServiceContractInformation(id) {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.delete(`${BASE_URL}customer/delete-customer-service/${customer_Id}/${id}`, config);
            toast.success(response.data.message);
            await getallServiceInfo();
        }
        catch (error) {
            if (error.response) {
                toast.warning(error.response.data.message);
            }
            else {
                toast.error(error.message);
            }
        }
    };


    function deleteCustomerService(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this customer!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#19C489',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                handleDeleteServiceContractInformation(id);
                setServiceInfo(prevData => prevData.filter(item => item.id !== id));
                Swal.fire(
                    'Deleted!',
                    'Your item has been deleted.',
                    'success'
                );
            }
        });
    };



    const handleToggle = () => {
        setToggle(prevItem => ({ ...prevItem, isActive: !prevItem.isActive }));
    };

    const handlePrevious = () => {
        setActiveStep(2);
        // navigate('/contactinformation')
    };

    const handlesubmit = () => {
        localStorage.clear();
        navigate('/customerlist')
    }

    return (

        <>
            <div className='mainBody'>
                <ToastContainer/>
                <div className="top_section">
                    <div className="pagesNamelink">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/dashboard">{t("admindashboard.dashboard")}</a></li>
                                <li className="breadcrumb-item"><a href="/customerlist">{t("customers.customers")}</a></li>
                                <li className="breadcrumb-item active text-white" aria-current="page">{t("customers.addcustomer")}</li>
                            </ol>
                        </nav>
                        <h4 className="text-white">{t("customerstep4.addservicecontract")} {t("customerstep4.for")} {companyname}</h4>
                    </div>
                </div>

                <div className='mainSection mt-3 mb-20'>

                    <div>
                        <Stepper activeStep={activeStep} />
                    </div>

                    <div className="tab-pane fade ml-4 mr-4 mt-5 text-white show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">
                        <h3 className="wizardHead">{t("customerstep4.servicecontract")}</h3>

                        <form className="row g-3" onSubmit={handleSubmit}>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep4.servicename")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep4.servicename")} value={serviceName} onChange={(e) => setServiceName(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep4.startdate")}</label>
                                <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep4.enddate")}</label>
                                <input type="date" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep4.status")}</label>
                                <select className="form-select" value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option selected>{t("customerstep4.selectstatus")}</option>
                                    <option>Active</option>
                                    <option>In Active</option>
                                </select>
                            </div>

                            <div className="col-xxl-12 col-xl-12 mt-20 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                                <button className="add_btn">{t("customerstep4.add")}</button>
                            </div>
                        </form>



                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                            <div className="mainTable table-responsive">
                                {/* {isLoading && <Loader />} */}
                                <table id="example" className="table table-striped table-dark">
                                    <thead>
                                        <tr>
                                            <th>{t("customerstep4.id")}</th>
                                            <th>{t("customerstep4.servicename")}</th>
                                            <th>{t("customerstep4.startdate")}</th>
                                            <th>{t("customerstep4.enddate")}</th>
                                            <th>{t("customerstep4.status")}</th>
                                            <th>{t("customerstep4.actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody disabled={loading}>
                                        {/* {loading && <Loader />} */}
                                        {serviceInfo?.map((service, index) => (
                                            <tr key={index}>
                                                <td>{service.id}</td>
                                                <td>{service.service_name}</td>
                                                <td>{new Date(service.service_start_date).toLocaleDateString('en-GB')}</td>
                                                <td>{new Date(service.service_end_date).toLocaleDateString('en-GB')}</td>
                                                <td>
                                                    <ToggleButton
                                                        item={{ isActive: service.service_status }}
                                                        onToggle={() => handleToggle(service.id)}
                                                    />
                                                </td>

                                                <td>
                                                    <div className="actionBtn flex gap-3 items-center">
                                                        {/* <button className="Edit"><img src={EditIcon} alt="Edit" /></button> */}
                                                        <button className="Delete">
                                                            <img src={DeleteIcon} alt="Delete" onClick={() => deleteCustomerService(service.id)} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end justify-content-end gap-3 mt-20">
                            <button onClick={handlePrevious} type="submit" className={`cancel_btn`}>
                                {t("customerstep4.previous")}
                            </button>
                            <button type="submit" className={`secondary_btn`} onClick={handlesubmit}>
                                {t("customerstep4.submit")}
                            </button>

                        </div>

                    </div>


                </div>
            </div>
        </>
    )
}

export default AddCustomerStep4

