import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react'
import EditIcon from "../../../assets/images/Icons/edit.svg";
import DeleteIcon from "../../../assets/images/Icons/delete.svg";
import useUserStore from '../../../store/UserStore';
import { deleteDeviceInfo, getCustomerInfraListings, getOneCustomerInfraListing, saveServerNetDevices } from '../../../services/customer_infrastructure_api';
import { toast } from 'react-toastify';
import Loader from '../../commonForm/Loader';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { t } from '../../Language/Translator';
import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';

const Step2 = ({ prevStep, nextStep }) => {
    const { c_id } = useParams();
    const [editDeviceID, setEditDeviceID] = useState(null);
    const queryClient = useQueryClient();


    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    const userData = jwtDecode(token)?.user_data;
    //console.log(userData);

    const handleStep2Submit = (e, EditID = null) => {
        e.preventDefault();
        const formdata = new FormData(e.target);
        if (EditID) {
            formdata.append("id", EditID);
        }
        formdata.append("c_id", c_id);
        if(customerInfraData?.customerinfo?.length == 0){
            formdata.append("row_num", 0);
        }

        const jsonObject = Object.fromEntries(formdata.entries());
        mutateStep2(jsonObject);

    };

    const { data: customerInfraData, isLoading, error } = useQuery({ queryKey: ['customerInfraData', c_id], queryFn: () => getOneCustomerInfraListing(token, c_id) })


    const { mutate: mutateStep2, isPending: isAdding } = useMutation({
        mutationFn: (jsonObj) => saveServerNetDevices(token, jsonObj),
        onSuccess: function (data) {
            if (data) {
                document.getElementById("EDITdeviceForm")?.reset();
                hideModal();
            }
            document.getElementById("ADDdeviceForm")?.reset();
            toast.success("Server devices info successfully saved");
            queryClient.invalidateQueries({ queryKey: ["customerInfraData"] });
        },
        onError: function (error) {
            toast.error(error.message);
        },
    });

    async function deleteDevicealert(ID) {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this information!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#19C489',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sure !'
        });

        if (result.isConfirmed) {
            try {
                console.log(token);
                console.log(ID);
                
                await deleteDeviceInfo(token, ID);
                await queryClient.invalidateQueries({ queryKey: ['customerInfraData'] });
                Swal.fire('Deleted!', 'Deleted successfully', 'success');
            } catch (error) {
                Swal.fire('Error!', error.message, 'error');
            }
        }
    }

    const hideModal = () => {
        const editModalElement = document.getElementById('editDEVICE');
        const addModalElement = document.getElementById('addDEVICE');
        const AddmodalInstance = Modal.getInstance(addModalElement) || new Modal(addModalElement);
        const EditmodalInstance = Modal.getInstance(editModalElement) || new Modal(editModalElement);
        AddmodalInstance.hide();
        EditmodalInstance.hide();
    };

    return (
        <>
            <h3 className="wizardHead">{t("customerinfra.serversnetworkdevices")}</h3>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                <button className="secondary_btn" data-bs-toggle="modal" data-bs-target="#addDEVICE">{t("customerinfra.add")}</button>
            </div>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                {isLoading && <Loader />}
                <div className="mainTable table-responsive">
                    {!isLoading && !error && customerInfraData?.customerinfo?.length > 0 &&
                        <table className="table table-dark table-striped">
                            <thead>
                                <tr>
                                    <th>{t("customerinfra.serverdevicetype")}</th>
                                    <th>{t("customerinfra.location")}</th>
                                    <th>{t("customerinfra.ipaddress")}</th>
                                    <th>{t("customerinfra.machinedevicename")}</th>
                                    <th>{t("customerinfra.role")}</th>
                                    <th>{t("customerinfra.applications")}</th>
                                    <th>{t("customerinfra.edr")}</th>
                                    <th>{t("customerinfra.publicipaddress")}</th>
                                    <th>{t("customerinfra.internetpublishedservice")}</th>
                                    <th>{t("customerinfra.description")}</th>
                                    <th>{t("customerinfra.d10notes")}</th>
                                    <th>{t("customerinfra.actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customerInfraData?.customerinfo?.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{data.device_type}</td>
                                            <td>{data.location}</td>
                                            <td>{data.ip_add}</td>
                                            <td>{data.device_name}</td>
                                            <td>{data.role}</td>
                                            <td>{data.application}</td>
                                            <td>{data.edr_type}</td>
                                            <td>{data.public_ip_add}</td>
                                            <td>{data.internet_public_service}</td>
                                            <td>{data.discription}</td>
                                            <td>{data.desct10_notes}</td>
                                            <td>
                                                <div className="actionBtn flex justify-start gap-2 items-center">
                                                    <button className="Edit m-0" data-bs-toggle="modal" data-bs-target="#editDEVICE" onClick={() => setEditDeviceID(data.id)}><img
                                                        src={EditIcon} alt="Edit" />
                                                    </button>
                                                    <button type='button' className="Delete" onClick={() => deleteDevicealert(data.id)}>
                                                        <img src={DeleteIcon} alt="Delete" /> </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    }
                    {customerInfraData?.customerinfo?.length == 0 &&
                        <p className='text-center text-white mt-4'>{t("customerinfra.noresultfound")}</p>
                    }
                    {error &&
                        <p className='text-center text-white mt-4'>{t("customerinfra.somethingwentwrong")}</p>
                    }
                </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
                <span className='me-3'>{isAdding && <Loader />}</span>
                <button className="cancel_btn previous me-3" id="bservice" onClick={prevStep}>{t("customerinfra.previous")}</button>
                <button type='button' className="secondary_btn next" id="company" disabled={isLoading} onClick={nextStep}>{t("customerinfra.next")}</button>
            </div>
            <AddDeviceModal token={token} handleStep2Submit={handleStep2Submit} isAdding={isAdding}></AddDeviceModal>
            <EditDeviceModal token={token} id={editDeviceID} handleStep2Submit={handleStep2Submit} isAdding={isAdding}></EditDeviceModal>
        </>
    );
}

export default Step2

const AddDeviceModal = ({ handleStep2Submit, isAdding }) => {

    return (
        <div className="modal fade addModel" id="addDEVICE" tabIndex="-1" aria-labelledby="editLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content text-wrap">
                    <div className="modal-header">
                        <h5 className="modal-title" id="viewLabel">{t("customerinfra.addcustomerinfrastructure")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="addDEVICE row g-3">
                            <>
                                <form className="row g-3" id='ADDdeviceForm' onSubmit={handleStep2Submit}>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                        <label className="form-label">{t("customerinfra.serverdevicetype")}</label>
                                        <input type="text" className="form-control" placeholder={t("customerinfra.serverdevicetype")} name='device_type' />
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                        <label className="form-label">{t("customerinfra.location")}</label>
                                        <input type="text" className="form-control" placeholder={t("customerinfra.location")} name='location' />
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                        <label className="form-label">{t("customerinfra.ipaddress")}</label>
                                        <input type="text" className="form-control" placeholder={t("customerinfra.ipaddress")} name='ip_add' />
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                        <label className="form-label">{t("customerinfra.machinedevicename")}</label>
                                        <input type="text" className="form-control" placeholder={t("customerinfra.machinedevicename")} name='device_name' />
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                        <label className="form-label">{t("customerinfra.role")}</label>
                                        <input type="text" className="form-control" placeholder={t("customerinfra.role")} name='role' />
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                        <label className="form-label">{t("customerinfra.applications")}</label>
                                        <input type="text" className="form-control" placeholder={t("customerinfra.applications")} name='application' />
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                        <label className="form-label">{t("customerinfra.edr")}</label>
                                        <input type="text" className="form-control" placeholder={t("customerinfra.edr")} name='edr_type' />
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                        <label className="form-label">{t("customerinfra.publicipaddress")}</label>
                                        <input type="text" className="form-control" placeholder={t("customerinfra.publicipaddress")} name='public_ip_add' />
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                        <label className="form-label">{t("customerinfra.internetpublishedservice")}</label>
                                        <input type="text" className="form-control" placeholder={t("customerinfra.internetpublishedservice")} name='internet_public_service' />
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <label className="form-label">{t("customerinfra.description")}</label>
                                        <input type="text" className="form-control" placeholder={t("customerinfra.description")} name='discription' />
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <label className="form-label">{t("customerinfra.d10notes")}</label>
                                        <input type="text" className="form-control" placeholder={t("customerinfra.d10notes")} name='desct10_notes' />
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                                        <span className='me-3'>{isAdding && <Loader />}</span>
                                        <button type='submit' className="secondary_btn" disabled={isAdding}>{t("customerinfra.add")}</button>
                                    </div>
                                </form>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

const EditDeviceModal = ({ token, id, handleStep2Submit, isAdding }) => {
    const { c_id } = useParams();
    const { data: customerInfraData, isLoading, error } = useQuery({ queryKey: ['customerInfraData', c_id], queryFn: () => getOneCustomerInfraListing(token, c_id), enabled: !!id })

    let currentDEVICErow = null;
    if (customerInfraData && id) {
        currentDEVICErow = customerInfraData.customerinfo.find((obj) => obj.id == id)
    }
    return (
        <div className="modal fade addModel" id="editDEVICE" tabIndex="-1" aria-labelledby="editLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content text-wrap">
                    <div className="modal-header">
                        <h5 className="modal-title" id="viewLabel">{t("customerinfra.editDevice")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {

                            <div className="editAPP row g-3">
                                {isLoading && <Loader />}

                                {customerInfraData &&
                                    <>
                                        <form className="row g-3" id='EDITdeviceForm' onSubmit={(e) => handleStep2Submit(e, id)}>

                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <label className="form-label">{t("customerinfra.serverdevicetype")}</label>
                                                <input type="text" className="form-control" placeholder={t("customerinfra.serverdevicetype")} name='device_type' defaultValue={currentDEVICErow?.device_type}/>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <label className="form-label">{t("customerinfra.location")}</label>
                                                <input type="text" className="form-control" placeholder={t("customerinfra.location")} name='location' defaultValue={currentDEVICErow?.location}/>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <label className="form-label">{t("customerinfra.ipaddress")}</label>
                                                <input type="text" className="form-control" placeholder={t("customerinfra.ipaddress")} name='ip_add' defaultValue={currentDEVICErow?.ip_add}/>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <label className="form-label">{t("customerinfra.machinedevicename")}</label>
                                                <input type="text" className="form-control" placeholder={t("customerinfra.machinedevicename")} name='device_name' defaultValue={currentDEVICErow?.device_name}/>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <label className="form-label">{t("customerinfra.role")}</label>
                                                <input type="text" className="form-control" placeholder={t("customerinfra.role")} name='role' defaultValue={currentDEVICErow?.role}/>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <label className="form-label">{t("customerinfra.applications")}</label>
                                                <input type="text" className="form-control" placeholder={t("customerinfra.applications")} name='application' defaultValue={currentDEVICErow?.application}/>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <label className="form-label">{t("customerinfra.edr")}</label>
                                                <input type="text" className="form-control" placeholder={t("customerinfra.edr")} name='edr_type' defaultValue={currentDEVICErow?.edr_type}/>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <label className="form-label">{t("customerinfra.publicipaddress")}</label>
                                                <input type="text" className="form-control" placeholder={t("customerinfra.publicipaddress")} name='public_ip_add' defaultValue={currentDEVICErow?.public_ip_add}/>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                                <label className="form-label">{t("customerinfra.internetpublishedservice")}</label>
                                                <input type="text" className="form-control" placeholder={t("customerinfra.internetpublishedservice")} name='internet_public_service' defaultValue={currentDEVICErow?.internet_public_service}/>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                <label className="form-label">{t("customerinfra.description")}</label>
                                                <input type="text" className="form-control" placeholder={t("customerinfra.description")} name='discription' defaultValue={currentDEVICErow?.discription}/>
                                            </div>
                                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <label className="form-label">{t("customerinfra.d10notes")}</label>
                                                <input type="text" className="form-control" placeholder={t("customerinfra.d10notes")} name='desct10_notes' defaultValue={currentDEVICErow?.desct10_notes}/>
                                            </div>
                                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                                                <span className='me-3'>{isAdding && <Loader />}</span>
                                                <button type='submit' className="secondary_btn" disabled={isAdding}>{t("customerinfra.update")}</button>
                                            </div>
                                        </form>
                                    </>
                                }
                                {error && <p>{t("customerinfra.somethingwentwrong")}</p>}

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}