import { Button } from 'bootstrap';
import React from 'react'
import { t } from '../Language/Translator';

const Pagination = ({ itemsPerPage, totalItems, paginate,currPage }) => {
    const pageNumbers = [];
  
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }
    return (
        <div className="row mt-2 justify-content-between">
        <div className="d-md-flex justify-content-between align-items-center dt-layout-start col-md-auto me-auto" style={{ color: 'white' }}>
            <div className="dt-info" aria-live="polite" id="example_info" role="status">{t("pagination.showing")} {(currPage - 1) * itemsPerPage + 1} {t("pagination.to")} {currPage == pageNumbers[pageNumbers.length-1] ? totalItems : currPage * itemsPerPage} {t("pagination.of")} {totalItems} {t("pagination.entries")}</div>
        </div>
        <div className="d-md-flex justify-content-between align-items-center dt-layout-end col-md-auto ms-auto">
            <div className="dt-paging">
                <nav>
        <ul className='pagination'>
          {pageNumbers.map(number => (
            <li key={number} className='page-item'>
              <button onClick={() => paginate(number)}  className='page-link'>
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
            </div>
        </div>
    </div>


       
      );
}

export default Pagination
