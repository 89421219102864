import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../constant/const.js';
import Loader from '../commonForm/Loader.js';
import { useNavigate } from 'react-router-dom';

import { default as RightMenu } from "./RightMenu.js";
import logo from "../../assets/images/logo.png";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
     const navigate = useNavigate();

    const [email, setemail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setconfirm_password] = useState('');
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);

    useEffect(() => {
        const currentURL = new URL(window.location.href);
        const idFromUrl = currentURL.searchParams.get('token');

        if (idFromUrl) {
            setToken(idFromUrl);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(BASE_URL + 'auth/reset-password', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password, confirm_password, token }),
            });
            const data = await response.json();
            if (data.status === 'success') {
                setLoading(false);
                toast.success(data.message);
                await new Promise((resolve) => setTimeout(resolve, 2000))
                navigate('/');

            } else {
                toast.warning(data.message);
            }
        } catch (error) {
            toast.error(error.message);
            
        } finally {
            setLoading(false);
        }
    };


    return (
        <body className="login_bg">
        <ToastContainer/>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 login_wrapper">
                        <div className="login-outer">
                            <div className="login_box ">
                                <div className="Main_logo text-center">
                                    <img src={logo} alt="logo image" className="logo_login img-fluid" />
                                </div>
                                <form onSubmit={handleSubmit} className="w-100">
                                    <h2 className="login_title">Reset password</h2>
                                    <div className="mb-xxl-4 mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Email</label>
                                        <div className="position-relative">
                                            <input className="form-control"
                                            id="email"
                                            name="email"
                                            onChange={(event) => setemail(event.target.value)}
                                            type="text"
                                            placeholder="Username"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-xxl-4 mb-3">
                                        <label for="exampleInputEmail1" className="form-label">New password</label>
                                        <div className="position-relative">
                                            <input className="form-control" placeholder="New password"
                                                id="password"
                                                name="password"
                                                onChange={(event) => setPassword(event.target.value)}
                                                type="Password"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-xxl-4 mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Confirm password</label>
                                        <div className="position-relative">
                                            <input className="form-control" placeholder="Confirm password"
                                                id="confirm_password"
                                                name="confirm_password"
                                                onChange={(event) => setconfirm_password(event.target.value)}
                                                type="Password"

                                            />
                                        </div>

                                    </div>

                                    <button type="submit"
                                        class={`login_btn mt-3 mb-xxl-3 mb-xl-3 mb-lg-1 mb-md-2 mb-3 text-white ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={loading}
                                    >
                                       Reset password</button>
                                    {loading ? <Loader /> : ''}

                                </form>

                            </div>
                        </div>
                    </div>
                    <RightMenu />
                </div>
            </div>

        </body>
    );
}

export default ResetPassword