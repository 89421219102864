import React, { useEffect, useState } from 'react';
import '../../App.css';
import { ReactComponent as ThreatIcon } from "../../assets/images/Icons/Threat.svg";
import { ReactComponent as CustomizeIcon } from "../../assets/images/Icons/Customize.svg";
import { ReactComponent as ServiceIcon } from "../../assets/images/Icons/24 Hours Service.svg";
import { ReactComponent as TrainingIcon } from "../../assets/images/Icons/Training.svg";
import { Link } from 'react-router-dom';
import { t } from '../Language/Translator';


function RightMenu() {

    const [selectedLanguage, setSelectedLanguage] = useState('Select Language');

    const handleLanguageSelect = (language) => {
        const langCode = language === 'English (en)' ? 'en' : 'es'; // Define the language code
        setSelectedLanguage(language);
        localStorage.setItem('selectedLanguage', langCode); // Store the language code
        window.location.reload(); // Reload the application to apply changes immediately
    };

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        if (storedLanguage) {
            const languageLabel = storedLanguage === 'en' ? 'English (en)' : 'Spanish (es)';
            setSelectedLanguage(languageLabel); // Set the language label based on the stored code
        } else {
            localStorage.setItem('selectedLanguage', 'en'); // Store 'en' as default language
            setSelectedLanguage('English (en)'); // Set display label to English
        }
    }, []);


    return (

        <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-12 login_image">
            <div className="mainSidebox">
                <div className="sideimgBox">
                    <CustomizeIcon className="logo_login img-fluid" alt="Customize Icon" />
                    <h2>Customized Security Solutions</h2>
                    <p>Sleep soundly knowing your security is in expert hands.</p>
                </div>
                <div className="sideimgBox">
                    <ServiceIcon className="logo_login img-fluid" alt="24 Hours Service Icon" />
                    <h2>24/7 Incident Response</h2>
                    <p>When a potential incident is detected, the response team is immediately alerted.</p>
                </div>
            </div>
            <div className="mainSidebox">
                {/* <div className="rightSide mb-10">
                    <div className="dropdown">
                        <button className="text-white dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {selectedLanguage}
                        </button>
                        <ul className="dropdown-menu mt-2" aria-labelledby="dropdownMenuButton">
                            <li>
                                <Link className="dropdown-item" onClick={() => handleLanguageSelect('English (en)')}>
                                    {t("languagedropdown.eng")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" onClick={() => handleLanguageSelect('Spanish (es)')}>
                                    {t("languagedropdown.span")}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div> */}
                <div className="sideimgBox">
                    <ThreatIcon className="logo_login img-fluid" alt="Threat Icon" />
                    <h2>Vulnerability Assessment</h2>
                    <p>It helps assess the level of risk associated with each vulnerability.</p>
                </div>
                <div className="sideimgBox">
                    <TrainingIcon className="logo_login img-fluid" alt="Training Icon" />
                    <h2>User Training Programs</h2>
                    <p>Security awareness training helps you to recognize and avoid cyber threats. </p>
                </div>
            </div>
        </div>

    );
}

export default RightMenu;