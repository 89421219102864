import React, { useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import LeftMenu from './LeftMenu';

const MasterLayout = ({ children }) => {
    const mainSectionRef = useRef(null);
    const sidebarRef = useRef(null);

    function toggleSidebar(sideBarRef) {
        sideBarRef.current.classList.toggle("sidebar_collpased");
        mainSectionRef.current.classList.toggle("main_collapsed");
    }

    return (

        <section>
            <div className="mainOuter" >
                <LeftMenu toggleSidebar={()=>toggleSidebar(sidebarRef)} sidebarRef={sidebarRef}/>
                <div className="main" id="main" ref={mainSectionRef}>
                    <Header toggleSidebar={()=>toggleSidebar(sidebarRef)} sidebarRef={sidebarRef}/>
                    <div className="mainBody">
                        {children}

                        <footer className="mt-10"><Footer /></footer>

                    </div>


                </div>

            </div>

        </section>


    )
}

export default MasterLayout;