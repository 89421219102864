import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../constant/const.js';
import axios from 'axios';
import useUserStore from '../../store/UserStore.js';
import { t } from '../Language/Translator.js';

const City = ({stateId,onCitySelect,selectedvaluecity}) => {

    const [city, setCity] = useState([]);
    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    useEffect(() => {
      try {
      const fetchCountries = async () => {
        const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": token,
            },
        };
        if(stateId){
        const response = await axios.get(`${BASE_URL}user/getcities/${stateId}`, config);
        setCity(response.data || []);
        }
        else{
          setCity([]);
        }
      };
      fetchCountries();
    } catch (error) {
      setCity([]);
    }
  
     
    }, [stateId]);

  return (
    <select className="form-control" onChange={(e) => onCitySelect(e.target.value)} 
    value={selectedvaluecity ||""}
    >
      <option value="">{t("addressdropdown.selectcity")}</option> 
      {city.map((city) => (
        <option key={city.id} value={city.id}>
          {city.name}
        </option>
      ))}
    </select>
  )
};

export default City