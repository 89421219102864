import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../constant/const.js';
import axios from 'axios';
import useUserStore from '../../store/UserStore.js';
import { t } from '../Language/Translator.js';

const State = ({countryId, onStateSelect, selectedvaluestate}) => {


     const [states, setStates] = useState([]);
    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    useEffect(() => {
      const fetchCountries = async () => {
        const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": token,
            },
        };
        if(countryId){
          const response = await axios.get(`${BASE_URL}user/getstates/${countryId}`, config);
          setStates(response.data || []);
        }
        else{
          setStates([]);
        }
        
      };
  
      fetchCountries();
    }, [countryId]);

  return (
    <select className="form-control" value={selectedvaluestate} onChange={(e) => onStateSelect(e.target.value)} 
    
    >
    {!selectedvaluestate ? (
      <option value="">{t("addressdropdown.selectstate")}</option>
    ) : null}
      
      {states.map((State) => (
        <option key={State.id} value={State.id}>
          {State.name}
        </option>
      ))}
    </select>
  )
};

export default State