import { toast } from "react-toastify";
import { BASE_URL } from "../constant/const";


export async function getTickets(token, currPage) {
    try {
        const ticketResponse = await fetch(`${BASE_URL}ticket/tickets?page=${currPage}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!ticketResponse.ok) {
            throw new Error(`HTTP Error: ${ticketResponse.status}-${ticketResponse.statusText}`);
        }
        const ticketData = await ticketResponse.json();
        if (ticketData.status == 'error') {
            throw new Error(ticketData.message);
        }
        return ticketData;
    }
    catch (error) {
        throw new Error(error);
    }
}

export async function getTicket(token, id) {
    try {
        const ticketResponse = await fetch(`${BASE_URL}ticket/view-ticket/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!ticketResponse.ok) {
            throw new Error(`HTTP Error: ${ticketResponse.status}-${ticketResponse.statusText}`);
        }
        const ticketData = await ticketResponse.json();
        if (ticketData.status == 'error') {
            throw new Error(ticketData.message);
        }
        // console.log(ticketData);

        return ticketData;
    }
    catch (error) {
        throw new Error(error);
    }
}

export async function createTicket(token, ticketDataObj) {
    try {
        const ticketResponse = await fetch(`${BASE_URL}ticket/create-ticket`, {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: ticketDataObj,
        });
        const ticketData = await ticketResponse.json();
        if (!ticketResponse.ok && ticketData.status == 'error') {
            throw new Error(ticketData.message);
        }
        return ticketData;
    }
    catch (error) {
        throw new Error(error.message);

    }
}

export async function updateTicket(token, ticketDataObj, id) {
    try {
        const ticketResponse = await fetch(`${BASE_URL}ticket/update-ticket/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': token
            },
            body: ticketDataObj,
        });
        const ticketData = await ticketResponse.json();
        if (!ticketResponse.ok && ticketData.status == 'error') {
            throw new Error(ticketData.message);
        }
        return ticketData;
    }
    catch (error) {
        throw new Error(error.message);

    }
}

export async function updateTicketByCustomer(token, ticketDataObj, id) {
    try {
        const ticketResponse = await fetch(`${BASE_URL}ticket/customer/update-ticket/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': token
            },
            body: ticketDataObj,
        });
        const ticketData = await ticketResponse.json();
        if (!ticketResponse.ok && ticketData.status == 'error') {
            throw new Error(ticketData.message);
        }
        return ticketData;
    }
    catch (error) {
        throw new Error(error.message);

    }
}

export async function deleteTicket(token, ticketID) {
    try {
        if (!ticketID) {
            throw new Error('TicketID unavailable!');
        }
        const ticketResponse = await fetch(`${BASE_URL}ticket/delete-ticket/${ticketID}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!ticketResponse.ok) {
            throw new Error(`HTTP Error: ${ticketResponse.status}-${ticketResponse.statusText}`);
        }
        const ticketData = await ticketResponse.json();
        if (ticketData.status == 'error') {
            throw new Error(ticketData.message);
        }
        return ticketData;
    }
    catch (error) {
        throw new Error(error.message);
    }
}

export async function filterTickets(token, ticketDataObj) {
    try {
        const requestResponse = (Object.fromEntries(ticketDataObj.entries()))
        const ticketResponse = await fetch(`${BASE_URL}ticket/searchAndFilter-ticket`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(requestResponse),
        });
        const ticketData = await ticketResponse.json();
        if (!ticketResponse.ok || ticketData.status == 'error') {
            throw new Error(ticketData.message || 'Something went wrong');
        }
        return ticketData;
    }
    catch (error) {
        throw new Error(error.message);

    }
}

export async function getArchivedTickets(token, currPage) {
    try {
        const ticketResponse = await fetch(`${BASE_URL}ticket/archived-tickets?page=${currPage}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!ticketResponse.ok) {
            throw new Error(`HTTP Error: ${ticketResponse.status}-${ticketResponse.statusText}`);
        }
        const ticketData = await ticketResponse.json();
        if (ticketData.status == 'error') {
            throw new Error(ticketData.message);
        }
        return ticketData;
    }
    catch (error) {
        throw new Error(error);
    }
}


export async function restoreArchivedTickets(token, id) {
    try {
        const ticketResponse = await fetch(`${BASE_URL}ticket/restore-archived-ticket/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!ticketResponse.ok) {
            throw new Error(`HTTP Error: ${ticketResponse.status}-${ticketResponse.statusText}`);
        }
        const ticketData = await ticketResponse.json();
        if (ticketData.status == 'error') {
            throw new Error(ticketData.message);
        }
        return ticketData;
    }
    catch (error) {
        throw new Error(error);
    }
}


export async function removeTicketAttachment(token, ticketUUID, fileName) {
    try {
        if (!ticketUUID || !fileName) {
            throw new Error('No such data exist!');
        }
        const ticketResponse = await fetch(`${BASE_URL}ticket/remove-attachment/${ticketUUID}/${fileName}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        const ticketData = await ticketResponse.json();
        if (ticketData.status == 'error') {
            throw new Error(ticketData.message);
        }
        return ticketData;
    }
    catch (error) {
        throw new Error(error.message);
    }
}


// Extra API's for ticket module

export async function getAlertsForTickets(token) {
    try {
        const ticketResponse = await fetch(`${BASE_URL}ticket/alertTypes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!ticketResponse.ok) {
            throw new Error(`HTTP Error: ${ticketResponse.status}-${ticketResponse.statusText}`);
        }
        const ticketData = await ticketResponse.json();
        if (ticketData.status == 'error') {
            throw new Error(ticketData.message);
        }
        return ticketData;
    }
    catch (error) {
        throw new Error(error);
    }
}

// Updating chat count to 0

export async function updateChatCount(token, ticketID) {
    try {
        const ticketResponse = await fetch(`${BASE_URL}ticket/delete-notifications/${ticketID}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            },
        });
        const ticketData = await ticketResponse.json();
        // console.log(ticketData);
        
        if (!ticketResponse.ok && ticketData.status == 'error') {
            
        }
        return ticketData;
    }
    catch (error) {
        console.log(error);
    }
}
