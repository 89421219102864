const Spanish = {
    login: {
        logintitle:"Iniciar sesión",
        username: "Nombre de usuario.",
        password: "Contraseña.",
        forgetpassword:"Olvidé la contraseña ?",
        sendotp: "Enviar OTP."
    },
    otp: {
        enterotp:"Ingresar OTP",
        submitotp:"Enviar OTP",
        resendotp:"Reenviar OTP"
    },
    dashboardleftmenu: {
        menu1: "Panel de control",
        menu2: "Usuarios",
        menu3: "Boleto",
        menu4: "Clientes",
        menu5: "Red de Clientes",
        menu6: "Reportes"
    },
    languagedropdown:{
        eng:"Inglés (en)",
        span:"Español (es)"
    },
    dashboardheaderdropdown:{
        profile:"Ver perfil",
        password:"Cambiar clave",
        logout:"Cerrar sesión"
    },
    admindashboard:{
        dashboard:"Panel de control",
        view:"Vista",
        critical:"Crítico",
        high:"Alto",
        medium:"Medio",
        low:"Bajo",
    },
    admindashboardgraphs:{
        linecharttitle:"Principales Riesgos a lo Largo del Tiempo (Año Actual)",
        piecharttitle:"Desglose de la Tasa de Riesgo",
        tabletitle:"Recuento de Riesgos",
        bargraphtitle:"Gestión de Tickets (Año Actual)"
    },
    pagination:{
        showing:"Proyección",
        to:"a",
        of:"de",
        entries:"entradas"
    },
    addressdropdown:{
        selectcountry:"Selecciona País",
        selectstate:"Selecciona Estado",
        selectcity:"Selecciona ciudad",
    },
    stepper:{
        companyinformation:"Información de la Empresa",
        locations:"Ubicaciones",
        contactinformation:"Información de Contacto",
        servicecontract:"Servicio/Contrato"
    },
    footer:{
        copyright:"Derechos de autor © 2025 DECEPT10®. Todos los derechos reservados."
    },
    users:{
        user:"Usuario",
        userslist:"Lista de Usuarios",
        adduser:"Añadir Usuario",
        clearfilters: "Limpiar Filtros",
        userid:"ID de Usuario",
        username:"Nombre de usuario",
        name:"Nombre",
        email:"Correo Electrónico",
        phoneno:"Número de Teléfono",
        role:"Papel",
        status:"Estado",
        actions:"Acciones",
        apply:"Aplicar",
    },
    addeditusers:{
        adduser:"Añadir Usuario",
        firstname:"Nombre de Pila",
        lastname:"Apellido",
        username:"Nombre de Usuario",
        email:"Correo Electrónico",
        phoneno:"Número de Teléfono.",
        alternatephoneno:"Número de Teléfono Alternativo",
        dateofbirth:"Fecha de Nacimiento",
        role:"Papel",
        address:"Dirección",
        address2:"Dirección 2",
        country:"País",
        state:"Estado",
        city:"Ciudad",
        zipcode:"Código Postal",
        submit:"Enviar",
        status:"Estado",
        selectstatus:"--Selecciona Estado--",
        selectrole:"--Selecciona Papel--",
        cancel:"Cancelar",
        update:"Actualizar"
    },
    tickets:{
        ticket:"Ticket",
        ticketslist:"Ticket de incidencia",
        archievedtickets:"Tickets Archivados",
        addticket:"Añadir Ticket",
        clearfilters: "Limpiar Filtros",
        ticketid:"ID de Ticket",
        alert:"Alerta",
        customer:"Cliente",
        serverity:"Gravedad",
        analysts:"Analistas",
        status:"Estado",
        utctimestamp:"UTC Marca de tiempo",
        timestamp:"Marca de hora",
        destinationip:"IP de Destino",
        actions:"Acciones",
        apply:"Aplicar",
    },
    addeditticket:{
        ticket:"Ticket",
        addticket:"Añadir Ticket",
        editticket:"Editar Ticket",
        alerttype:"Tipo de Alerta",
        selectalerttype:"--Selecciona Tipo de Alerta--",
        alert:"Alerta",
        severitytype:"Gravedad",
        selectseveritytype:"--Selecciona Tipo de Gravedad--",
        customer:"Cliente",
        selectcustomer:"--Selecciona Tipo de Cliente--",
        domain:"Domainio",
        assignto:"Asignar a",
        sourceip:"IP de Origen",
        sourceport:"Puerto de Origen",
        selectsourceport:"--Selecciona Puerto de Origen--",
        sourceportmanual:"Puerto de Origen (Manual)",
        destinationip:"IP de Destino",
        destinationport:"Puerto de Destino",
        selectdestinationport:"--Selecciona Puerto de Destinot--",
        destinationportmanual:"Puerto de Destino (Manual)",
        virustotallink:"Enlace de VirusTotal",
        abuseddblink:"Enlace de Abuse DB",
        timestamp:"Marca de tiempo",
        processnamefilename:"Nombre del Proceso/ Nombre del Archivo",
        filehash:"Hash de Archivo",
        status:"Estado",
        attachments:"Adjuntos",
        browse:"Navegar",
        file:"Elige los archivos (máx. 1 MB)",
        filesselected:"Archivos seleccionados",
        customersummary:"Resumen del cliente (Solo para clientes)",
        customersummaryplaceholder:"Resumen del cliente",
        summary:"Resumen",
        cancel:"Cancelar",
        submit:"Enviar",
    },
    viewticket:{
        viewticket:"Ver Ticket",
        alert:"Alerta",
        alerttype:"Tipo de Alerta",
        customer:"Cliente",
        severity:"Gravedad",
        assignto:"Asignar a",
        sourceip:"IP de Origen",
        destinationip:"IP de Destino",
        timestamp:"Marca de hora",
        status:"Estado",
        processnamefilename:"Nombre del Proc/Archivo",
        domain:"Domainio",
        attachments:"Adjuntos",
        virustotallink:"Enlace de VirusTotal",
        abuseddblink:"Enlace de Abuse DB",
        filehash:"Hash de Archivo",
        summary:"Resumen",
        customersummary:"Resumen del cliente",
        somethingwentwrong:"Algo salió mal. Por favor, inténtalo de nuevo más tarde. !",
    },
    customers:{
        customers:"Clientes",
        customerslist:"Lista de Clientes",
        addcustomer:"Anadir Cliente",
        clearfilters: "Limpiar Filtros",
        customerid:"Id de Cliente",
        phone:"Teléfono",
        email:"Correo Electrónico",
        companyname:"Nombre de la Empresa",
        lastlogindate:"Última conexión",
        status:"Estado",
        actions:"Acciones",
        apply:"Aplicar",
    },
    customerstep1:{
        addcustomer:"Anadir Cliente",
        editcustomer:"Editar Cliente",
        companyinformation:"Información de la Empresa",
        companyname:"Nombre de la Empresa",
        address:"Dirección",
        address2:"Dirección 2",
        country:"País",
        state:"Estado",
        city:"Ciudad",
        zipcode:"Código Postal",
        phoneno:"Número de Teléfono.",
        alternatephoneno:"Número de Teléfono Alternativo",
        email:"Correo Electrónico",
        next:"Siguiente"
    },
    customerstep2:{
        locations:"Ubicaciones",
        addlocation:"Anadir Ubicación",
        editlocation:"Editar Ubicación",
        for:"por",
        id:"ID",
        locationtype:"Tipo de Ubicación",
        address:"Dirección",
        address2:"Dirección 2",
        actions:"Acciones",
        country:"País",
        state:"Estado",
        city:"Ciudad",
        zipcode:"Código Postal",
        phoneno:"Número de Teléfono.",
        alternatephoneno:"Número de Teléfono Alternativo.",
        email:"Correo Electrónico",
        cancel:"Cancelar",
        add:"Añadir",
        previous:"Anterior",
        next:"Siguiente",
        update:"Actualizar"
    },
    customerstep3:{
        addcontactinformation:"Anadir Información de Contacto",
        editservicecontractinformation:"Editar Información de Contacto",
        for:"por",
        contactinformation:"Información de Contacto",
        contactname:"Nombre de Contacto",
        phoneno:"Número de Teléfono.",
        alternatephoneno:"Número de Teléfono Alternativo.",
        email:"Correo Electrónico",
        add:"Añadir",
        id:"ID",
        actions:"Acciones",
        previous:"Anterior",
        next:"Siguiente",
    },
    customerstep4:{
        addservicecontract:"Añadir Servicio/Contrato",
        editservicecontract:"Editar Servicio/Contrato",
        for:"for",
        servicecontract:"Servicio/Contrato",
        servicename:"Nombre del Servicio",
        startdate:"Fecha de Inicio",
        enddate:"Fecha de Finalización.",
        status:"Estado",
        add:"Añadir",
        id:"ID",
        actions:"Acciones",
        previous:"Anterior",
        submit:"Enviar",
        selectstatus:"--Selecciona Estado--"
    },
    viewcustomer:{
        viewcustomer:"Ver Cliente",
        companyinformation:"Información de la Empresa",
        companyname:"Nombre de la Empresa",
        address:"Dirección",
        address2:"Dirección 2",
        country:"País",
        state:"Estado",
        city:"Ciudad",
        zipcode:"Código Postal",
        phoneno:"Número de Teléfono.",
        alternatephoneno:"Número de Teléfono Alternativo",
        email:"Correo Electrónico",
        customerlocations:"Ubicaciones de Clientes",
        locationtype:"Tipo de Ubicación",
        nolocation:"No se encontraron ubicaciones para este cliente.",
        contactinformation:"Información de Contacto",
        contactname:"Nombre de Contacto",
        nocontact:"No se encontró información de contacto para este cliente.",
        servicecontract:"Servicio/Contrato",
        startdate:"Fecha de Inicio",
        enddate:"Fecha de Finalización.",
        status:"Estado",
        noservice:"No se encontró información de servicio para este cliente.",
    },
    customerinfra:{
        customerinfrastructure:"Infraestructura del Cliente",
        addcustomerinfrastructure:"Añadir Infraestructura del Cliente",
        customerinfralist:"Lista de Infraestructura del Cliente ",
        infrastructurelisting:"Listado de Infraestructura",
        addnewinfra:"Añadir Nueva Infraestructura",
        selectcustomer:"--Selecciona Tipo de Cliente--",
        addinfrastructure:"Add Infrastructure",
        clearfilters:"Limpiar Filtros",
        serverdevicetype:"Tipo de Servidor/Dispositivo",
        devicetype:"Tipo de Dispositivo",
        devicename:"Nombre del Dispositivo",
        location:"Ubicacione",
        machinedevicename:"Nombre de la Máquina/Dispositivo",
        role:"Papel",
        edrtype:"Tipo de EDR",
        edr:"EDR",
        application:"Aplicación",
        websitename:"Nombre de la Aplicación/Sitio Web",
        vlanallowed:"VLAN permitida/IP permitida/Todos",
        publicprivate:"Público/Privado",
        description:"Descripción",
        applications:"Aplicaciónes",
        ipaddress:"Dirección IP",
        publicipaddress:"Dirección IP Pública",
        internetpublishedservice:"Servicio Publicado en Internet",
        d10notes:"Notas D10",
        decept10socinfo:"Información del SOC Decept10",
        actions:"Acciones",
        apply:"Aplicar",
        vlaninfo:"Información sobre VLAN",
        vlanname:"Nombre de VLAN",
        vlanipmask:"IP/Máscara de VLAN",
        add:"Añadir",
        next:"Siguiente",
        submit:"Enviar",
        update:"Actualizar",
        viewticket:"Ver Ticket",
        previous:"Anterior",
        serversnetworkdevices:"Dispositivos de Red de Servidores",
        noresultfound:"No se Encontraron Resultados !",
        somethingwentwrong:"Algo salió mal. Por favor, inténtalo de nuevo más tarde. !",
    },
    report:{
        report:"Reporte",
        exportas:"Exportar como",
        pdf:"PDF",
        customers:"Clientes",
        analyst:"Analistas",
        selectdays:"Seleccionar Días",
        status:"Estado",
        severity:"Gravedad",
        submit:"Enviar",
        clearfilters:"Limpiar Filtros",
        piechart:"Desglose de la Tasa de Riesgo",
        bargraph:"Gestión de Tickets (Año Actual)",
        noresultsfound:"No se Encontraron Resultados !",
        
    },

};
export default Spanish;