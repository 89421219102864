import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Client as TwilioClient } from "@twilio/conversations";
import { BASE_URL } from '../../../constant/const.js';
import UserStore from '../../../store/UserStore.js';
import Loader from "../../commonForm/Loader.js";
import { toast } from "react-toastify";

const MessageContainer = ({ chatId }) => {
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [conversation, setConversation] = useState(null);
    const messagesEndRef = useRef(null); // Ref for auto-scroll

    const user_data = UserStore((state) => state.user);
    const token = user_data;

    const user_info = UserStore((state) => state.userInfo);
    const username = user_info.email;

    useEffect(() => {
        const initChat = async () => {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            try {
                const { data } = await axios.post(`${BASE_URL}twilio/token`, { identity: username },config);

                if (!data.token) {
                    throw new Error("❌ Failed to get token from backend");
                }

                const twilioClient = new TwilioClient(data.token);

                twilioClient.on("initialized", async () => {
                    try {
                        // Check if the conversation with the given chatId exists
                        const { data: convData } = await axios.get(`${BASE_URL}twilio/getconversation/?chatId=${chatId}`,config);
                        let activeConversation;

                        if (convData.sid) {
                            activeConversation = await twilioClient.getConversationBySid(convData.sid);
                        } else {
                            // Create a new conversation if it doesn't exist
                            const response = await axios.post(`${BASE_URL}twilio/createconversation`, { friendlyName: `Chat for ${chatId}` },config);
                            activeConversation = await twilioClient.getConversationBySid(response.data.sid);
                        }

                        setConversation(activeConversation);

                        // Add the user to the conversation
                        await axios.post(`${BASE_URL}twilio/adduser`, {
                            conversationSid: activeConversation.sid,
                            identity: username,
                        },config);

                        // Fetch previous messages
                        const fetchedMessages = await activeConversation.getMessages();
                        setMessages(fetchedMessages.items.map((msg) => ({ author: msg.author, body: msg.body })));
                        setLoading(false);

                        // Listen for new messages
                        activeConversation.on("messageAdded", handleMessageReceived);
                        setLoading(false);
                    } catch (error) {
                        console.error("❌ Error fetching or creating conversation:", error);
                    }
                });

                twilioClient.on("stateChanged", (state) => console.log("🔄 ",));
                
            } catch (error) {
                console.error("❌ Error initializing chat:", error);
            }
        };

        if (username && chatId) {
            setLoading(true);
            initChat();
           // setLoading(false);
        }
    }, [username, chatId]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const handleMessageReceived = (message) => {
        if (message.author !== username) {
            // Check if the message already exists to avoid duplicates
            setMessages((prevMessages) => {
                const messageExists = prevMessages.some(msg => msg.body === message.body && msg.author === message.author);
                if (!messageExists) {
                    // toast.info('Hi new message received');
                    return [...prevMessages, { author: message.author, body: message.body }];
                }
                return prevMessages; // Return previous messages if the new message is a duplicate
            });
        }
    };

    const sendMessage = async () => {
        if (!conversation || newMessage.trim() === "") {
            return;
        }

        try {
            await conversation.sendMessage(newMessage,chatId);
            addMessageToState(username, newMessage); // Add the sent message to the state
            setNewMessage("");
        } catch (error) {
            console.error("❌ Error sending message:", error);
        }
    };

    const addMessageToState = (author, body) => {
        setMessages((prevMessages) => [...prevMessages, { author, body }]);
    };

    return (
        <div style={styles.chatContainer}>
            <div style={styles.chatBox}>
            {loading ? 
                <>
                  <p> 
                    <span className="animate-pulse text-sm text-white">Creating a safe space for our chat...</span>
                  </p>
                  <Loader />
                </>
                : ''}
                {messages.map((msg, index) => (
                    <div key={index} style={{
                        ...styles.message,
                        alignSelf: msg.author === username ? "flex-end" : "flex-start",
                        backgroundColor: msg.author === username ? "#dcf8c6" : "#fff"
                    }}>
                        <strong>{(msg.author).split('@')[0]}:</strong>
                        <span>{msg.body}</span>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div style={styles.inputContainer}>
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a message..."
                    style={styles.input}
                    onKeyDown={(e)=>{if(e.key === "Enter"){
                        e.preventDefault();
                        sendMessage(e);
                    }
                    }}
                />
                <button type="button" onClick={sendMessage} style={styles.sendButton}>Send</button>
            </div>
        </div>
    );
};

const styles = {
    chatContainer: {
        // Add any additional styles for the chat container if needed
    },
    chatBox: {
        height: "300px",
        overflowY: "auto",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        color: "Black"
    },
    message: {
        width: "70%",
        wordWrap:"break-word",
        overflowWrap:"break-word",
        whiteSpace:"pre-wrap",
        textWrap: "wrap",
        padding: "8px",
        borderRadius: "10px",
        marginBottom: "5px",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    },
    inputContainer: {
        display: "flex",
        marginTop: "10px",
    },
    input: {
        flex: 1,
        padding: "8px",
        borderRadius: "5px",
        border: "1px solid #ccc",
        color: "Black"
    },
    sendButton: {
        marginLeft: "10px",
        padding: "8px 12px",
        border: "none",
        borderRadius: "5px",
        backgroundColor: "#007bff",
        color: "#fff",
        cursor: "pointer",
    },
};

export default MessageContainer;