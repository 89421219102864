import React, { useEffect, useState } from 'react';
import Stepper from '../Stepper';
import useUserStore from '../../../../store/UserStore';
import EditIcon from '../../../../assets/images/Icons/edit.svg';
import DeleteIcon from '../../../../assets/images/Icons/delete.svg';
import AddLocation from './AddLocation'
import axios from 'axios';
import { BASE_URL } from '../../../../constant/const';
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';
import { t } from '../../../Language/Translator';

const AddCustomerStep2 = ({ activeStep, setActiveStep }) => {

    const [locationData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [AddLocationModalOpen, setAddLocationModalOpen] = useState(false);



    const location_data = useUserStore((state) => state.user);
    const token = location_data;
    const customerId = localStorage.getItem('customerId-add');
    const companyname = localStorage.getItem('companyname-add')
    

    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getAllLocations();
    }, []);

    



    //------------------------------GET ALL LOCATIONS----------------------------------

    async function getAllLocations() {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.get(`${BASE_URL}customer/fetchCustomerLocations/${customerId}?page=${currentPage}`, config);
            setData(response.data.data || []);
            setLoading(false);
        }
        catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };





    //----------------------------DELETE LOCATION RECORD---------------------------------

    async function handleDeleteCustomerlocation(locationId) {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.delete(`${BASE_URL}customer/delete-customer-location/${locationId}`, config);
            toast.success(response.data.message);
        }
        catch (error) {
            if (error.response) {
                toast.warning(error.response.data.message);
            }
            else {
                toast.error(error.message);
            }
        }
    };


    function deleteCustomerlocationalert(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this customer!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#19C489',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                handleDeleteCustomerlocation(id);
                setData(prevData => prevData.filter(item => item.id !== id));
                Swal.fire(
                    'Deleted!',
                    'Your item has been deleted.',
                    'success'
                );
            }
        });
    };

    const handleNext = (event) => {
        setActiveStep(2);
        event.preventDefault();
    };

    const handlePrevious = () => {
        setActiveStep(0);
    };

    const handleAddLocation = () => {
        setSelectedLocation(null);
        setAddLocationModalOpen(true);
    };

    const handleEditCustomerLocation = (location) => {
        setSelectedLocation(location);
        setAddLocationModalOpen(true);
    };

    const addNewLocation = (newLocation) => {
        setData(prevData => {
            // Check if the location already exists and update it
            const existingLocationIndex = prevData.findIndex(loc => loc.id === newLocation.id);
            if (existingLocationIndex > -1) {
                return prevData.map(loc => (loc.id === newLocation.id ? newLocation : loc));
            }
            // Add new location
            return [...prevData, newLocation];
        });
    };






    return (

        <>
            <div className='mainBody'>
                <ToastContainer/>
                <div className="top_section">
                    <div className="pagesNamelink">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/dashboard">{t("admindashboard.dashboard")}</a></li>
                                <li className="breadcrumb-item"><a href="/customerlist">{t("customers.customers")}</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">{t("customers.addcustomer")}</li>
                            </ol>
                        </nav>
                        <h4 className="text-white">{t("customerstep2.addlocation")} {t("customerstep2.for")} {companyname}</h4>
                    </div>
                </div>

                <div className='mainSection mt-3 mb-20'>

                    <div>
                        <Stepper activeStep={activeStep} />
                    </div>

                    <div className="tab-pane flex flex-row justify-between fade ml-4 mr-4 mt-5 text-white show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">
                        <h3 className="wizardHead">{t("customerstep2.locations")}</h3>
                        <div className='mb-3'>
                            <button to="" className="main_btn" onClick={handleAddLocation}><i className="fa-solid fa-plus"></i>{t("customerstep2.addlocation")}</button>
                        </div>
                    </div>

                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                        <div className="mainTable table-responsive">
                            {/* {isLoading && <Loader />} */}
                            <table id="example" className="table table-striped table-dark">
                                <thead>
                                    <tr>
                                        <th>{t("customerstep2.id")}</th>
                                        <th>{t("customerstep2.locationtype")}</th>
                                        <th>{t("customerstep2.phoneno")}</th>
                                        <th>{t("customerstep2.email")}</th>
                                        <th>{t("customerstep2.address")}</th>
                                        <th>{t("customerstep2.actions")}</th>
                                    </tr>
                                </thead>
                                <tbody disabled={loading}>

                                    {locationData?.map((location, index) => (
                                        <tr key={index}>
                                            <td>{location.id || 'N/A'}</td>
                                            <td>{location.location_type}</td>
                                            <td>{location.phone}</td>
                                            <td>{location.email}</td>
                                            <td>{location.address}</td>

                                            <td>
                                                <div className="actionBtn flex gap-3 items-center">
                                                    <button className="Edit"><img
                                                        src={EditIcon} alt="Edit" onClick={() => handleEditCustomerLocation(location)} />
                                                    </button>
                                                    <button className="Delete" onClick={() => deleteCustomerlocationalert(location.id)}>
                                                        <img src={DeleteIcon} alt="Delete" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-end gap-3 mt-20">
                        <button onClick={handlePrevious} type="submit" className={`cancel_btn`}>
                          {t("customerstep2.previous")}
                        </button>
                        <button onClick={handleNext} type="submit" className={`secondary_btn`}>
                          {t("customerstep2.next")}
                        </button>

                    </div>
                </div>
            </div>
            {AddLocationModalOpen && (
                <AddLocation
                    AddLocationModalOpen={AddLocationModalOpen}
                    setAddLocationModalOpen={setAddLocationModalOpen}
                    // customerId={customerId}
                    addNewLocation={addNewLocation}
                    selectedLocation={selectedLocation}
                />
            )}
        </>
    )
}

export default AddCustomerStep2
