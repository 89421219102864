// import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom';
// import Stepper from '../AddCustomer/Stepper';

// const EditCustomerStep4 = ({ activeStep, setActiveStep }) => {
//     const navigate = useNavigate();
//     const [userdata, setData] = useState([]);








//     const handlePrevious = () => {
//         setActiveStep(2);
//         navigate('/contactinformation')
//     };




//     return (

//         <>
//             <div className='mainBody'>

//                 <div className="top_section">
//                     <div className="pagesNamelink">
//                         <nav aria-label="breadcrumb">
//                             <ol className="breadcrumb">
//                                 <li className="breadcrumb-item"><a href="dashboard.html">Dashboard</a></li>
//                                 <li className="breadcrumb-item"><a href="ticket.html">Customers</a></li>
//                                 <li className="breadcrumb-item active" aria-current="page">Add Customer</li>
//                             </ol>
//                         </nav>
//                         <h4 className="text-white">Add Customer</h4>
//                     </div>
//                 </div>

//                 <div className='mainSection mt-3 mb-20'>

//                     <div>
//                         <Stepper activeStep={activeStep} />
//                     </div>

//                     <div className="tab-pane fade ml-4 mr-4 mt-5 text-white show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">
//                         <h3 className="wizardHead">Service/Contract</h3>

//                         <form className="row g-3">

//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Service Name</label>
//                                 <select className="form-select">
//                                     <option selected>--Select Service Name--</option>
//                                     <option>...</option>
//                                 </select>
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Start Date</label>
//                                 <input type="date" className="form-control" />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">End Date</label>
//                                 <input type="date" className="form-control" />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Status</label>
//                                 <select className="form-select">
//                                     <option selected>--Select Status--</option>
//                                     <option>...</option>
//                                 </select>
//                             </div>

//                             <div className="col-xxl-12 col-xl-12 mt-20 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
//                                 <button className="delete_btn me-3">Delete</button>
//                                 <button className="add_btn">Add</button>
//                             </div>
//                             <div className="col-12 d-flex justify-content-end gap-3 mt-20">
//                                 <button onClick={handlePrevious} type="submit" className={`cancel_btn`}>
//                                     Previous
//                                 </button>
//                                 <button type="submit" className={`secondary_btn`}>
//                                     Submit
//                                 </button>

//                             </div>
//                         </form>

//                     </div>


//                 </div>
//             </div>
//         </>
//     )
// }

// export default EditCustomerStep4




import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../constant/const';
import axios from 'axios';
import useUserStore from '../../../store/UserStore';
import DeleteIcon from '../../../assets/images/Icons/delete.svg'
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import ToggleButton from '../../commonForm/ToggleButton';
import EditStepper from './EditStepper';
import { t } from '../../Language/Translator';

const EditCustomerStep4 = ({ activeStep, setActiveStep, customerUuid }) => {


    const navigate = useNavigate();
    const customer_data = useUserStore((state) => state.user);
    const token = customer_data;
    const customer_Id = localStorage.getItem('customerId-edit');
    const companyname = localStorage.getItem('companyname-edit')

    const [serviceInfoData, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [serviceName, setServiceName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [status, setStatus] = useState('')
    const [toggle, setToggle] = useState({ isActive: true });


    useEffect(() => {
        fetchData();
    }, [])

    //-------------------------------------GET CONTRACT SERVICE INFO-----------------------------

    async function fetchData() {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token
                },
            };
            const response = await axios.get(`${BASE_URL}customer/view-customer/${customerUuid}`, config);
            //console.log(response, "===============SERVICEIFO===============");

            if (response.data && response.data.status === 'success' && response.data.data.length > 0) {
                const customerData = response.data.data[0];
                setData(customerData.customerServices || []);
            }
        }
        catch (error) {

        }
    };


    // ----------------------------------------CREATE NEW CUSTOMER SERVICE---------------------------------

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!serviceName) {
            toast.warning("Please Provide Service Name.");
            setLoading(false);
            return;
        }
        if (!startDate) {
            toast.warning("Please Provide Start Date.");
            setLoading(false);
            return;
        }
        if (!endDate) {
            toast.warning("Please Provide End Date.");
            setLoading(false);
            return;
        }
        let serviceStatus;
        if (status === "Active") {
            serviceStatus = true;
        } else if (status === "In Active") {
            serviceStatus = false;
        } else {
            toast.warning("Please Provide Status.");
            setLoading(false);
            return;
        }
        const customerServiceContractData = {
            customer_id: customer_Id,
            service_name: serviceName,
            service_start_date: startDate,
            service_end_date: endDate,
            service_status: serviceStatus
        };
        //console.log(customerServiceContractData, "DATA");

        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.post(`${BASE_URL}customer/addCustomerService`, customerServiceContractData, config);
            if (response.data && response.data.status)
            {
                toast.success(response.data.message);
                setData(prevData => [
                    ...prevData,
                    {
                        id: response.data.data.serviceId,
                        service_name:serviceName,
                        service_start_date:startDate,
                        service_end_date:endDate,
                        service_status:serviceStatus
                    }
                ])
            }
            setLoading(false);

        } catch (error) {
            if (error.response) {
                toast.warning(error.response.data.message);
            } else {
                toast.error(error.message);
            }

            setLoading(false);
            // handleResponse(error);
        }
    }



    //--------------------------------------DELETE CONTACTINFO RECORD------------------------------------------


    async function handleDeleteCustomerServiceContract(id) {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.delete(`${BASE_URL}customer/delete-customer-service/${customer_Id}/${id}`, config);
            toast.success(response.data.message);
        }
        catch (error) {
            if (error.response) {
                toast.warning(error.response.data.message);
            }
            else {
                toast.error(error.message);
            }
        }
    };


    function deleteCustomerservicecontractalert(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this customer!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#19C489',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                handleDeleteCustomerServiceContract(id);
                setData(prevData => prevData.filter(item => item.id !== id));
                Swal.fire(
                    'Deleted!',
                    'Your item has been deleted.',
                    'success'
                );
            }
        });
    };



    const handlePrevious = () => {
        setActiveStep(2);
    };

    const HandleSubmit = () => {
        localStorage.clear();
        navigate('/customerlist')
    };

    const handleToggle = () => {
        setToggle(prevItem => ({ ...prevItem, isTrue: !prevItem.isTrue }));
    };




    return (

        <>
            <div className='mainBody'>
                <ToastContainer/>
                <div className="top_section">
                    <div className="pagesNamelink">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                                <li className="breadcrumb-item"><a href="/customerlist">Customers</a></li>
                                <li className="breadcrumb-item active text-white" aria-current="page">Edit Customer</li>
                            </ol>
                        </nav>
                        <h4 className="text-white">{t("customerstep4.editservicecontract")} {t("customerstep4.for")} {companyname}</h4>
                    </div>
                </div>

                <div className='mainSection mt-3 mb-20'>

                    <div>
                        <EditStepper activeStep={activeStep} setActiveStep={setActiveStep} />
                    </div>

                    <div className="tab-pane fade ml-4 mr-4 mt-5 text-white show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">
                        <h3 className="wizardHead">{t("customerstep4.servicecontract")}</h3>

                        <form className="row g-3" onSubmit={handleSubmit}>

                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep4.servicename")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep4.servicename")} value={serviceName} onChange={(e) => setServiceName(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep4.startdate")}</label>
                                <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep4.enddate")}</label>
                                <input type="date" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep4.status")}</label>
                                <select className="form-select" value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option selected>{t("customerstep4.selectstatus")}</option>
                                    <option>Active</option>
                                    <option>In Active</option>
                                </select>
                            </div>

                            <div className="col-xxl-12 col-xl-12 mt-20 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                                <button className="add_btn">{t("customerstep4.add")}</button>
                            </div>
                        </form>

                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                            <div className="mainTable table-responsive">
                                {/* {isLoading && <Loader />} */}
                                <table id="example" className="table table-striped table-dark">
                                    <thead>
                                        <tr>
                                            <th>{t("customerstep4.id")}</th>
                                            <th>{t("customerstep4.servicename")}</th>
                                            <th>{t("customerstep4.startdate")}</th>
                                            <th>{t("customerstep4.enddate")}</th>
                                            <th>{t("customerstep4.status")}</th>
                                            <th>{t("customerstep4.actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody disabled={loading}>
                                        {/* {loading && <Loader />} */}
                                        {serviceInfoData?.map((serviceInfo, index) => (
                                            <tr key={index}>
                                                <td>{serviceInfo.id}</td>
                                                <td>{serviceInfo.service_name}</td>
                                                <td>{new Date(serviceInfo.service_start_date).toLocaleDateString('en-GB')}</td>
                                                <td>{new Date(serviceInfo.service_end_date).toLocaleDateString('en-GB')}</td>
                                                <td>
                                                    <ToggleButton
                                                        item={{ isActive: serviceInfo.service_status }} // Assuming service has an id to identify it
                                                        onToggle={() => handleToggle(serviceInfo.id)}
                                                    />
                                                </td>

                                                <td>
                                                    <div className="actionBtn flex gap-3 items-center">
                                                        {/* <button className="Edit"><img src={EditIcon} alt="Edit" /></button> */}
                                                        <button className="Delete">
                                                            <img src={DeleteIcon} alt="Delete" onClick={() => deleteCustomerservicecontractalert(serviceInfo.id)} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end justify-content-end gap-3 mt-20">
                            <button onClick={handlePrevious} type="submit" className={`cancel_btn`}>
                              {t("customerstep4.previous")}
                            </button>
                            <button type="submit" className={`secondary_btn`} onClick={HandleSubmit}>
                              {t("customerstep4.submit")}
                            </button>

                        </div>

                    </div>


                </div>
            </div>
        </>
    )
}

export default EditCustomerStep4