// import React, { useEffect, useState } from 'react';
// import Stepper from './Stepper';
// import { toast } from 'react-toastify';
// import { BASE_URL } from '../../../constant/const';
// import axios from 'axios';
// import useUserStore from '../../../store/UserStore';
// import Country from '../../commonForm/Country';
// import State from '../../commonForm/State';
// import City from '../../commonForm/City';
// import Loader from '../../commonForm/Loader';
// import { useParams } from 'react-router-dom';

// const AddCustomerStep1 = ({ activeStep, setActiveStep }) => {
//     const customer_data = useUserStore((state) => state.user);
//     const token = customer_data;
//     const { customer_uuid } = useParams();

//     // State variables
//     const [loading, setLoading] = useState(false);
//     const [companyName, setCompanyName] = useState('');
//     const [address, setAddress] = useState('');
//     const [addressTwo, setAddressTwo] = useState('');
//     const [state, setState] = useState('');
//     const [city, setCity] = useState('');
//     const [zipCode, setZipCode] = useState('');
//     const [country, setCountry] = useState('');
//     const [phoneNo, setPhoneNo] = useState('');
//     const [alternatePhoneNo, setAlternatePhoneNo] = useState('');
//     const [email, setEmail] = useState('');

//     // Fetch Customer Data if `customer_uuid` exists OR if stored in localStorage
//     useEffect(() => {
//         const storedCustomerData = localStorage.getItem('customerData');

//         if (storedCustomerData) {
//             // Load from localStorage (when navigating back)
//             const data = JSON.parse(storedCustomerData);
//             populateForm(data);
//         } else if (customer_uuid) {
//             // Fetch from API if not in localStorage
//             fetchCustomerData(customer_uuid);
//         }
//     }, [customer_uuid]);

//     // Function to fetch customer data
//     const fetchCustomerData = async (customer_uuid) => {
//         setLoading(true);
//         try {
//             const config = {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': token,
//                 },
//             };
//             const response = await axios.get(`${BASE_URL}customer/view-customer/${customer_uuid}`, config);
//             if (response.data && response.data.data) {
//                 const data = response.data.data;
//                 populateForm(data);
//                 localStorage.setItem('customerData', JSON.stringify(data));
//             }
//             toast.success(response.data.message);
//         } catch (error) {
//             toast.error(error.response?.data?.message || "Failed to fetch customer data.");
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Function to populate form fields
//     const populateForm = (data) => {
//         setCompanyName(data.company_name || '');
//         setAddress(data.address || '');
//         setAddressTwo(data.address2 || '');
//         setCity(data.city?.toString() || '');
//         setCountry(data.country?.toString() || '');
//         setState(data.state?.toString() || '');
//         setZipCode(data.zip || '');
//         setPhoneNo(data.phone || '');
//         setAlternatePhoneNo(data.alternate_phone || '');
//         setEmail(data.email || email);
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         setLoading(true);
//         let storedCustomeruuid = customer_uuid || localStorage.getItem('customer-uuid');

//         const createCustomerData = {
//             company_name: companyName,
//             address: address,
//             address2: addressTwo,
//             state: state.toString(),
//             city: city.toString(),
//             zip: zipCode,
//             country: country.toString(),
//             phone: phoneNo,
//             alternate_phone: alternatePhoneNo,
//             email: email,
//         };

//         const updateCustomerData = {
//             company_name: companyName,
//             address: address,
//             address2: addressTwo,
//             state: state.toString(),
//             city: city.toString(),
//             zip: zipCode,
//             country: country.toString(),
//             phone: phoneNo,
//             alternate_phone: alternatePhoneNo,
//             isActive: true,
//         };

//         try {
//             const config = {
//                 headers: {
//                     "Content-Type": "application/json",
//                     "Authorization": token,
//                 },
//             };
//             let response;

//             if (storedCustomeruuid) {
//                 response = await axios.put(`${BASE_URL}customer/update-customer/${storedCustomeruuid}`, updateCustomerData, config);
//                 toast.success(response.data.message || "Customer updated successfully!");
//             } else {
//                 response = await axios.post(`${BASE_URL}customer/create-customer`, createCustomerData, config);
//                 toast.success(response.data.message || "Customer added successfully!");
//                 const newCustomeruuid = response.data.data.customer_uuid;
//                 localStorage.setItem('customer-uuid', newCustomeruuid);

//                 let customerid = response.data.data.customerId;
//                 localStorage.setItem('customerId-add',customerid)

//                 let companyname = response.data.data.company_name;
//                 localStorage.setItem('companyname-add',companyname)
//             }

//             localStorage.setItem('customerData', JSON.stringify(storedCustomeruuid ? updateCustomerData : createCustomerData));
//             setActiveStep(1); // Move to Step 2
//         } catch (error) {
//             toast.error(error.response?.data?.message || "Something went wrong.");
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <>
//             <div className='mainBody'>
//                 <div className="top_section">
//                     <div className="pagesNamelink">
//                         <nav aria-label="breadcrumb">
//                             <ol className="breadcrumb">
//                                 <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
//                                 <li className="breadcrumb-item"><a href="/customerlist">Customers</a></li>
//                                 <li className="breadcrumb-item text-white" aria-current="page">Add Customer</li>
//                             </ol>
//                         </nav>
//                         <h4 className="text-white">Add Customer</h4>
//                     </div>
//                 </div>
//                 <div className='mainSection mt-3 mb-20'>
//                     <Stepper activeStep={activeStep} />
//                     <div className="tab-pane fade ml-4 mr-4 mt-5 text-white show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">
//                         <h3 className="wizardHead">Company Information</h3>
//                         {loading && <Loader />}
//                         <form className="row g-3" onSubmit={handleSubmit}>
//                             <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
//                                 <label className="form-label">Company Name</label>
//                                 <input type="text" className="form-control" placeholder="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
//                                 <label className="form-label">Address</label>
//                                 <input type="text" className="form-control" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
//                                 <label className="form-label">Address 2</label>
//                                 <input type="text" className="form-control" placeholder="Address 2" value={addressTwo} onChange={(e) => setAddressTwo(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Country</label>
//                                 <Country onCountrySelect={setCountry} selectedValueCountry={country} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">State</label>
//                                 <State countryId={country} onStateSelect={setState} selectedValueState={state} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">City</label>
//                                 <City stateId={state} onCitySelect={setCity} selectedValueCity={city} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Zip code</label>
//                                 <input type="text" className="form-control" placeholder="Zip code" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Phone No.</label>
//                                 <input type="text" className="form-control" placeholder="Phone No." value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Alternate Phone No.</label>
//                                 <input type="text" className="form-control" placeholder="Alternate Phone No." value={alternatePhoneNo} onChange={(e) => setAlternatePhoneNo(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Email</label>
//                                 <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
//                             </div>
//                             <div className="col-12 d-flex justify-content-end">
//                                 <button type="submit" className={`secondary_btn ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={loading}>
//                                     Next
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default AddCustomerStep1;



// import React, { useEffect, useState } from 'react';
// import Stepper from './Stepper';
// import { toast } from 'react-toastify';
// import { BASE_URL } from '../../../constant/const';
// import axios from 'axios';
// import useUserStore from '../../../store/UserStore';
// import Country from '../../commonForm/Country';
// import State from '../../commonForm/State';
// import City from '../../commonForm/City';
// import Loader from '../../commonForm/Loader';
// import { useParams } from 'react-router-dom';

// const AddCustomerStep1 = ({ activeStep, setActiveStep }) => {
//     const customer_data = useUserStore((state) => state.user);
//     const token = customer_data;
//     const { customer_uuid } = useParams();

//     // State variables
//     const [loading, setLoading] = useState(false);
//     const [companyName, setCompanyName] = useState('');
//     const [address, setAddress] = useState('');
//     const [addressTwo, setAddressTwo] = useState('');
//     const [state, setState] = useState('');
//     const [city, setCity] = useState('');
//     const [zipCode, setZipCode] = useState('');
//     const [country, setCountry] = useState('');
//     const [phoneNo, setPhoneNo] = useState('');
//     const [alternatePhoneNo, setAlternatePhoneNo] = useState('');
//     const [email, setEmail] = useState('');

//     // Fetch Customer Data if `customer_uuid` exists OR if stored in localStorage
//     useEffect(() => {
//         const storedCustomerData = localStorage.getItem('customerData');

//         if (storedCustomerData) {
//             // Load from localStorage (when navigating back)
//             const data = JSON.parse(storedCustomerData);
//             populateForm(data);
//         } else if (customer_uuid) {
//             // Fetch from API if not in localStorage
//             fetchCustomerData(customer_uuid);
//         }
//     }, [customer_uuid]);

//     // Function to fetch customer data
//     const fetchCustomerData = async (customer_uuid) => {
//         setLoading(true);
//         try {
//             const config = {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': token,
//                 },
//             };
//             const response = await axios.get(`${BASE_URL}customer/view-customer/${customer_uuid}`, config);
//             if (response.data && response.data.data) {
//                 const data = response.data.data;
//                 populateForm(data);
//                 localStorage.setItem('customerData', JSON.stringify(data));
//             }
//             toast.success(response.data.message);
//         } catch (error) {
//             toast.error(error.response?.data?.message || "Failed to fetch customer data.");
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Function to populate form fields
//     const populateForm = (data) => {
//         setCompanyName(data.company_name || '');
//         setAddress(data.address || '');
//         setAddressTwo(data.address2 || '');
//         setCity(data.city?.toString() || '');
//         setCountry(data.country?.toString() || '');
//         setState(data.state?.toString() || '');
//         setZipCode(data.zip || '');
//         setPhoneNo(data.phone || '');
//         setAlternatePhoneNo(data.alternate_phone || '');
//         setEmail(data.email || ''); // Set email from fetched data
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         setLoading(true);
//         let storedCustomeruuid = customer_uuid || localStorage.getItem('customer-uuid');

//         const createCustomerData = {
//             company_name: companyName,
//             address: address,
//             address2: addressTwo,
//             state: state.toString(),
//             city: city.toString(),
//             zip: zipCode,
//             country: country.toString(),
//             phone: phoneNo,
//             alternate_phone: alternatePhoneNo,
//             email: email,
//         };

//         const updateCustomerData = {
//             company_name: companyName,
//             address: address,
//             address2: addressTwo,
//             state: state.toString(),
//             city: city.toString(),
//             zip: zipCode,
//             country: country.toString(),
//             phone: phoneNo,
//             alternate_phone: alternatePhoneNo,
//             isActive: true,
//         };

//         try {
//             const config = {
//                 headers: {
//                     "Content-Type": "application/json",
//                     "Authorization": token,
//                 },
//             };
//             let response;

//             if (storedCustomeruuid) {
//                 response = await axios.put(`${BASE_URL}customer/update-customer/${storedCustomeruuid}`, updateCustomerData, config);
//                 toast.success(response.data.message || "Customer updated successfully!");
//             } else {
//                 response = await axios.post(`${BASE_URL}customer/create-customer`, createCustomerData, config);
//                 toast.success(response.data.message || "Customer added successfully!");
//                 const newCustomeruuid = response.data.data.customer_uuid;
//                 localStorage.setItem('customer-uuid', newCustomeruuid);
//                 let customerid = response.data.data.customerId;
//                 localStorage.setItem('customerId-add', customerid)

//                 let companyname = response.data.data.company_name;
//                 localStorage.setItem('companyname-add', companyname)
//             }

//             const customerDatatoStore = storedCustomeruuid ? {...updateCustomerData, email} : createCustomerData
//             localStorage.setItem('customerData', JSON.stringify(customerDatatoStore));

//             setActiveStep(1); // Move to Step 2
//         } catch (error) {
//             toast.error(error.response?.data?.message || "Something went wrong.");
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <>
//             <div className='mainBody'>
//                 <div className="top_section">
//                     <div className="pagesNamelink">
//                         <nav aria-label="breadcrumb">
//                             <ol className="breadcrumb">
//                                 <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
//                                 <li className="breadcrumb-item"><a href="/customerlist">Customers</a></li>
//                                 <li className="breadcrumb-item text-white" aria-current="page">Add Customer</li>
//                             </ol>
//                         </nav>
//                         <h4 className="text-white">Add Customer</h4>
//                     </div>
//                 </div>
//                 <div className='mainSection mt-3 mb-20'>
//                     <Stepper activeStep={activeStep} />
//                     <div className="tab-pane fade ml-4 mr-4 mt-5 text-white show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">
//                         <h3 className="wizardHead">Company Information</h3>
//                         {loading && <Loader />}
//                         <form className="row g-3" onSubmit={handleSubmit}>
//                             <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
//                                 <label className="form-label">Company Name</label>
//                                 <input type="text" className="form-control" placeholder="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
//                                 <label className="form-label">Address</label>
//                                 <input type="text" className="form-control" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
//                                 <label className="form-label">Address 2</label>
//                                 <input type="text" className="form-control" placeholder="Address 2" value={addressTwo} onChange={(e) => setAddressTwo(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Country</label>
//                                 <Country onCountrySelect={setCountry} selectedValueCountry={country} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">State</label>
//                                 <State countryId={country} onStateSelect={setState} selectedValueState={state} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">City</label>
//                                 <City stateId={state} onCitySelect={setCity} selectedValueCity={city} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Zip code</label>
//                                 <input type="text" className="form-control" placeholder="Zip code" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Phone No.</label>
//                                 <input type="text" className="form-control" placeholder="Phone No." value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Alternate Phone No.</label>
//                                 <input type="text" className="form-control" placeholder="Alternate Phone No." value={alternatePhoneNo} onChange={(e) => setAlternatePhoneNo(e.target.value)} />
//                             </div>
//                             <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                                 <label className="form-label">Email</label>
//                                 <input 
//                                     type="email" 
//                                     className="form-control" 
//                                     placeholder="Email" 
//                                     value={email}
//                                     onChange = {(e) => !customer_uuid && setEmail(e.target.value)} 
//                                     readOnly={Boolean(customer_uuid)} 
//                                 />
//                             </div>
//                             <div className="col-12 d-flex justify-content-end">
//                                 <button type="submit" className={`secondary_btn ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={loading}>
//                                     Next
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default AddCustomerStep1;





import React, { useEffect, useState } from 'react';
import Stepper from './Stepper';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL } from '../../../constant/const';
import axios from 'axios';
import useUserStore from '../../../store/UserStore';
import Country from '../../commonForm/Country';
import State from '../../commonForm/State';
import City from '../../commonForm/City';
import Loader from '../../commonForm/Loader';
import { useParams } from 'react-router-dom';
import { t } from '../../Language/Translator.js';

const AddCustomerStep1 = ({ activeStep, setActiveStep }) => {
    const customer_data = useUserStore((state) => state.user);
    const token = customer_data;
    const { customer_uuid } = useParams();

    // State variables
    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [addressTwo, setAddressTwo] = useState('');
    const [state, setState] = useState(localStorage.getItem('selectedStateId') || '');
    const [city, setCity] = useState(localStorage.getItem('selectedCityId') || '');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState(localStorage.getItem('selectedCountryId') || '');
    const [phoneNo, setPhoneNo] = useState('');
    const [alternatePhoneNo, setAlternatePhoneNo] = useState('');
    const [email, setEmail] = useState('');


    useEffect(() => {

        const selectedCountryId = localStorage.getItem('selectedCountryId') || '';
        const selectedStateId = localStorage.getItem('selectedStateId') || '';
        const selectedCityId = localStorage.getItem('selectedCityId') || '';

        setCountry(selectedCountryId);
        setState(selectedStateId);
        setCity(selectedCityId);
        //console.log(selectedCountryId,'COUNTRY_ID')

    }, []);


    // Fetch Customer Data if `customer_uuid` exists OR if stored in localStorage
    useEffect(() => {
        const storedCustomerData = localStorage.getItem('customerData');

        if (storedCustomerData) {
            // Load from localStorage (when navigating back)
            const data = JSON.parse(storedCustomerData);
            populateForm(data);
        } else if (customer_uuid) {
            // Fetch from API if not in localStorage
            fetchCustomerData(customer_uuid);
        }
    }, [customer_uuid]);

    // Function to fetch customer data
    const fetchCustomerData = async (customer_uuid) => {
        setLoading(true);
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            };
            const response = await axios.get(`${BASE_URL}customer/view-customer/${customer_uuid}`, config);
            if (response.data && response.data.data) {
                const data = response.data.data;
                populateForm(data);
                localStorage.setItem('customerData', JSON.stringify(data));
            }
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to fetch customer data.");
        } finally {
            setLoading(false);
        }
    };

 
    const populateForm = (data) => {
        setCompanyName(data.company_name || '');
        setAddress(data.address || '');
        setAddressTwo(data.address2 || '');
        setZipCode(data.zip || '');
        setPhoneNo(data.phone || '');
        setAlternatePhoneNo(data.alternate_phone || '');
        setEmail(data.email || ''); // Set email from fetched data

        // Set country, state, and city from fetched data or local storage
        const countryId = data?.country?.toString() || localStorage.getItem('selectedCountryId') || '';
        const stateId = data?.state?.toString() || localStorage.getItem('selectedStateId') || '';
        const cityId = data?.city?.toString() || localStorage.getItem('selectedCityId') || '';

        setCountry(countryId);
        setState(stateId);
        setCity(cityId);

        localStorage.setItem('selectedCountryId', countryId);
        localStorage.setItem('selectedStateId', stateId);
        localStorage.setItem('selectedCityId', cityId);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let storedCustomeruuid = customer_uuid || localStorage.getItem('customer-uuid');

        const createCustomerData = {
            company_name: companyName,
            address: address,
            address2: addressTwo,
            state: state.toString(),
            city: city.toString(),
            zip: zipCode,
            country: country.toString(),
            phone: phoneNo,
            alternate_phone: alternatePhoneNo,
            email: email,
        };

        const updateCustomerData = {
            company_name: companyName,
            address: address,
            address2: addressTwo,
            state: state.toString(),
            city: city.toString(),
            zip: zipCode,
            country: country.toString(),
            phone: phoneNo,
            // email:email,
            alternate_phone: alternatePhoneNo,
            isActive: true,
        };

        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token,
                },
            };
            let response;

            if (storedCustomeruuid) {
                response = await axios.put(`${BASE_URL}customer/update-customer/${storedCustomeruuid}`, updateCustomerData, config);
                toast.success(response.data.message || "Customer updated successfully!");
            } else {
                response = await axios.post(`${BASE_URL}customer/create-customer`, createCustomerData, config);
                toast.success(response.data.message || "Customer added successfully!");
                const newCustomeruuid = response.data.data.customer_uuid;
                localStorage.setItem('customer-uuid', newCustomeruuid);
                let customerid = response.data.data.customerId;
                localStorage.setItem('customerId-add', customerid)

                let companyname = response.data.data.company_name;
                localStorage.setItem('companyname-add', companyname)
            }

            const customerDatatoStore = storedCustomeruuid ? { ...updateCustomerData, email } : createCustomerData
            localStorage.setItem('customerData', JSON.stringify(customerDatatoStore));

            setActiveStep(1); // Move to Step 2
        } catch (error) {
            toast.error(error.response?.data?.message || "Something went wrong.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
        <ToastContainer/>
            <div className='mainBody'>
                <div className="top_section">
                    <div className="pagesNamelink">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/dashboard">{t("admindashboard.dashboard")}</a></li>
                                <li className="breadcrumb-item"><a href="/customerlist">{t("customers.customers")}</a></li>
                                <li className="breadcrumb-item text-white" aria-current="page">{t("customerstep1.addcustomer")}</li>
                            </ol>
                        </nav>
                        <h4 className="text-white">{t("customerstep1.addcustomer")}</h4>
                    </div>
                </div>
                <div className='mainSection mt-3 mb-20'>
                    <Stepper activeStep={activeStep} />
                    <div className="tab-pane fade ml-4 mr-4 mt-5 text-white show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">
                        <h3 className="wizardHead">{t("customerstep1.companyinformation")}</h3>
                        {loading && <Loader />}
                        <form className="row g-3" onSubmit={handleSubmit}>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("customerstep1.companyname")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.companyname")} value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("customerstep1.address")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.address")} value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("customerstep1.address2")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.address2")} value={addressTwo} onChange={(e) => setAddressTwo(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.country")}</label>
                                <Country onCountrySelect={setCountry} selectedvaluecountry={country} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.state")}</label>
                                <State countryId={country} onStateSelect={setState} selectedvaluestate={state} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.city")}</label>
                                <City stateId={state} onCitySelect={setCity} selectedvaluecity={city} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.zipcode")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.zipcode")} value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.phoneno")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.phoneno")} value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.alternatephoneno")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.alternatephoneno")} value={alternatePhoneNo} onChange={(e) => setAlternatePhoneNo(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.email")}</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder={t("customerstep1.email")}
                                    value={email}
                                    // onChange={(e) => !customer_uuid && setEmail(e.target.value)}
                                    onChange={(e) =>setEmail(e.target.value)}
                                    readOnly={!! localStorage.getItem('customer-uuid')}
                                />
                            </div>
                            <div className="col-12 d-flex justify-content-end">
                                <button type="submit" className={`secondary_btn ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={loading}>
                                   {t("customerstep1.next")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddCustomerStep1;