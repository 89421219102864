import React, { useEffect, useState } from 'react';
import AdminLineChart from './AdminLineChart';
import AdminPieChart from './AdminPieChart';
import useUserStore from '../../store/UserStore';
import { BASE_URL } from '../../constant/const.js';
import axios from 'axios';
import Loader from '../commonForm/Loader.js';
import critical from "../../assets/images/Icons/critical.svg";
import high from "../../assets/images/Icons/high.svg";
import low from "../../assets/images/Icons/low.svg";
import medium from "../../assets/images/Icons/medium.svg";
import overall from "../../assets/images/Icons/overall.svg";
import AdminBarChart from './AdminBarChart.js';
import AdminDashboardTable from './AdminDashboardTable.js';
import { useNavigate } from 'react-router-dom';
import { t } from '../Language/Translator.js';

const AdminDashboard = () => {
    const [tabdata, setData] = useState("");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    useEffect(() => {
        fetchData();
    }, [token, BASE_URL]);

    async function fetchData(params) {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.get(`${BASE_URL}dashboard`, config);


            setData(response.data || []);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            //  handleResponse(error);
        }
        if (loading) {
            return <Loader />;
        }

    }

    function handleBlockClick(severity) {
        navigate(`/tickets?severity=${severity}`);
    }

    return (
        <div>
            {loading ? <Loader /> : ''}

            <div className="mainBody">
                <div className="top_section">
                    <div className="pagesNamelink">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/Dashboard">{t("admindashboard.dashboard")}</a></li>
                                <li className="breadcrumb-item text-white">{t("admindashboard.view")}</li>
                            </ol>
                        </nav>
                        <h4 className="text-white">{t("admindashboard.dashboard")}</h4>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6 numCards">
                    <div className="mainSection text-white p-4 rounded-xl shadow-md flex items-center justify-center gap-4 cursor-pointer" onClick={() => handleBlockClick('critical')}>
                        <img className="img-fluid" src={critical} alt="Risk Img" />
                        <div className="riskCount my-auto">
                            <h5 className='text-gray-400 capitalize mb-1'>{t("admindashboard.critical")}</h5>
                            <p className='text-3xl font-semibold m-0'>{tabdata.criticalviolation}</p>
                        </div>
                    </div>
                    <div className="mainSection text-white p-4 rounded-xl shadow-md flex items-center justify-center gap-4 cursor-pointer" onClick={() => handleBlockClick('high')}>
                        <img className="img-fluid" src={high} alt="Risk Img" />
                        <div className="riskCount my-auto">
                            <h5 className='text-gray-400 capitalize mb-1'>{t("admindashboard.high")}</h5>
                            <p className='text-3xl font-semibold m-0'>{tabdata.highrisk}</p>
                        </div>
                    </div>
                    <div className="mainSection text-white p-4 rounded-xl shadow-md flex items-center justify-center gap-4 cursor-pointer" onClick={() => handleBlockClick('medium')}>
                        <img className="img-fluid" src={medium} alt="Risk Img" />
                        <div className="riskCount my-auto">
                            <h5 className='text-gray-400 capitalize mb-1'>{t("admindashboard.medium")}</h5>
                            <p className='text-3xl font-semibold m-0'>{tabdata.mediumrisk}</p>
                        </div>
                    </div>
                    <div className="mainSection text-white p-4 rounded-xl shadow-md flex items-center justify-center gap-4 cursor-pointer" onClick={() => handleBlockClick('low')}>
                        <img className="img-fluid" src={low} alt="Risk Img" />
                        <div className="riskCount my-auto">
                            <h5 className='text-gray-400 capitalize mb-1'>{t("admindashboard.low")}</h5>
                            <p className='text-3xl font-semibold m-0'>{tabdata.lowrisk}</p>
                        </div>
                    </div>
                    {/* <div className="mainSection text-white p-4 rounded-xl shadow-md flex items-center justify-around" onClick={() => handleBlockClick('critical')}>
                        <img className="img-fluid" src={overall} alt="Risk Img" />
                        <div className="riskCount">
                            <h5 className='text-gray-400 capitalize mb-1'>Overall</h5>
                            <p className='text-3xl font-semibold'>{tabdata.overallrisk}</p>
                        </div>
                    </div> */}
                </div>
                <div className="chartsSection row g-3 mt-1">
                    <div className="col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12">
                        <div className="chartCard mainSection">
                            {/* <h3>Top 2 Risks</h3> */}
                            <div className="graph">
                                {/* <img className="img-fluid" src="assets/images/Large_Double.png" alt="chart" /> */}
                                <AdminLineChart />
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12">
                        <div className="chartCard mainSection">
                            <div className="graph">
                                {/* <img className="img-fluid" src="assets/images/Consumption.png" alt="chart" /> */}
                                <AdminPieChart />

                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 mb-10">
                        <div className="chartCard mainSection">
                            <div className="graph">
                                {/* <img className="img-fluid" src="assets/images/CubeBhart.png" alt="chart" /> */}
                                <AdminDashboardTable />
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-10">
                        <div className="chartCard mainSection">
                            <div className="graph">
                                {/* <img className="img-fluid" src="assets/images/pieChart.png" alt="chart" /> */}
                                <AdminBarChart />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default AdminDashboard;
