import React, { useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import fileUploadImg from "../../assets/images/Icons/fileupload.svg";
import { getCustomers } from '../../services/customer_api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useUserStore from '../../store/UserStore';
import { createTicket, getAlertsForTickets } from '../../services/ticket_api';
import { toast, ToastContainer } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { format } from 'date-fns';
import Loader from '../commonForm/Loader';
import { Link, useNavigate } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import { t } from '../Language/Translator';

const AddTicket = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [dropdownSrcPort, setDropdownSrcPort] = useState("");
    const [manualSrcPort, setManualSrcPort] = useState("");
    const [dropdownDstPort, setDropdownDstPort] = useState("");
    const [manualDstPort, setManualDstPort] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const navigate = useNavigate();
    const user_data = useUserStore((state) => state.user);
    const token = user_data;

    const userData = jwtDecode(token)?.user_data;

    const queryClient = useQueryClient();
    const { data: customerData, error: customerError } = useQuery({ queryKey: ['addTicket(customer)'], queryFn: () => getCustomers(token) })
    // const { data: analystData, error: analystError } = useQuery({ queryKey: ['addTicket(analyst)'], queryFn: () => getAnalysts(token) })
    const { data: alertData, error: alertError } = useQuery({ queryKey: ['alertData(createTicket)'], queryFn: () => getAlertsForTickets(token) })

    function handleSubmitForm(e) {
        e.preventDefault();
        const formData = new FormData(e.target);

        const finalSrcPort = (dropdownSrcPort && dropdownSrcPort !== 'SRCnone') ? dropdownSrcPort : manualSrcPort;
        if (finalSrcPort) {
            formData.set("src_port", finalSrcPort);
        }
        const finalDstPort = (dropdownDstPort && dropdownDstPort !== 'DSTnone') ? dropdownDstPort : manualDstPort;
        if (finalDstPort) {
            formData.set("dst_port", finalDstPort);
        }

        formData.delete('src_port_dropdown');
        formData.delete('src_port_manual');
        formData.delete('dst_port_dropdown');
        formData.delete('dst_port_manual');

        if (selectedDate) {
            const utcDate = new Date(selectedDate).toISOString();
            // const formattedDate = format(selectedDate, "MM/dd/yyyy hh:mm a");
            formData.set("timestamp", utcDate);
        }

        formData.delete("attachments");

        selectedFiles.forEach((file) => {
            formData.append("attachments", file)
        })
        console.log(Object.fromEntries(formData.entries()));

        mutate(formData);

    }

    const { mutate, isPending: isAdding } = useMutation({
        mutationFn: (formData) => createTicket(token, formData),
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: ["tickets"] });
            toast.success("Successfully inserted data");
            navigate('/tickets');
        },
        onError: function (error) {
            toast.error(error.message);
        },
    });


    function handleFileChange(e) {
        const files = e.target.files;
        setSelectedFiles(prevFiles => [...prevFiles, ...Array.from(files)])
        // (e) => setSelectedFiles([...(e.target.files)])
    }

    function removeFile(fileToRemove) {
        setSelectedFiles(prevFiles =>
            prevFiles.filter(file => file !== fileToRemove)
        )
    }



    return (

        <>
            <ToastContainer />
            <div className="top_section">
                <div className="pagesNamelink">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">{t("admindashboard.dashboard")}</Link></li>
                            <li className="breadcrumb-item"><Link to="/tickets">{t("tickets.ticket")}</Link></li>
                            <li className="breadcrumb-item text-white">{t("addeditticket.addticket")}</li>
                        </ol>
                    </nav>
                    <h4 className="text-white">{t("addeditticket.addticket")}</h4>
                </div>
            </div>
            <div className="mainSection">
                <form className="row g-3" onSubmit={handleSubmitForm}>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.alerttype")}</label>
                        <select className="form-select" name='alert_type' defaultValue='default'>
                            <option disabled={true} value='default'>{t("addeditticket.selectalerttype")}</option>
                            {/* {alertError && <option >{alertError}</option>} */}
                            {alertData && alertData.data.map((alert) => {
                                return (
                                    <option key={alert.id} value={alert.id} className='capitalize'>{alert.alert_type}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.alert")}</label>
                        <input type="text" className="form-control" placeholder={t("addeditticket.alert")} name='alert' />

                        {/* <select className="form-select" name='alertSubCategory' defaultValue='default'>
                            <option disabled={true} value='default'>--Select Sub Category--</option>
                            <option>...</option>
                        </select> */}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.severitytype")}</label>
                        <select className="form-select" name='severity' defaultValue='default'>
                            <option disabled={true} value='default'>{t("addeditticket.selectseveritytype")}</option>
                            <option value='info'>Information</option>
                            <option value='low'>Low</option>
                            <option value='medium'>Medium</option>
                            <option value='high'>High</option>
                            <option value='critical'>Critical</option>
                        </select>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.customer")}</label>
                        <select className="form-select" name='customer_id' defaultValue='default'>
                            <option value='default' disabled={true}>{t("addeditticket.selectcustomer")}</option>
                            {/* {customerError && <option >{customerError}</option>} */}
                            {customerData && customerData.data.map((customer, index) => {
                                return (
                                    <option key={index} value={customer.customerId}>{customer.company_name}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.domain")}</label>
                        <input type="text" className="form-control" placeholder="yahoo.com" name='domain' />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.assignto")}</label>
                        <select className="form-select" name='assigned_to' defaultValue={userData?.userId}>
                            <option disabled={true} value='default'>--Select Analyst--</option>
                            <option value={userData?.userId} className='capitalize'>{userData?.first_name + ' ' + userData?.last_name}</option>

                        </select>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.sourceip")}</label>
                        <input type="text" className="form-control" placeholder={t("addeditticket.sourceip")} name='source_ip' />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.sourceport")}</label>
                        <select className="form-select" name='src_port_dropdown' defaultValue='default' onChange={(e) => setDropdownSrcPort(e.target.value)}>
                            <option disabled={true} value='default'>{t("addeditticket.selectsourceport")}</option>
                            <option value={53}>DNS(53)</option>
                            <option value={80}>HTTP(80)</option>
                            <option value={443}>HTTPS(443)</option>
                            <option value={21}>FTP(21)</option>
                            <option value={22}>SSH(22)</option>
                            <option value='SRCnone'>None</option>
                        </select>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.sourceportmanual")}</label>
                        <input type="text" className="form-control" placeholder={t("addeditticket.sourceportmanual")} name="src_port_manual" value={manualSrcPort} onChange={(e) => setManualSrcPort(e.target.value)} disabled={dropdownSrcPort && dropdownSrcPort !== 'SRCnone'} />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.destinationip")}</label>
                        <input type="text" className="form-control" placeholder={t("addeditticket.destinationip")} name='destination_ip' />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.destinationport")}</label>
                        <select className="form-select" name='dst_port_dropdown' defaultValue='default' onChange={(e) => setDropdownDstPort(e.target.value)}>
                            <option disabled={true} value='default'>{t("addeditticket.destinationport")}</option>
                            <option value={53}>DNS(53)</option>
                            <option value={80}>HTTP(80)</option>
                            <option value={443}>HTTPS(443)</option>
                            <option value={21}>FTP(21)</option>
                            <option value={22}>SSH(22)</option>
                            <option value='DSTnone'>None</option>
                        </select>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.destinationportmanual")}</label>
                        <input type="text" className="form-control" placeholder={t("addeditticket.destinationportmanual")} name='dst_port_manual' value={manualDstPort} onChange={(e) => setManualDstPort(e.target.value)} disabled={dropdownDstPort && dropdownDstPort !== 'DSTnone'} />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.virustotallink")}</label>
                        <input type="text" className="form-control" placeholder={t("addeditticket.virustotallink")} name='virus_total_link' />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.abuseddblink")}</label>
                        <input type="text" className="form-control" placeholder={t("addeditticket.abuseddblink")} name='abuse_db_link' />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.timestamp")}</label>
                        <div>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                showTimeSelect
                                showMonthDropdown
                                timeIntervals={5}
                                dateFormat="MM/dd/yyyy h:mm a"
                                wrapperClassName='w-full'
                                className="form-control"
                                placeholderText="MM/DD/YYYY HH:MM"
                            />
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                        <label className="form-label">{t("addeditticket.processnamefilename")}</label>
                        <input type="text" className="form-control" placeholder={t("addeditticket.processnamefilename")}name='fileName' />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <label className="form-label">{t("addeditticket.filehash")}</label>
                        <input type="text" className="form-control" placeholder={t("addeditticket.filehash")} name='fileHash' />
                    </div>
                    <div className="com-md-12">
                        <div className="row g-3">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("addeditticket.attachments")}</label>
                                <input className="form-control d-none" type="file" onChange={handleFileChange} id="attachments" name="attachments" multiple />
                                <label htmlFor="attachments" className="uploadFile">
                                    <img src={fileUploadImg} alt="uploadFile" />
                                    <span><span>{t("addeditticket.browse")}</span> {t("addeditticket.file")}</span>
                                    <span>{selectedFiles.length} {t("addeditticket.filesselected")}</span>
                                </label>
                                <div className='flex flex-wrap gap-2 mt-1'>
                                    {selectedFiles.map((file) => {
                                        return (
                                            <span className='bg-slate-400 flex px-1.5 py-1 rounded-lg text-center'>
                                                <span>{file.originalname || file.name}</span>
                                                <span type="button" className="text-red-800 ms-1 rounded-full  p-0" onClick={() => removeFile(file)}><IoMdClose /></span>
                                            </span>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("addeditticket.summary")}</label>
                                <textarea className="form-control" placeholder={t("addeditticket.summary")} name='summary' rows="4"></textarea>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 d-flex justify-content-end">
                        <span className='me-3'>{isAdding && <Loader />}</span>
                        <Link to={'/tickets'} className="cancel_btn me-3 no-underline">{t("addeditticket.cancel")}</Link>
                        <button type='submit' className="secondary_btn" disabled={isAdding} >{t("addeditticket.submit")}</button>
                    </div>
                </form>
            </div>
        </>

    )
}

export default AddTicket