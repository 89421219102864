import nacl from 'tweetnacl';
import naclUtil from 'tweetnacl-util';

// Generate a random key for encryption
const secretKey = nacl.randomBytes(32);

// Function to encrypt a token
export const encryptData = (token) => {
  const nonce = nacl.randomBytes(24);
  const messageUint8 = naclUtil.decodeUTF8(token);
  const box = nacl.secretbox(messageUint8, nonce, secretKey);

  const fullMessage = new Uint8Array(nonce.length + box.length);
  fullMessage.set(nonce);
  fullMessage.set(box, nonce.length);

  const base64FullMessage = naclUtil.encodeBase64(fullMessage);
  return base64FullMessage;
};

// Function to decrypt a token
export const decryptData = (encryptedToken) => {
  const messageWithNonceAsUint8Array = naclUtil.decodeBase64(encryptedToken);
  const nonce = messageWithNonceAsUint8Array.slice(0, 24);
  const message = messageWithNonceAsUint8Array.slice(24);

  const decrypted = nacl.secretbox.open(message, nonce, secretKey);

  if (!decrypted) {
    throw new Error('Decryption failed');
  }

  const base64DecryptedMessage = naclUtil.encodeUTF8(decrypted);
  return base64DecryptedMessage;
};