const English = {
    login: {
        logintitle:"Sign In",
        username: "Username",
        password: "Password",
        forgetpassword:"Forgot Password ?",
        sendotp: "Send OTP"
    },
    otp: {
        enterotp:"Enter OTP",
        submitotp:"Submit",
        resendotp:"Resend OTP"
    },
    dashboardleftmenu: {
        menu1: "Dashboard",
        menu2: "Users",
        menu3: "Tickets",
        menu4: "Customers",
        menu5: "Customer Infrastructure",
        menu6: "Reports"
    },
    languagedropdown:{
        eng:"English (en)",
        span:"Spanish (es)"
    },
    dashboardheaderdropdown:{
        profile:"View Profile",
        password:"Change Password",
        logout:"Logout"
    },
    admindashboard:{
        dashboard:"Dashboard",
        view:"View",
        critical:"Critical",
        high:"High",
        medium:"Medium",
        low:"Low",
    },
    admindashboardgraphs:{
        linecharttitle:"Top Risks Over Time (Current Year)",
        piecharttitle:"Risk Rate Breakdown",
        tabletitle:"Risk Count",
        bargraphtitle:"Ticket Management(Current Year)"
    },
    pagination:{
        showing:"Showing",
        to:"to",
        of:"of",
        entries:"entries"
    },
    addressdropdown:{
        selectcountry:"Select Country",
        selectstate:"Select State",
        selectcity:"Select City",
    },
    stepper:{
        companyinformation:"Company Information",
        locations:"Locations",
        contactinformation:"Contact Infomation",
        servicecontract:"Service/Contract"
    },
    footer:{
        copyright:"Copyright © 2025 DECEPT10®. All rights reserved."
    },
    users:{
        user:"User",
        userslist:"Users List",
        adduser:" Add User",
        clearfilters: "Clear Filters",
        userid:"User ID",
        username:"Username",
        name:"Name",
        email:"Email",
        phoneno:"Phone No",
        role:"Role",
        status:"Status",
        actions:"Actions",
        apply:"Apply",
    },
    addeditusers:{
        adduser:"Add User",
        firstname:"First Name",
        lastname:"Last Name",
        username:"Username",
        email:"Email",
        phoneno:"Phone No.",
        alternatephoneno:"Alternate Phone No.",
        dateofbirth:"Date of Birth",
        role:"Role",
        address:"Address",
        address2:"Address 2",
        country:"Country",
        state:"State",
        city:"City",
        zipcode:"Zip code",
        submit:"Submit",
        status:"Status",
        selectstatus:"Select Status",
        selectrole:"Select Role",
        cancel:"Cancel",
        update:"Update"
    },
    tickets:{
        ticket:"Ticket",
        ticketslist:"Tickets List",
        archivedtickets:"Archived Tickets",
        addticket:"Add Ticket",
        clearfilters: "Clear Filters",
        ticketid:"Ticket ID",
        alert:"Alert",
        customer:"Customer",
        severity:"Severity",
        analysts:"Analysts",
        status:"Status",
        timestamp:"Timestamp",
        destinationip:"Destination IP",
        sourceip:"Source IP",
        destinationport:"Destination Port",
        sourceport:"Source Port",
        createdby:"Created By",
        actions:"Actions",
        apply:"Apply",
        refresh:"Refresh"
    },
    addeditticket:{
        ticket:"Ticket",
        addticket:"Add Ticket",
        editticket:"Edit Ticket",
        alerttype:"Alert Type",
        selectalerttype:"--Select Alert Type--",
        alert:"Alert",
        severitytype:"Severity Type",
        selectseveritytype:"--Select Severity Type--",
        customer:"Customer",
        selectcustomer:"--Select Customer--",
        domain:"Domain",
        assignto:"Assign To",
        sourceip:"Source IP",
        sourceport:"Source Port",
        selectsourceport:"--Select Source Port--",
        sourceportmanual:"Source Port (Manual)",
        destinationip:"Destination IP",
        destinationport:"Destination Port",
        selectdestinationport:"--Select Destination Port--",
        destinationportmanual:"Destination Port (Manual)",
        virustotallink:"VirusTotal Link",
        abuseddblink:"Abuse DB Link",
        timestamp:"Timestamp",
        processnamefilename:"Process Name/ File Name",
        filehash:"File Hash",
        status:"Status",
        attachments:"Attachments",
        browse:"Browse",
        file:"and choose the files (Max 10 MB)",
        filesselected:"files selected",
        customersummary:"Customer Summary (Only for customers)",
        customersummaryplaceholder:"Customer Summary",
        summary:"Summary",
        cancel:"Cancel",
        submit:"Submit"
    },
    viewticket:{
        viewticket:"View Ticket",
        alert:"Alert",
        alerttype:"Alert Type",
        customer:"Customer",
        severity:"Severity",
        assignto:"Assign To",
        sourceip:"Source IP",
        destinationip:"Destination IP",
        timestamp:"Time Stamp",
        status:"Status",
        processnamefilename:"Process Name/ File Name",
        domain:"Domain",
        attachments:"Attachments",
        virustotallink:"VirusTotal Link",
        abuseddblink:"Abuse DB Link",
        filehash:"File Hash",
        summary:"Summary",
        customersummary:"Customer Summary",
        somethingwentwrong:"Something went wrong...!",
    },
    customers:{
        customers:"Customers",
        customerslist:"Customers List",
        addcustomer:"Add Customer",
        clearfilters: "Clear Filters",
        customerid:"Customer Id",
        phone:"Phone",
        email:"Email",
        companyname:"Company Name",
        lastlogindate:"Last Login Date",
        status:"Status",
        actions:"Actions",
        apply:"Apply",
    },
    customerstep1:{
        addcustomer:"Add Customer",
        editcustomer:"Edit Customer",
        companyinformation:"Company Information",
        companyname:"Company Name",
        address:"Address",
        address2:"Address 2",
        status:"Status",
        country:"Country",
        state:"State",
        city:"City",
        zipcode:"Zip code",
        phoneno:"Phone No.",
        alternatephoneno:"Alternate Phone No.",
        email:"Email",
        next:"Next"
    },
    customerstep2:{
        locations:"Locations",
        addlocation:"Add Location",
        editlocation:"Edit Location",
        for:"for",
        id:"ID",
        locationtype:"Location Type",
        address:"Address",
        address2:"Address 2",
        actions:"Actions",
        country:"Country",
        state:"State",
        city:"City",
        zipcode:"Zip code",
        phoneno:"Phone No.",
        alternatephoneno:"Alternate Phone No.",
        email:"Email",
        cancel:"Cancel",
        add:"Add",
        previous:"Previous",
        next:"Next",
        update:"Update"
    },
    customerstep3:{
        addcontactinformation:"Add Contact Information",
        editcontactinformation:"Edit Contact Information",
        for:"for",
        contactinformation:"Contact Information",
        contactname:"Contact Name",
        phoneno:"Phone No.",
        alternatephoneno:"Alternate Phone No.",
        email:"Email",
        add:"Add",
        id:"ID",
        actions:"Actions",
        previous:"Previous",
        next:"Next",
    },
    customerstep4:{
        addservicecontract:"Add Service/Contract",
        editservicecontract:"Edit Service/Contract",
        for:"for",
        servicecontract:"Service/Contract",
        servicename:"Service Name",
        startdate:"Start Date",
        enddate:"End Date",
        status:"Status",
        add:"Add",
        id:"ID",
        actions:"Actions",
        previous:"Previous",
        submit:"Submit",
        selectstatus:"--Select Status--"
    },
    viewcustomer:{
        viewcustomer:"View Customer",
        companyinformation:"Company Information",
        companyname:"Company Name",
        address:"Address",
        address2:"Address 2",
        country:"Country",
        state:"State",
        city:"City",
        zipcode:"Zip code",
        phoneno:"Phone No.",
        alternatephoneno:"Alternate Phone No.",
        email:"Email",
        customerlocations:"Customer Locations",
        locationtype:"Location Type",
        nolocation:"No locations found for this customer.",
        contactinformation:"Contact Information",
        contactname:"Contact Name",
        nocontact:"No Contact Information found for this customer.",
        servicecontract:"Service/Contract",
        startdate:"Start Date",
        enddate:"End Date",
        status:"Status",
        noservice:"No service Information found for this customer.",
    },
    customerinfra:{
        customerinfrastructure:"Customer Infrastructure",
        addcustomerinfrastructure:"Add Customer Infrastructure",
        customerinfralist:"Customer Infrastructure List",
        infrastructurelisting:"Infrastructure Listing",
        addnewinfra:"Add New Infrastructure",
        selectcustomer:"Select Customer",
        addinfrastructure:"Add Infrastructure",
        clearfilters:"Clear Filters",
        serverdevicetype:"Server/Device Type",
        location:"Location",
        machinedevicename:"Machine/Device Name",
        devicetype:"Device Type",
        devicename:"Device Name",
        role:"Role",
        edrtype:"EDR Type",
        edr:"EDR",
        application:"Application",
        websitename:"Application/Website Name",
        vlanallowed:"VLAN allowed/IP allowed/All",
        publicprivate:"Public/Private",
        description:"Description",
        applications:"Applications",
        ipaddress:"IP Address",
        publicipaddress:"Public IP Address",
        internetpublishedservice:"Internet Published Service",
        d10notes:"D10 Notes",
        decept10socinfo:"Decept10 SOC Info",
        actions:"Actions",
        apply:"Apply",
        vlaninfo:"VLAN Information",
        vlanname:"VLAN Name",
        vlanipmask:"VLAN IP/Mask",
        add:"Add",
        next:"Next",
        submit:"Submit",
        update:"Update",
        viewticket:"View Ticket",
        previous:"Previous",
        serversnetworkdevices:"Servers Network Devices",
        noresultfound:"No result found !",
        somethingwentwrong:"Something went wrong. Please try again later !",
    },
    report:{
        report:"Report",
        exportas:"Export as",
        pdf:"PDF",
        customers:"Customers",
        analyst:"Analysts",
        selectdays:"Select Days",
        status:"Status",
        severity:"Severity",
        submit:"Submit",
        clearfilters:"Clear Filters",
        piechart:"Risk Rate Breakdown",
        bargraph:"Ticket Management(Current Year)",
        noresultsfound:"No results found !",
        
    },

};
export default English;