import React, { useEffect, useState } from 'react';
import useUserStore from '../../../../store/UserStore';
import axios from 'axios';
import { BASE_URL } from '../../../../constant/const';
import { toast, ToastContainer } from 'react-toastify';
import City from '../../../commonForm/City';
import State from '../../../commonForm/State';
import Country from '../../../commonForm/Country';
import Loader from '../../../commonForm/Loader';
import { t } from '../../../Language/Translator';

const AddLocation = ({ AddLocationModalOpen, setAddLocationModalOpen, addNewLocation, selectedLocation }) => {
    const customerId = localStorage.getItem('customerId-add');
    const CustomerId = localStorage.getItem('customerId-edit');
    
    const [locationInfo, setLocationInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [locationType, setLocationType] = useState('');
    // const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [alternatePhoneNo, setAlternatePhoneNo] = useState('');
    const [email, setEmail] = useState('');

    const token = useUserStore((state) => state.user);
    useEffect(() => {
        if (selectedLocation) {
            setLocationType(selectedLocation.location_type || '');
            // setCompanyName(selectedLocation.company_name || '');
            setAddress(selectedLocation.address || '');
            setAddress2(selectedLocation.address2 || '');
            setCity(selectedLocation.city || '');
            setState(selectedLocation.state || '');
            setZipCode(selectedLocation.zip || '');
            setCountry(selectedLocation.country || '');
            setPhoneNo(selectedLocation.phone || '');
            setAlternatePhoneNo(selectedLocation.alternate_phone || '');
            setEmail(selectedLocation.email || '');
        } else {
            resetForm();
        }
    }, [selectedLocation]);

    const resetForm = () => {
        setLocationType('');
        // setCompanyName('');
        setAddress('');
        setAddress2('');
        setCity('');
        setState('');
        setZipCode('');
        setCountry('');
        setPhoneNo('');
        setAlternatePhoneNo('');
        setEmail('');
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        // const validations = [
        //     { check: !locationType, message: "Please select the location Type." },
        //     // { check: !companyName, message: "Please enter the Company Name." },
        //     { check: !address, message: "Please enter the Address." },
        //     { check: !country, message: "Please enter the Country." },
        //     { check: !state, message: "Please enter the State." },
        //     { check: !city, message: "Please enter the City." },
        //     { check: !phoneNo || !/^\d{10}$/.test(phoneNo), message: "Please provide a valid Phone Number." },
        //     { check: !email, message: "Please enter the Email." },
        // ];

        // for (const validation of validations) {
        //     if (validation.check) {
        //         toast.warning(validation.message);
        //         setLoading(false);
        //         return;
        //     }
        // }

        const locationData = {
            customer_id: customerId || CustomerId,
            location_type: locationType,
            // company_name: companyName,
            address: address,
            address2: address2,
            state: state,
            city: city,
            zip: zipCode,
            country: country,
            phone: phoneNo,
            alternate_phone: alternatePhoneNo,
            email: email,
        };

        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token,
                },
            };

            let response;
            if (selectedLocation) {
                // Update existing location
                response = await axios.put(`${BASE_URL}customer/updateCustomerLocation/${selectedLocation.id}`, locationData, config);

                // Create the updated location object
                const updatedLocation = {
                    id: selectedLocation.id, // Keep the same ID
                    ...response.data.data, // Spread the updated location data from the response
                };

                // Update the location in the parent
                addNewLocation(updatedLocation); // Pass the updated location object back to the parent
            } else {
                // Add new location
                response = await axios.post(`${BASE_URL}customer/addCustomerLocation`, locationData, config);
                const newLocation = {
                    id: response.data.data.locationId,
                    ...response.data.data,
                };

                addNewLocation(newLocation); // Pass the new location object back to the parent
            }

            toast.success(response.data.message);
            setAddLocationModalOpen(false);
            resetForm();
        } catch (error) {
            toast.error(error.response ? error.response.data.message : "Failed to add/edit location");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="viewModel modal" style={{ display: AddLocationModalOpen ? 'block' : 'none' }}>
            <ToastContainer/>
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{selectedLocation ? "Edit Location" : "Add Location"}</h5>
                        <button type="button" className="btn-close" onClick={() => setAddLocationModalOpen(false)}></button>
                    </div>

                    {/* <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Location Type</label>
                                <select className="form-select" value={locationType} onChange={(e) => setLocationType(e.target.value)}>
                                    <option value="">--Select Location Type--</option>
                                    <option value="Head quarter">Head quarter</option>
                                    <option value="Head quarter-2">Head quarter-2</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Company Name</label>
                                <input type="text" className="form-control" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Address</label>
                                <input type="text" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Address 2</label>
                                <input type="text" className="form-control" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Country</label>
                                <Country onCountrySelect={setCountry} selectedvaluecountry={country} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">State</label>
                                <State countryId={country} onStateSelect={setState} selectedvaluestate={state} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">City</label>
                                <City stateId={state} onCitySelect={setCity} selectedvaluecity={city} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Zip Code</label>
                                <input type="text" className="form-control" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Phone No.</label>
                                <input type="text" className="form-control" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Alternate Phone No.</label>
                                <input type="text" className="form-control" value={alternatePhoneNo} onChange={(e) => setAlternatePhoneNo(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="d-flex justify-content-end gap-2">
                                <button type="button" className="btn btn-secondary" onClick={() => setAddLocationModalOpen(false)}>Cancel</button>
                                <button type="submit" className="btn btn-primary">{selectedLocation ? "Update" : "Add"}</button>
                            </div>
                        </form>
                    </div> */}

                    <div className="modal-body">
                        {loading && <Loader />}
                        <form className="row g-3" onSubmit={handleSubmit}>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("customerstep2.locationtype")} </label>
                                <input type="text" className="form-control" placeholder={t("customerstep2.locationtype")} name="locationType" value={locationType} onChange={(e) => setLocationType(e.target.value)} />
                            </div>
                            {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">Company Name </label>
                                <input type="text" className="form-control" placeholder="Company Name " name="locationType" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                            </div> */}
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("customerstep2.address")} </label>
                                <input type="text" className="form-control" placeholder={t("customerstep2.address")} name="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("customerstep2.address2")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep2.address2")} name="address2" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("customerstep2.country")}</label>
                                <Country onCountrySelect={setCountry} selectedvaluecountry={country} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep2.state")}</label>

                                <State countryId={country} onStateSelect={setState} selectedvaluestate={state} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep2.city")}</label>
                                <City stateId={state} onCitySelect={setCity} selectedvaluecity={city} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep2.zipcode")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep2.zipcode")} name="zipCode" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep2.phoneno")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep2.phoneno")} name="phoneNo" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep2.alternatephoneno")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep2.alternatephoneno")} name="alternatePhoneNo" value={alternatePhoneNo} onChange={(e) => setAlternatePhoneNo(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep2.email")}</label>
                                <input type="email" className="form-control" placeholder={t("customerstep2.email")} name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end justify-content-end gap-3 mt-20">
                                <button type="button" className={`cancel_btn`} onClick={() => setAddLocationModalOpen(false)}>
                                    {t("customerstep2.cancel")}
                                </button>
                                <button type="submit" className={`secondary_btn`}>
                                    {selectedLocation ? t("customerstep2.update") : t("customerstep2.add")}
                                </button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddLocation;




