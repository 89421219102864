import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../constant/const.js';
import AuthStore from '../../../src/store/AuthStore.js'
import UserStore from '../../../src/store/UserStore.js'
import { useNavigate } from 'react-router-dom';
import { encryptData } from "../utility/EncryptDecrypt.js";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../commonForm/Loader.js';
import { t } from '../Language/Translator.js';


const VerifyOtp = ({ isOpen, setIsOtpOpen }) => {
    const navigate = useNavigate();

    const [otp, setOtp] = useState(Array(6).fill(''));

    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(0);

    const user = AuthStore((state) => state.user);
    const email = user.data.email;

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1)
            }, 1000)
        }
        return()=>clearInterval(interval);
    },[timer]);

    //const setUserData = UserStore((state) => state.setUser);
    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[a-zA-Z0-9]*$/.test(value)) { // Allow alphanumeric input
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move to the next input field if a character is entered
            if (value && index < 5) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };
    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData('text').slice(0, 6);
        const newOtp = pasteData.split('');
        setOtp(newOtp);
        e.preventDefault();
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };
    const getOtpString = () => otp.join('');
    async function verify_otp() {
        const otp = getOtpString();
        try {
            const response = await fetch(BASE_URL + 'auth/verify-otp', {

                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, otp }),
            });
            const result = await response.json();
            if (result.status === 'success') {
                toast.success(result.message);
                // const datastore = encryptData(result.token);
                UserStore.getState().setUser(result.token)
                UserStore.getState().setUserInfo({ first_name: result.data.first_name, last_name: result.data.last_name, email: result.data.email, user_type: result.data.user_type })
                navigate('/Dashboard');
            } else {
                toast.error(result.message);
                // setError(result.message);
            }
        } catch (error) {
            toast.error(error.message);
            // setError(error.message);
        } finally {
            setLoading(false);
        }

    }

    async function resend_otp() {
        try {
            setLoading(true);
            const response = await fetch(BASE_URL + 'auth/send-otp', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            if (data.status === 'success') {
                toast.success("OTP sent successfully");
                setTimer(60);
                // //console.log('Reset otp send');

            } else {
                // setError(data.message);
                toast.error(data.message);
            }
        } catch (error) {
            toast.error(error.message);
            // setError(error.message);
        } finally {
            setLoading(false);
        }

    }

    function handleCloseOtpModal() {
        setIsOtpOpen(false);
        setOtp(Array(6).fill(''));
    }


    return (
        isOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                {/* <ToastContainer /> */}
                <div className="bg-white p-6 rounded-lg shadow-lg relative">
                    <button
                        onClick={()=>handleCloseOtpModal()}
                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    >
                        ×
                    </button>
                    <h2 className="text-xl font-semibold mb-4">{t("otp.enterotp")}</h2>
                    <div onPaste={handlePaste}>
                        {otp && otp?.map((string, index) => (
                            <input
                                key={index}
                                id={`otp-input-${index}`}
                                type="text"
                                maxLength="1"
                                value={string}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                className="w-12 h-12 text-center border border-gray-300 rounded"
                            />
                        ))}
                    </div>

                    <button
                        onClick={verify_otp}
                        className="login_btn mt-3 mb-xxl-3 mb-xl-3 mb-lg-1 mb-md-2 mb-3 text-white"
                        type="submit" disabled={loading}
                    >
                        {t("otp.submitotp")}
                    </button>
                    <button
                        className="forgot_a w-100"
                        onClick={resend_otp}
                        type="Resend"
                        disabled={loading || timer>0}
                    >
                        <i className="fa fa-repeat">&nbsp;</i> {timer>0 ? `${t("otp.resendotp")} in ${timer}s`:t("otp.resendotp")}
                    </button>
                    {loading ? <Loader /> : ''}
                </div>

            </div>
        )
    );

};

export default VerifyOtp;
