import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../commonForm/Loader';
import ViewIcon from "../../assets/images/Icons/view.svg"
import EditIcon from "../../assets/images/Icons/edit.svg"
import DeleteIcon from "../../assets/images/Icons/delete.svg"
import TrashIcon from "../../assets/images/Icons/deleteIcon.svg"
import { IoIosChatbubbles, IoIosRefresh } from 'react-icons/io';
import ChatModal from "./ChatModule/ChatModal";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteTicket, filterTickets, getTicket, getTickets, updateChatCount } from '../../services/ticket_api';
import { Link, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import useUserStore from '../../../src/store/UserStore.js';
import Swal from 'sweetalert2';
import Pagination from '../commonForm/Pagination.js';
import Call from '../Twilio/Call.js';
import { t } from '../Language/Translator.js';
// import io from 'socket.io-client';
import DatePicker from 'react-datepicker';
import { attachmentURL } from '../../constant/const.js';

// const socket = io("http://192.241.158.206:5677");

export const TicketList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [viewTicketID, setViewTicketID] = useState(null);
    const [filteredTickets, setFilteredTickets] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [filteredFormData, setFilteredFormData] = useState(null);
    const [chatId, setChatId] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    // const [conversationCount, setconversationCount] = useState(2);

    const statusForFilter = searchParams.get('status');
    const severityForFilter = searchParams.get('severity');

    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    const userInfo = useUserStore((state) => state.userInfo);

    const isNotCustomer = !['customer', 'secondary_customer'].includes(userInfo?.user_type);

    const queryClient = useQueryClient();
    // const { data: customerData, error: customerError } = useQuery({ queryKey: ['ticketList(customer)'], queryFn: () => getCustomers(token), enabled: isNotCustomer })
    // const { data: analystData, error: analystError } = useQuery({ queryKey: ['ticketList(analyst)'], queryFn: () => getAnalysts(token), enabled: isNotCustomer })

    // useEffect(() => {
    //     socket.io.on("error", (error) => {
    //         console.log(error);

    //     });
    //     socket.on('ticketsUpdated', () => {
    //         console.log('newwwwwwww');
    //         queryClient.invalidateQueries({ queryKey: ['tickets', currentPage] });

    //         return () => {
    //             socket.off('ticket-update');
    //         }
    //     })
    // }, [currentPage, queryClient])

    useEffect(() => {
        if (statusForFilter || severityForFilter) {
            const FILTER_FORM = document.querySelector('#filterForm');
            const formData = new FormData(FILTER_FORM);
            if (statusForFilter) {
                formData.append('status', statusForFilter);
            } else if (severityForFilter) {
                formData.append('severity', severityForFilter);
            }
            setFilteredFormData(Object.fromEntries(formData.entries()));
            mutateFilter(formData);
        }
    }, [statusForFilter, severityForFilter])

    const toggleModal = (chatId) => {
        setChatId(chatId);
        setIsOpen(!isOpen);
        mutateChatCount(chatId);
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        if (filteredFormData) {
            const newFormData = new FormData();
            Object.entries(filteredFormData).forEach(([key, value]) => {
                newFormData.append(key, value);
            });
            newFormData.set('page', pageNumber);
            mutateFilter(newFormData);
        }
        else {
            queryClient.invalidateQueries({ queryKey: ['tickets', pageNumber] });
        }
    };

    const { data: ticketData, isLoading, error, refetch, isFetching, isRefetching } = useQuery({ queryKey: ['tickets', currentPage], queryFn: () => !filteredFormData ? getTickets(token, currentPage) : null, enabled: !filteredFormData, refetchInterval: 1000, refetchIntervalInBackground: true })


    async function deleteTicketalert(ticketID) {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this ticket!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#19C489',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                await deleteTicket(token, ticketID);
                await queryClient.invalidateQueries({ queryKey: ['tickets', currentPage] });

                Swal.fire('Deleted!', 'Your ticket has been deleted.', 'success');
            } catch (error) {
                Swal.fire('Error!', 'Failed to delete ticket.', 'error');
            }
        }
    }

    function handleFiltering(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        if (selectedDate) {
            const date = new Date(selectedDate);
            const utcDateOnly = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())).toISOString().split("T")[0];
            formData.set("timestamp", utcDateOnly);
        }
        formData.append('page', 1);
        setFilteredFormData(Object.fromEntries(formData.entries()));
        //console.log(filteredFormData);
        mutateFilter(formData);
    }

    function handleClearFilter(e) {
        e.preventDefault();
        document.getElementById("filterForm").reset();
        setFilteredTickets(null);
        setFilteredFormData(null);
        setCurrentPage(1);
        queryClient.invalidateQueries({ queryKey: ['tickets'] });

    }

    const { mutate: mutateFilter, isPending: isFiltering } = useMutation({
        mutationFn: (formData) => filterTickets(token, formData),
        onSuccess: function (data) {
            setFilteredTickets(data);
        },
        onError: function (error) {
            toast.error(error.message);
        },
    });

    const { mutate: mutateChatCount, isPending } = useMutation({
        mutationFn: (ID) => updateChatCount(token, ID),
        onSuccess: function (data) {
            console.log("chat set to 0");
        },
        onError: function (error) {
            toast.error(error.message);
        },
    });

    const ticketsToDisplay = filteredTickets?.data?.length >= 0 ? filteredTickets : ticketData || { data: [] };
    // console.log(ticketsToDisplay);

    return (
        <>
            <ToastContainer />
            <div className="top_section flex-wrap">
                <div className="pagesNamelink">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">{t("admindashboard.dashboard")}</Link></li>
                            <li className="breadcrumb-item text-white">{t("tickets.ticket")}</li>

                        </ol>
                    </nav>
                    <h4 className="text-white">{t("tickets.ticketslist")}</h4>
                </div>
                {
                    isNotCustomer &&
                    <div className='flex gap-4'>
                        <Link to={'/tickets/archivedTickets'} className="delete_btn no-underline"><i className="fa-solid fa-trash me-2"></i>{t("tickets.archivedtickets")}</Link>
                        <Link to={'/tickets/addTicket'} className="main_btn no-underline"><i className="fa-solid fa-plus"></i> {t("tickets.addticket")}</Link>
                    </div>
                }
            </div>
            <div className="mainSection">
                <div className='flex gap-3 align-items-end justify-end mb-3'>
                    <div className="">
                        {/* <button className="add_btn ms-auto" onClick={() => refetch()} disabled={isLoading || isFetching || isRefetching}>{t("tickets.refresh")} <IoIosRefresh className={`ml-2 ${isLoading || isFetching || isRefetching ? "animate-spin" : ""}`} fontSize={'15px'} /> </button> */}
                        <button className="add_btn ms-auto" onClick={() => refetch()} disabled={isLoading || isFetching || isRefetching}>{t("tickets.refresh")} <IoIosRefresh className={`ml-2`} fontSize={'15px'} /> </button>
                    </div>
                    <div className="">
                        <button className="cancel_btn mx-auto" onClick={handleClearFilter}>{t("tickets.clearfilters")}</button>
                    </div>
                </div>

                {!error &&
                    <>
                        <div className="mainTable table-responsive">

                            {isLoading && <Loader />}
                            <form id='filterForm' onSubmit={handleFiltering}>

                                <table id="example" className="table table-striped table-dark">
                                    {!isLoading && !error && ticketsToDisplay &&
                                        <>
                                            <thead>
                                                <tr>
                                                    <th>{t("tickets.ticketid")}</th>
                                                    <th>{t("tickets.alert")}</th>
                                                    {isNotCustomer &&
                                                        <th>{t("tickets.customer")}</th>
                                                    }
                                                    <th>{t("tickets.severity")}</th>
                                                    {
                                                        isNotCustomer &&
                                                        <th>{t("tickets.analysts")}</th>
                                                    }
                                                    <th>{t("tickets.status")}</th>
                                                    <th>{t("tickets.timestamp")}</th>
                                                    <th>{t("tickets.destinationip")}</th>
                                                    <th>{t("tickets.destinationport")}</th>
                                                    <th>{t("tickets.sourceip")}</th>
                                                    <th>{t("tickets.sourceport")}</th>
                                                    <th>{t("tickets.createdby")}</th>
                                                    <th className='w-28'>{t("tickets.actions")}</th>
                                                </tr>

                                            </thead>
                                            <tbody>

                                                <tr>

                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("tickets.ticketid")} name='ticket_id' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("tickets.alert")} name='alert' />
                                                    </th>
                                                    {
                                                        isNotCustomer &&
                                                        <th>
                                                            <input type="text" className="form-control" placeholder={t("tickets.customer")} name='customer_name' />
                                                        </th>
                                                    }
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("tickets.severity")} name='severity' />
                                                    </th>
                                                    {
                                                        isNotCustomer &&
                                                        <th>
                                                            <input type="text" className="form-control" placeholder={t("tickets.analysts")} name='analyst_name' />
                                                        </th>
                                                    }
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("tickets.status")} name='status' />
                                                    </th>
                                                    <th>
                                                        {/* <input type="text" className="form-control" placeholder={t("tickets.timestamp")} name='timestamp' /> */}
                                                        <DatePicker
                                                            selected={selectedDate}
                                                            onChange={(date) => setSelectedDate(date)}
                                                            showMonthDropdown
                                                            dateFormat="MM/dd/yyyy"
                                                            wrapperClassName='w-full'
                                                            className="form-control"
                                                            placeholderText={t("tickets.timestamp")}
                                                        />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("tickets.destinationip")} name='destination_ip' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("tickets.destinationport")} name='dst_port' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("tickets.sourceip")} name='source_ip' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("tickets.sourceport")} name='src_port' />
                                                    </th>
                                                    <th>
                                                        <input type="text" className="form-control" placeholder={t("tickets.createdby")} name='created_by' />
                                                    </th>
                                                    <th>
                                                        <button type='submit' className="secondary_btn" disabled={isFiltering ? <Loader /> : ''}>{t("tickets.apply")}</button>
                                                    </th>

                                                </tr>
                                                {ticketsToDisplay.data.length > 0 && ticketsToDisplay.data.map((ticket, index) => {
                                                    return (


                                                        <tr key={index}>
                                                            <td>{ticket.ticketId}</td>
                                                            <td>{ticket.alert}</td>
                                                            {
                                                                isNotCustomer &&
                                                                <td>{ticket.customer_first_name + " " + ticket.customer_last_name}</td>
                                                            }
                                                            <td>
                                                                <div className={`sevBtn Sev${ticket.severity} capitalize`}>{ticket.severity}</div>
                                                            </td>
                                                            {isNotCustomer &&
                                                                <td>{ticket.analyst_first_name + " " + ticket.analyst_last_name}</td>
                                                            }
                                                            <td className={`status${(ticket.status).charAt(0).toUpperCase() + ticket.status.slice(1)} capitalize`}>{ticket.status}</td>
                                                            <td>{format(new Date(ticket.timestamp), "MM/dd/yyyy hh:mm a")}</td>
                                                            <td>{ticket.destination_ip}</td>
                                                            <td>{ticket.dst_port}</td>
                                                            <td>{ticket.source_ip}</td>
                                                            <td>{ticket.src_port}</td>
                                                            <td>{ticket.created_by}</td>
                                                            <td>
                                                                <div className="actionBtn flex justify-between gap-2 items-center w-max">
                                                                    <button type='button' className="view" data-bs-toggle="modal" data-bs-target="#view" onClick={() => setViewTicketID(ticket.ticket_uuid)}>
                                                                        <img src={ViewIcon} alt="View" />
                                                                    </button>
                                                                    <Link to={`/tickets/editTicket/${ticket.ticket_uuid}`} className="Edit m-0">
                                                                        <img src={EditIcon} alt="Edit" />
                                                                    </Link>
                                                                    {isNotCustomer && (
                                                                        <button type='button' className="Delete" onClick={() => deleteTicketalert(ticket.ticket_uuid)}>
                                                                            <img src={DeleteIcon} alt="Delete" />
                                                                        </button>
                                                                    )}
                                                                    <div className="relative" onClick={() => toggleModal(ticket.ticketId)} id={ticket.ticketId}>
                                                                        <IoIosChatbubbles className="text-blue-500 cursor-pointer text-2xl" />
                                                                        {ticket.notificationCount > 0 && (
                                                                            <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs animate-pulse cursor-pointer">
                                                                                {ticket.notificationCount}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <ChatModal isOpen={isOpen} onClick={() => toggleModal(null)} chatId={chatId} />
                                                                    <Call analist={ticket.analyst_phone} customer={ticket.customer_phone} ticketid={ticket.ticketId} />

                                                                </div>
                                                            </td>
                                                        </tr>

                                                    );
                                                })}
                                                {ticketsToDisplay.data.length == 0 && <p className='text-center text-white mt-4'>No matching results found !</p>}

                                            </tbody>
                                        </>
                                    }
                                    {/* {!isLoading && ticketsToDisplay.data.length == 0 ? <p className='text-center text-white mt-4'>No matching results found !</p>:''} */}
                                </table>
                            </form>


                        </div>
                        {
                            ticketsToDisplay.data.length > 0 &&
                            <div>
                                <Pagination
                                    itemsPerPage={10}
                                    totalItems={ticketsToDisplay?.pagination?.totalItems}
                                    paginate={paginate}
                                    currPage={currentPage}
                                />
                            </div>
                        }
                    </>
                }
                {error &&
                    <p className='text-center text-white mt-4'>Something went wrong. Please try again later !</p>
                }
            </div>

            {/* <!--View Modal --> */}
            <ViewModal token={token} id={viewTicketID} userInfo={userInfo} />

        </>
    )
}

const ViewModal = ({ token, id, userInfo }) => {

    const { data: ticket, isLoading, error } = useQuery({ queryKey: ['ticket', id], queryFn: () => getTicket(token, id), enabled: id ? true : false })

    return (
        <div className="modal fade viewModel" id="view" tabIndex="-1" aria-labelledby="viewLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content text-wrap">
                    <div className="modal-header">
                        <h5 className="modal-title" id="viewLabel">{t("viewticket.viewticket")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="viewTicket row g-3">
                            {isLoading && <Loader />}

                            {ticket &&
                                <>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.alert")}</h6>
                                        <p>{ticket.data?.alert}</p>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.alerttype")}</h6>
                                        <p>{ticket.data?.alert_type}</p>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.severity")}</h6>
                                        <p className="statusClosed">{ticket.data?.severity}</p>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.customer")}</h6>
                                        <p>{ticket.data.customer_first_name + ' ' + ticket.data?.customer_last_name}</p>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.assignto")}</h6>
                                        <p>{ticket.data.analyst_first_name + ' ' + ticket.data?.analyst_last_name}</p>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.sourceip")}</h6>
                                        <p>{ticket.data.source_ip} | {ticket.data?.src_port}</p>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.destinationip")}</h6>
                                        <p>{ticket.data.destination_ip} | {ticket.data?.dst_port}</p>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.timestamp")} </h6>
                                        <p>{format(ticket.data?.timestamp, "MM/dd/yyyy hh:mm a")}</p>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.status")}</h6>
                                        <p className="statusProgress">{ticket.data?.status}</p>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.processnamefilename")}</h6>
                                        <p className="statusProgress">{ticket.data?.fileName}</p>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.domain")}</h6>
                                        <p className="statusProgress">{ticket.data?.domain}</p>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <h6>{t("viewticket.attachments")}</h6>
                                        <div className='flex flex-wrap items-center gap-x-4'>
                                            {ticket.data.attachments.length > 0 ? (
                                                ticket.data.attachments.map((file, i) => (
                                                    <span className='UploadedFile m-0' key={i}>
                                                        {userInfo?.user_type === 'admin' || userInfo?.user_type === 'customer' || userInfo?.user_type === 'secondary_customer' ? (
                                                            <span className="text-gray-500">{file?.originalname}</span> // Non-clickable for these users
                                                        ) : (
                                                            <a href={`${attachmentURL}/uploads/${file.filename}`} target='_blank' className="text-blue-600 underline">
                                                                {file?.originalname}
                                                            </a>
                                                        )}
                                                    </span>
                                                ))
                                            ) : (
                                                <p>No attachments</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <h6>{t("viewticket.virustotallink")}</h6>
                                        <a href={ticket.data.virus_total_link} target='_blank' className="linkFile">
                                            {ticket.data?.virus_total_link}
                                        </a>
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <h6>{t("viewticket.abuseddblink")}</h6>
                                        <a href={ticket.data.abuse_db_link} target='_blank' className="linkFile">
                                            {ticket.data?.abuse_db_link}
                                        </a>
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <h6>{t("viewticket.filehash")}</h6>
                                        <p>
                                            {ticket.data?.fileHash}
                                        </p>
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <h6>Created By</h6>
                                        <p>
                                            {ticket.data?.created_by}
                                        </p>
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <h6>Summary</h6>
                                        <p>{ticket.data?.summary}</p>
                                    </div>
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <h6>{t("viewticket.customersummary")}</h6>
                                        <p>{ticket.data?.customer_summary}</p>
                                    </div>
                                </>
                            }
                            {error && <p>{t("viewticket.somethingwentwrong")}</p>}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TicketList;
