import { jwtDecode } from "jwt-decode";
import { BASE_URL } from "../constant/const";

export async function getCustomerInfraListings(token, currPage) {
    try {
        const response = await fetch(`${BASE_URL}customer/get-customerinfrastructure?page=${currPage}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}-${response.statusText}`);
        }
        const data = await response.json();

        return data;
    }
    catch (error) {
        throw new Error(error);
    }
}

export async function getOneCustomerInfraListing(token, c_id) {
    const userData = jwtDecode(token)?.user_data;
    try {
        const response = await fetch(`${BASE_URL}customer/get-customerinfrastructure`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}-${response.statusText}`);
        }
        const data = await response.json();
        if (c_id) {

            const filteredDataForEdit = {
                vlaninfo: data.totalResults.vlaninfo.filter(item => item.c_id == c_id),
                customerinfo: data.totalResults.customerinfo.filter(item => item.c_id == c_id),
                customerapp: data.totalResults.customerapp.filter(item => item.c_id == c_id)
            };
            return filteredDataForEdit;
        }
        else {
            return {};
        }

    }
    catch (error) {
        throw new Error(error);
    }
}

export async function filterInfrastructure(token, formData) {

    try {
        const requestResponse = {};
        for (const [key, value] of formData.entries()) {
            if (value.trim() !== "") {
                requestResponse[key] = value;
            }
        }
        const response = await fetch(`${BASE_URL}customer/search-customerinfo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(requestResponse)

        });
        const data = await response.json();

        if (!response.ok || data.status == 'error') {
            throw new Error(data.message || 'Something went wrong');
        }
        return data;
    }
    catch (error) {
        throw new Error(error);
    }
}

export async function deletecustomerInfra(token, id) {
    try {
        if (!id) {
            throw new Error('This record does not exist!');
        }
        const response = await fetch(`${BASE_URL}customer/delete-customerinfo/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}-${response.statusText}`);
        }
        const data = await response.json();
        return data;
    }
    catch (error) {
        throw new Error(error.message);
    }
}


export async function saveVLANinfo(token, dataObj) {
    try {
        const response = await fetch(`${BASE_URL}customer/save-vlaninfo`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': token
            },
            body: JSON.stringify(dataObj),
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message);
        }
        return data;
    }
    catch (error) {
        throw new Error(error.message);

    }
}

export async function deleteVLANinfo(token, id) {
    try {
        if (!id) {
            throw new Error('This record does not exist!');
        }
        const response = await fetch(`${BASE_URL}customer/delete-vlan/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}-${response.statusText}`);
        }
        const data = await response.json();
        return data;
    }
    catch (error) {
        throw new Error(error.message);
    }
}

export async function saveServerNetDevices(token, dataObj) {
    try {
        const response = await fetch(`${BASE_URL}customer/save-infrastructure`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': token
            },
            body: JSON.stringify(dataObj),
        });
        const data = await response.json();
        await new Promise((resolve) => setTimeout(resolve, 400))
        if (!response.ok) {
            throw new Error(data.message);
        }
        return data;
    }
    catch (error) {
        throw new Error(error.message);

    }
}

export async function deleteDeviceInfo(token, id) {
    try {
        if (!id) {
            throw new Error('This record does not exist!');
        }
        const response = await fetch(`${BASE_URL}customer/delete-devices/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        console.log(response);
       
        const data = await response.json();
        if (!response.ok || data.status=='error') {
            throw new Error(`HTTP Error: ${data.message}`);
        }
        console.log(data);
        
        return data;
    }
    catch (error) {
        throw new Error(error.message);
    }
}

export async function saveApplicationInfo(token, dataObj) {
    try {
        const response = await fetch(`${BASE_URL}customer/save-customerapp`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': token
            },
            body: JSON.stringify(dataObj),
        });
        const data = await response.json();
        await new Promise((resolve) => setTimeout(resolve, 400))
        if (!response.ok) {
            throw new Error(data.message);
        }
        return data;
    }
    catch (error) {
        throw new Error(error.message);

    }
}

export async function deleteAppInfo(token, id) {
    try {
        if (!id) {
            throw new Error('This record does not exist!');
        }
        const response = await fetch(`${BASE_URL}customer/delete-capps/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}-${response.statusText}`);
        }
        const data = await response.json();
        return data;
    }
    catch (error) {
        throw new Error(error.message);
    }
}
