import { BASE_URL } from "../constant/const";

export async function getReports(token) {
    try {
        const response = await fetch(`${BASE_URL}allreports`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': token
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}-${response.statusText}`);
        }
        const data = await response.json();
        if (data.status == 'error') {
            throw new Error(data.message);
        }
        return data;
    }
    catch (error) {
        throw new Error(error);
    }
}

export async function filterReports(token, formData) {
    try {
        const requestResponse = {};
        for (const [key, value] of formData.entries()) {
            if (value.trim() !== "") {
                requestResponse[key] = value;
            }
        }
        const response = await fetch(`${BASE_URL}allreports/filtertickets-info`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': token
            },
            body: JSON.stringify(requestResponse),
        });
        const data = await response.json();
        await new Promise((resolve) => setTimeout(resolve, 400))
        if (!response.ok) {
            throw new Error(data.message);
        }
        return data;
    }
    catch (error) {
        throw new Error(error.message);

    }
}