import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Device } from "@twilio/voice-sdk";
import { BASE_URL } from '../../constant/const.js';

const Call = (data) => {
  // console.log('aaaaaaaaaaaaaa',data);
  
  const [cphoneNumber, setCPhoneNumber] = useState(data.customer);
  const [aphoneNumber, setAPhoneNumber] = useState(data.analist);
  const [twilioDevice, setTwilioDevice] = useState(null);
  const [connection, setConnection] = useState(null);
  const [isCalling, setIsCalling] = useState(null);

  useEffect(() => {
    getToken();
  }, []);

  // 1. Get Twilio Token for WebRTC
  const getToken = async () => {
    try {
      const response = await axios.post(`${BASE_URL}call/token`, {
        identity: "user1",
      });
      const device = new Device(response.data.token, {
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true,
      });
      device.on("ready", () => console.log("Twilio Device is ready"));
      device.on("error", (error) => console.error("Twilio Device Error:", error));
      device.on("connectionEstablished", (conn) => {
        console.log("Connection established");
        setConnection(true);
      });
      device.on("connectionDisconnected", () => {
        console.log("Call ended");
        setConnection(false);
      });
      setTwilioDevice(device);
    } catch (error) {
      console.error("Error getting Twilio token:", error);
    }
  };

  // 2. Make an Outgoing Call (WebRTC to Phone)
  const makeCall = async () => {
    console.log(aphoneNumber,'111111111111111111111111_makeCall',cphoneNumber);

    const response = await axios.post(`${BASE_URL}call/call`, {
      from: "+16504050910", // Your Twilio number
      to: cphoneNumber, // Number to call
      vto:aphoneNumber,
    });
    if (response.data.success) {
      setIsCalling(true);
      console.log("Call initiated successfully!");
    } else {
      setIsCalling(false);
      alert("Call failed!");
    }
  }

  // 3. Hang Up Call
  const hangUp = () => {
    setIsCalling(false);
    if (connection) {
      connection.disconnect();
      setConnection(null);
    }
  };

  return (
    <div>
      {isCalling ? (

        <i className='fas fa-phone-slash' onClick={hangUp}></i>
      ) : (
        <i className="fa fa-phone cursor-pointer" onClick={makeCall}></i>
      )}
      <br />
    </div>
  );
};

export default Call;

