var sidebarToggle = document.getElementById("sidebar_toggle-btn");
var sidebar = document.getElementById("sidebar");
var main = document.getElementById("main");
var sidbar_cta = document.getElementById("sidbar_cta");
var closeSideElements = document.querySelectorAll('.closeSide');

function toggleSidebar() {
    sidebar.classList.toggle("sidebar_collpased");
    main.classList.toggle("main_collapsed");
    sidbar_cta.classList.toggle("d-none");
}




