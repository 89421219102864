import React, { useRef, useState } from 'react';
import { getAnalysts } from '../../services/user_api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useUserStore from '../../store/UserStore';
import Pdf from "../../assets/images/Icons/pdf.png";
// import Csv from "../../assets/images/Icons/csv.png";
import ReportTable from './ReportTable';
import { getCustomers } from '../../services/customer_api';
import ReportsCharts from './ReportsCharts';
import { filterReports, getReports } from '../../services/reports_api';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import jsPDF from 'jspdf';
import { autoTable } from 'jspdf-autotable';
import logo from "../../assets/images/logo.png";
import html2canvas from 'html2canvas';
import { generateDetailedReport, generateMonthlyTable } from '../utility/reportsPDFutils';
import { Link } from 'react-router-dom';
import { t } from '../Language/Translator';


const ReportContainer = () => {
    const [filteredReport, setFilteredReport] = useState(null);
    const PieChartRef = useRef(null);
    const BarChartRef = useRef(null);
    const filterDaysRef = useRef(null);
    const filterCustomerRef = useRef(null);
    const filterSeverityRef = useRef(null);
    const filterStatusRef = useRef(null);


    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    const userInfo = useUserStore((state) => state.userInfo);
    const userData = jwtDecode(token)?.user_data;

    const queryClient = useQueryClient();

    const isNotCustomer = !['customer', 'secondary_customer'].includes(userInfo?.user_type);
    

    const { data: analystData, error: analystError } = useQuery({ queryKey: ['report(analysts)'], queryFn: () => getAnalysts(token), enabled: isNotCustomer });
    const { data: customerData, error: customerError } = useQuery({ queryKey: ['report(customers)'], queryFn: () => getCustomers(token), enabled: isNotCustomer })
    const { data: reportsData, isLoading, error } = useQuery({ queryKey: ['report(tickets)'], queryFn: () => getReports(token) })


    const handleExportPDF = async () => {
        if (!reportsData) {
            return;
        }
        const pdf = new jsPDF("p", "mm", "a4");

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 10;
        const leftMargin = 10
        const normalTextSize = 12;
        const greyLineHeight = 10
        const contentWidth = pageWidth - 2 * margin;
        const reportDate = new Date().toLocaleDateString('en-US');

        // **Page 1 - Cover Page & Pie Chart**
        pdf.setFillColor(20, 20, 20); // Dark background color (almost black)
        pdf.rect(0, 0, pageWidth, 18, "F"); // Full-width rectangle
        pdf.addImage(logo, "PNG", (contentWidth / 4) + 10, 0, contentWidth / 2, 18);


        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(22);
        pdf.text(`Summary Report`, pageWidth / 2, 30, { align: "center" });

        pdf.setDrawColor('#005198')
        pdf.setLineWidth(2);
        pdf.line(leftMargin, 37, 200, 37);

        // pdf.setFont("helvetica", "normal");
        // pdf.setFontSize(20);
        // pdf.setTextColor('#005198');
        // pdf.text("Ticket Statistics", pageWidth / 2, 55, { align: "center" });


        const data = [
            ["Report Duration:",
                `${filterDaysRef.current?.value == 'default' ? 'ALL' : ('Last ' + filterDaysRef.current?.value + ' Days')?.toUpperCase()}`,
                "Ticket Severity:",
                `${filterSeverityRef.current?.value == 'default' ? 'ALL' : (filterSeverityRef.current?.value)?.toUpperCase()}`],
            ["Customer Name:",
                `${filterCustomerRef.current?.value == 'default' ? 'ALL' : (filterCustomerRef.current?.options[filterCustomerRef.current.selectedIndex].text)?.toUpperCase() || 'ALL'}`,
                "Ticket Status:",
                `${filterStatusRef.current?.value == 'default' ? 'ALL' : filterStatusRef.current?.value?.toUpperCase()}`]
        ];
        pdf.autoTable({
            body: data,
            startY: 43,
            theme: "plain", // Removes default styling
            styles: { fontSize: 11, cellPadding: 2, textColor: [0, 0, 0] },
            margin: { left: leftMargin, right: leftMargin },
            tableWidth: "auto",
            columnStyles: {
                0: { fontStyle: "bold", cellWidth: 40, }, // Label column
                1: { cellWidth: 90 }, // Value column
                2: { fontStyle: "bold", cellWidth: 40 }, // Label column
                3: { cellWidth: 80 }  // Value column
            }
        });


        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(normalTextSize);
        pdf.setTextColor('black');
        pdf.text("This report provides an overview of the tickets, including trends, analytics, and ticket distribution.", leftMargin, 70);

        pdf.setFillColor('#EBEBEB');
        pdf.rect(leftMargin, 80, 190, greyLineHeight, 'F');

        pdf.setFontSize(normalTextSize);
        pdf.setTextColor('black');
        pdf.text("Key Statistics", leftMargin + 2, 86);

        // Compute total tickets
        const totalTickets = ticketCounts.opened + ticketCounts.pending + ticketCounts.resolved + ticketCounts.closed;

        // Table data
        const ticketData = [
            { category: "Opened", count: ticketCounts.opened },
            { category: "Pending", count: ticketCounts.pending },
            { category: "Resolved", count: ticketCounts.resolved },
            { category: "Closed", count: ticketCounts.closed },
            { category: "Total", count: totalTickets },
        ];

        // Convert data into an array format for autoTable
        const ticketRows = ticketData.map(row => [row.category, row.count]);

        // Add Table using autoTable
        pdf.autoTable({
            startY: 98, // Position below "Total Tickets"
            head: [["Ticket", "Count"]], // Table headers
            body: ticketRows,
            theme: "grid", // Adds borders to cells
            styles: { fontSize: 12, cellPadding: 2, textColor: 20 },
            headStyles: { fillColor: [0, 81, 152], textColor: [255, 255, 255] }, // Blue header with white text
            columnStyles: {
                0: { cellWidth: 120 }, // Full-width first column
                1: { cellWidth: 70 }, // Full-width second column
            },
            tableWidth: "auto", // Ensure it expands across the page
            margin: { left: leftMargin, right: leftMargin }, // Full width with proper margins
            didParseCell: function (data) {
                if (data.row.raw[0] === "Total") {
                    data.cell.styles.fontStyle = "bold";
                }
            }
        });

        pdf.setFillColor('#EBEBEB');
        pdf.rect(leftMargin, 160, 190, greyLineHeight, 'F');

        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(normalTextSize);
        pdf.setTextColor('black');
        pdf.text("Visual Representation for Severity", leftMargin + 2, 166);

        // Capture Pie Chart
        const pieCanvas = await html2canvas(PieChartRef.current, { scale: 2, allowTaint: true });
        const pieImg = pieCanvas.toDataURL("image/png");
        pdf.addImage(pieImg, "PNG", 0, 172, contentWidth * 6 / 10, 100);

        const legends = [
            { color: [255, 0, 0], label: "Critical" }, // Red
            { color: [255, 165, 0], label: "High" }, // Orange
            { color: [255, 255, 0], label: "Medium" }, // Yellow
            { color: [0, 128, 0], label: "Low" }, // Green
            { color: [0, 0, 255], label: "Info" }, // Blue
        ];

        let legendX = leftMargin; // Start from left margin
        let legendY = 172 + 110 - 15; // Below the pie chart

        let spacing = 10; // Minimum space between items

        // Add Legends
        legends.forEach((legend, index) => {
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(normalTextSize);

            // Calculate text width dynamically
            let textWidth = pdf.getTextWidth(legend.label);

            // Draw colored square
            pdf.setFillColor(legend.color[0], legend.color[1], legend.color[2]);
            pdf.rect(legendX, legendY, 4, 4, 'F'); // 4x4 mm square

            // Draw label next to square
            pdf.setTextColor('black');
            pdf.text(legend.label, legendX + 6, legendY + 4);

            // Move legendX for the next item
            legendX += textWidth + spacing + 1; // Add extra space after text
        });
        // **Add Table on the Right Side of Pie Chart**
        const tableData = [
            { category: "Critical", count: reportsToDisplay?.criticalviolation, percentage: Math.round((reportsToDisplay?.criticalviolation / reportsToDisplay?.overallrisk) * 100) + "%" },
            { category: "High", count: reportsToDisplay?.highrisk, percentage: Math.round((reportsToDisplay?.highrisk / reportsToDisplay?.overallrisk) * 100) + "%" },
            { category: "Medium", count: reportsToDisplay?.mediumrisk, percentage: Math.round((reportsToDisplay?.mediumrisk / reportsToDisplay?.overallrisk) * 100) + "%" },
            { category: "Low", count: reportsToDisplay?.lowrisk, percentage: Math.round((reportsToDisplay?.lowrisk / reportsToDisplay?.overallrisk) * 100) + "%" },
            { category: "Info", count: reportsToDisplay?.inforisk, percentage: Math.round((reportsToDisplay?.inforisk / reportsToDisplay?.overallrisk) * 100) + "%" },
            { category: "Total", count: reportsToDisplay?.overallrisk, percentage: "100%" },
        ];

        // Convert data into an array format suitable for autoTable
        const tableRows = tableData.map(row => [row.category, row.count, row.percentage]);

        // Add Table using autoTable
        autoTable(pdf, {
            startY: 190, // Adjust Y position
            head: [["Category", "Count", "Percentage"]], // Table headers
            body: tableRows,
            theme: "grid", // Adds borders to cells
            styles: { fontSize: 11, cellPadding: 2, textColor: 20 },
            headStyles: { fillColor: [0, 81, 152], textColor: [255, 255, 255] }, // Blue header with white text
            columnStyles: {
                0: { cellWidth: 25 }, // Category column width
                1: { cellWidth: 20, halign: "center" }, // Count column width, center aligned
                2: { cellWidth: 25, halign: "center" }, // Percentage column width, center aligned
            },
            margin: { left: (contentWidth * 7) / 10 - 10 }, // Position table to the right
            didParseCell: function (data) {
                if (data.row.raw[0] === "Total") {
                    data.cell.styles.fontStyle = "bold";
                }
            }
        });


        // **Page 2 - Bar Chart & Analysis**
        pdf.addPage();

        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(22);
        pdf.text(`Summary Report`, pageWidth / 2, 20, { align: "center" });

        pdf.setDrawColor('#005198')
        pdf.setLineWidth(2);
        pdf.line(leftMargin, 30, 200, 30);

        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(20);
        pdf.setTextColor('#005198');
        pdf.text("Trends & Analysis", pageWidth / 2, 45, { align: "center" });

        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(normalTextSize);
        pdf.setTextColor('black');
        pdf.text("This section highlights the trends in ticket creation and resolution over the time, offering insights", leftMargin, 60);
        pdf.text("into workflow efficiency and areas that require attention.", leftMargin, 70);


        pdf.setFillColor('#EBEBEB');
        pdf.rect(leftMargin, 90, 190, greyLineHeight, 'F');

        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(normalTextSize);
        pdf.setTextColor('black');
        pdf.text("Ticket Trend Chart", leftMargin + 2, 96);

        // Capture Bar Chart
        const barCanvas = await html2canvas(BarChartRef.current, { scale: 2, allowTaint: true });
        const barImg = barCanvas.toDataURL("image/png");
        pdf.addImage(barImg, "PNG", margin, 110, contentWidth, 140);

        const legendsForBar = [
            { color: [3, 169, 244], label: "Opened" },
            { color: [255, 235, 59], label: "Pending" },
            { color: [76, 175, 80], label: "Resolved" },
            { color: [244, 67, 54], label: "Closed" },
        ];

        let legendsForBarX = contentWidth / 4;
        let legendsForBarY = 172 + 110 - 25;

        let spacingForBar = 15; // Minimum space between items

        // Add Legends
        legendsForBar.forEach((legend, index) => {
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(normalTextSize);

            // Calculate text width dynamically
            let textWidth = pdf.getTextWidth(legend.label);

            // Draw colored square
            pdf.setFillColor(legend.color[0], legend.color[1], legend.color[2]);
            pdf.rect(legendsForBarX, legendsForBarY, 4, 4, 'F'); // 4x4 mm square

            // Draw label next to square
            pdf.setTextColor('black');
            pdf.text(legend.label, legendsForBarX + 6, legendsForBarY + 4);

            // Move legendX for the next item
            legendsForBarX += textWidth + spacingForBar + 1; // Add extra space after text
        });

        pdf.addPage();
        pdf.setFontSize(20);
        pdf.setTextColor('#005198');
        pdf.text("Ticket Status Analysis", pageWidth / 2, 15, { align: "center" });

        const lastY = generateMonthlyTable(pdf, reportsToDisplay.incidentgraph);
        generateDetailedReport(pdf, reportsToDisplay, lastY + 20)

        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor('black');
            pdf.setFont("helvetica", "normal");
            pdf.text(`Page ${i} of ${totalPages}`, pageWidth / 2, pageHeight - 10, { align: "center" });
            pdf.setFont("helvetica", "bold");
            pdf.text(`Report Generated: ${reportDate}`, pageWidth - 50, pageHeight - 10);
        }

        pdf.save("report.pdf");
    };

    function handleReportFilter(e) {
        e.preventDefault();
        const formdata = new FormData(e.target);
        if (!isNotCustomer) {
            formdata.append('customer_id', userData.userId);
        }
        //console.log(Object.fromEntries(formdata.entries()));
        mutate(formdata);
    }

    function handleClearFilter(e) {
        e.preventDefault();
        document.getElementById("reportHeaderForm")?.reset();
        setFilteredReport(null);
        queryClient.invalidateQueries({ queryKey: ['report(tickets)'] });
    }

    const { mutate, isPending: isFiltering } = useMutation({
        mutationFn: (formdata) => filterReports(token, formdata),
        onSuccess: function (data) {
            //console.log(data);
            setFilteredReport(data);
        },
        onError: function (error) {
            toast.error(error.message);
        },
    });

    const reportsToDisplay = filteredReport?.data?.length >= 0 ? filteredReport : reportsData || { data: [] };
    //console.log(reportsToDisplay);

    // For reports pdf

    const ticketCounts = { opened: 0, pending: 0, resolved: 0, closed: 0 };
    reportsToDisplay?.incidentgraph?.forEach(({ status, statusCount }) => {
        const key = status.toLowerCase();
        if (ticketCounts[key] !== undefined) {
            ticketCounts[key] += statusCount;
        }
    });

    // const result = analyzeIncidentData(reportsToDisplay?.incidentgraph);

    return (
        <>
            {/* Top Section */}
            <div className="top_section p-3">
                <div className="pagesNamelink">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/Dashboard">{t("admindashboard.dashboard")}</Link></li>
                            <li className="breadcrumb-item"><Link to="/reports">{t("report.report")}</Link></li>
                        </ol>
                    </nav>
                    <h4 className="text-white">{t("report.report")}</h4>
                </div>
            </div>

            <div className='container-fluid p-3'>
                <div className='flex justify-end gap-2 mb-3 items-center'>
                    <span className='text-white text-nowrap'>{t("report.exportas")}</span>
                    <button className='text-white d-flex align-items-center py-2 px-3 rounded-lg bg-red-500' onClick={handleExportPDF}>
                        <img src={Pdf} alt="PDF" /> <span>PDF</span>
                    </button>
                    {/* <button className='text-white d-flex align-items-center py-2 px-3 rounded-lg bg-green-500'>
                            <img src={Csv} alt="CSV" /> <span>CSV</span>
                        </button> */}
                </div>
                <div className='row g-3 justify-content-start items-center'>

                    <div className={`col-12 ${isNotCustomer && 'col-xxl-9 '} ${!isNotCustomer && 'col-xxl-6'}`}>
                        <form className="row g-3" id='reportHeaderForm' onSubmit={handleReportFilter}>
                            {isNotCustomer &&
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <select className="form-select" name='customer_id' defaultValue='default' ref={filterCustomerRef}>
                                        <option disabled={true} value='default'>{t("report.customers")}</option>
                                        {customerData && customerData.data.map((elem) => (
                                            <option key={elem.customerId} value={elem.customerId}>{elem.company_name}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {isNotCustomer &&
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <select className="form-select" name='analyst_id' defaultValue='default'>
                                        <option disabled={true} value='default'>{t("report.analyst")}</option>
                                        {analystData && analystData.map((elem) => (
                                            <option key={elem.userId} value={elem.userId}>{elem.first_name + ' ' + elem.last_name}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            <div className={`col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ${!isNotCustomer && 'col-xl-3'}`}>
                                <select className="form-select" name='days' defaultValue='default' ref={filterDaysRef}>
                                    <option disabled={true} value='default'>{t("report.selectdays")}</option>
                                    <option value='7'>7 days</option>
                                    <option value='14'>14 days</option>
                                    <option value='30'>30 days</option>
                                    <option value='60'>60 days</option>
                                    <option value='90'>90 days</option>
                                </select>
                            </div>
                            <div className={`col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ${!isNotCustomer && 'col-xl-3'}`}>
                                <select className="form-select" name='status' defaultValue='default' ref={filterStatusRef}>
                                    <option disabled={true} value='default'>{t("report.status")}</option>
                                    <option value='Opened'>Opened</option>
                                    <option value='Pending'>Pending</option>
                                    <option value='Resolved'>Resolved</option>
                                    <option value='Closed'>Closed</option>
                                </select>
                            </div>
                            <div className={`col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ${!isNotCustomer && 'col-xl-3'}`}>
                                <select className="form-select" name='severity' defaultValue='default' ref={filterSeverityRef}>
                                    <option disabled={true} value='default'>{t("report.severity")}</option>
                                    <option value='critical'>Critical</option>
                                    <option value='high'>High</option>
                                    <option value='medium'>Medium</option>
                                    <option value='low'>Low</option>
                                    <option value='info'>Info</option>
                                </select>
                            </div>
                            <div className={`col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ${!isNotCustomer && 'col-xl-3'}`}>
                                <button type='submit' className="secondary_btn w-100">{t("report.submit")}</button>
                            </div>
                        </form>
                    </div>

                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-12">
                        <button className="cancel_btn " onClick={handleClearFilter}>{t("report.clearfilters")}</button>
                    </div>
                </div>

            </div>

            <div className="container-fluid p-3">
                <div className='row g-3 align-items-end justify-end'>
                    <ReportsCharts reportsData={reportsToDisplay} PieChartRef={PieChartRef} BarChartRef={BarChartRef} />
                </div>
            </div>
            <div className="mainSection container-fluid p-3 mt-5">
                <div className='row g-3 align-items-end justify-end'>
                    <ReportTable reportsData={reportsToDisplay} isLoading={isLoading} error={error} />
                </div>
            </div>
        </>
    );
};

export default ReportContainer;