import React, { useEffect, useState } from 'react';
import { Bar, BarChart, Tooltip, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';
import useUserStore from '../../store/UserStore';
import { BASE_URL } from '../../constant/const';
import axios from 'axios';
import { t } from '../Language/Translator';

const AdminBarChart = () => {
    const [graphData, setGraphData] = useState([]);
    const [loading, setLoading] = useState(true);

    const BarGraph_Data = useUserStore((state) => state.user);
    const token = BarGraph_Data;

    const STATUS_COLORS = {
        Opened: "url(#openedGradient)",
        Pending: "url(#pendingGradient)",
        Resolved: "url(#resolvedGradient)",
        Closed: "url(#closedGradient)"
    };
    const STATUS_COLORS2 = {
        Opened: "#03A9F4",
        Pending: "#FFEB3B",
        Resolved: "#4CAF50",
        Closed: "#F44336"
    };
 
    // Process data for the chart
    function processChartData(graphData) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]; // Dynamically get the last 12 months

        const structuredData = months.map((month) => ({
            month,
            Opened: 0,
            Pending: 0,
            Resolved: 0,
            Closed: 0,
        }));

        graphData?.incidentgraph?.forEach(({ monthName, statusCount, status }) => {
            const monthIndex = months.indexOf(monthName);
            if (monthIndex !== -1) {
                structuredData[monthIndex][status] += statusCount;
            }
        });
        return structuredData;
    };

    const barChartData = processChartData(graphData);


    useEffect(() => {
        fetchBarGraphData();
    }, [token]);

    async function fetchBarGraphData() {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.get(`${BASE_URL}/dashboard/`, config);
            setGraphData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div>
                <div className='mt-3'>
                    <h3>{t("admindashboardgraphs.bargraphtitle")}</h3>
                </div>
                <div className='flex-grow'>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={barChartData} margin={{
                            top:
                                20, right: 30, left: 0, bottom: 20
                        }}>
                            {/* Grid & Axis */}
                            <CartesianGrid strokeDasharray="3 3"
                                stroke="#444" />
                            <XAxis dataKey="month" tick={{ fill: "grey", fontSize: 18 }} />
                            <YAxis tick={{ fill: "grey", fontSize: 18 }}  />
                            <Tooltip contentStyle={{
                                background:
                                    "#222", borderRadius: "8px", color: "white"
                            }} />
                            {/* <Legend wrapperStyle={{ color: "white",fontSize: "14px" }}/> */}

                            {/* Gradient for Better Look */}
                            <defs>
                                <linearGradient id="openedGradient"
                                    x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%"
                                        stopColor="#03A9F4" stopOpacity={0.8} />
                                    <stop offset="95%"
                                        stopColor="#03A9F4" stopOpacity={0.2} />
                                </linearGradient>
                                <linearGradient id="pendingGradient"
                                    x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%"
                                        stopColor="#FFEB3B" stopOpacity={0.8} />
                                    <stop offset="95%"
                                        stopColor="#FFEB3B" stopOpacity={0.2} />
                                </linearGradient>
                                <linearGradient id="resolvedGradient"
                                    x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%"
                                        stopColor="#4CAF50" stopOpacity={0.8} />
                                    <stop offset="95%"
                                        stopColor="#4CAF50" stopOpacity={0.2} />
                                </linearGradient>
                                <linearGradient id="closedGradient"
                                    x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%"
                                        stopColor="#F44336" stopOpacity={0.8} />
                                    <stop offset="95%"
                                        stopColor="#F44336" stopOpacity={0.2} />
                                </linearGradient>
                            </defs>

                            {/* Bars with Gradient Colors */}
                            {Object.keys(STATUS_COLORS).map((status) => (
                                <Bar key={status} dataKey={status} stackId="a" fill={STATUS_COLORS[status]} barSize={40} />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                    <div className="flex justify-center gap-3 flex-wrap">
                        {Object.keys(STATUS_COLORS2).map((status,
                            index) => (
                            <div key={index} className="flex items-center space-x-2">
                                <span className="w-4 h-4 rounded-full"
                                    style={{ backgroundColor: STATUS_COLORS2[status] }}></span>
                                <span className="text-sm font-medium capitalize text-white">{status}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminBarChart;
