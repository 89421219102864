// import React, { useEffect, useState } from 'react';
// import { PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer } from 'recharts';
// import './../../assets/css/riskmatrix.css';
// import Loader from '../commonForm/Loader';
// import axios from 'axios';
// import useUserStore from '../../store/UserStore';
// import { BASE_URL } from '../../constant/const.js';
// import AdminDashboardTable from './AdminDashboardTable.js';

// const COLORS = ['#7D2105', '#BA2E0F', '#B2911C', '#CC5F21','#3184C2'];

// const AdminPieChart = () => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const user_data = useUserStore((state) => state.user);
//     const token = user_data;

//     const CustomLegend = () => {
//         return (
//             <div className='flex justify-center mt-3'>
//                 {data.map((entry, index) => (
//                     entry.value > 0 && (
//                         <div key={entry.name} className='flex items-center mr-4 text-sm text-gray-300'>
//                             <div className='w-3 h-3 gap-2' style={{ backgroundColor: COLORS[index % COLORS.length] }}></div><span>{entry.name}</span>
//                         </div>
//                     )
//                 ))}
//             </div>
//         );
//     }

//     useEffect(() => {
//         fetchData();
//     }, [token, BASE_URL]);

//     async function fetchData() {
//         try {
//             const config = {
//                 headers: {
//                     "Content-type": "application/json",
//                     "Authorization": token,
//                 },
//             };
//             const response = await axios.get(`${BASE_URL}dashboard`, config);
//             //console.log('API Response:', response.data);

//             const tabdata = [
//                 { name: 'Critical', value: response.data.criticalviolation },
//                 { name: 'High', value: response.data.highrisk },
//                 { name: 'Low', value: response.data.lowrisk },
//                 { name: 'Medium', value: response.data.mediumrisk },
//             ];

//             //console.log('Processed Data:', tabdata);

//             setData(tabdata || []);
//             setLoading(false);
//         } catch (error) {
//             console.error("Error fetching data:", error);
//             setLoading(false);
//         }
//     }

//     if (loading) {
//         return <Loader />;
//     }

//     return (
//         <div className='flex flex-row -mt-3 mb-10'>
//             <div className=''>
//                 <div className='mt-3 ml-3'>
//                 <h3>Risk Rating Breakdown</h3>
//                 </div>
//                 <div className='-mt-5 w-[350px]'>
//                     <ResponsiveContainer width="100%" height={250}>
//                         <PieChart width={400} height={300}>
//                             <Tooltip />
//                             <Legend content={<CustomLegend />} />
//                             <Pie
//                                 data={data}
//                                 cx="50%" // Center x
//                                 cy="50%" // Center y
//                                 labelLine={false}
//                                 outerRadius={80}
//                                 fill="#8884d8"
//                                 dataKey="value"
//                             >
//                                 {data.map((entry, index) => (
//                                     <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                                 ))}
//                             </Pie>
//                         </PieChart>
//                     </ResponsiveContainer>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AdminPieChart;





import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts'; // Import the Chart component
import './../../assets/css/riskmatrix.css';
import Loader from '../commonForm/Loader';
import axios from 'axios';
import useUserStore from '../../store/UserStore';
import { BASE_URL } from '../../constant/const.js';
import { useNavigate } from 'react-router-dom';
import { t } from '../Language/Translator.js';

const AdminPieChart = () => {
    const [data, setData] = useState([]); // State for the pie chart data
    const [loading, setLoading] = useState(true); // State for loading status
    const [selectedSlice, setSelectedSlice] = useState(null); // State for the selected slice
    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    const SEVERITIES = ["Critical", "High", "Medium", "Low", "Info"];
    const navigate=useNavigate();

    useEffect(() => {
        fetchData(); // Fetch data when the component mounts or token changes
    }, [token]);

    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.get(`${BASE_URL}dashboard`, config);
            //console.log('API Response:', response.data);

            const tabdata = [
                ['Risk Level', 'Count'], // Header for the Google Chart
                ['Critical', response.data.criticalviolation],
                ['High', response.data.highrisk],
                ['Medium', response.data.mediumrisk],
                ['Low', response.data.lowrisk],
                ['Info', response.data.inforisk],
            ];

            //console.log('Processed Data:', tabdata);
            setData(tabdata); // Set the chart data
            setLoading(false); // Update loading status
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false); // Update loading status on error
        }
    };

    if (loading) {
        return <Loader />; // Show loader while data is being fetched
    }

    const pieChartOptions = {
        is3D: true,
        pieSliceText: "percentage",
        pieSliceTextStyle: { fontSize: 18 },
        focusTarget: "category", // Highlights the whole category on hover
        enableInteractivity: true,
        slices: {
            0: { color: "#D32F2F" }, // Dark Red
            1: { color: "#F57C00" }, // Orange
            2: { color: "#FFC107" }, // Yellow
            3: { color: "#388E3C" }, // Green
            4: { color: "#1976D2" }  // Blue
        },
        chartArea: { width: '80%', height: '80%' },
        backgroundColor: "transparent",
        legend: "none",
        tooltip: { textStyle: { fontSize: 16, bold: false } },

    };

    const handleChartClick = ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        //console.log(selection);

        if (selection.length > 0) {
            const sliceIndex = selection[0].row
            if (sliceIndex !== null || sliceIndex !== undefined) {
                const severity = SEVERITIES[sliceIndex];
                navigate(`/tickets?severity=${severity}`);
            }
        }
    };



    return (
        <div className='piechart_graph'>
            <div className='mt-3 ml-3'>
            <h5 className="text-white text-center my-2">{t("admindashboardgraphs.piecharttitle")}</h5>
            </div>
            <Chart
                chartType="PieChart"
                data={data} // Use the processed data
                loader={<Loader />}
                options={pieChartOptions}
                width="100%"
                height="350px"
                chartEvents={[
                    {
                        eventName: "select", callback:
                            handleChartClick
                    }]}

            />
            <div className="flex justify-center xl:gap-3 gap-2 mt-12 sm:flex-nowrap flex-wrap mx-auto">
                {data.slice(1).map((severity, index) => ( // Skip header row
                    <div key={index} className="flex items-center space-x-2">
                        <span
                            className="w-4 h-4 rounded-full"
                            style={{ backgroundColor: pieChartOptions.slices[index].color }} // Use color from pie chart options
                        ></span>
                        <span className="text-sm font-medium capitalize text-white">{severity[0]}</span> {/* Show severity label */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminPieChart;



// import React, { useState } from "react";
// import { Chart } from "react-google-charts";
// import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, Legend } from "recharts";
// import Loader from "../commonForm/Loader";

// const STATUS_COLORS = {
//     opened: "url(#resolvedGradient)",
//     pending: "url(#pendingGradient)",
//     resolved: "url(#openedGradient)",
//     closed: "url(#closedGradient)"
// };
// const STATUS_COLORS2 = {
//     opened: "#4CAF50",
//     pending: "#FFEB3B",
//     resolved: "#03A9F4",
//     closed: "#F44336"
// };
// const SEVERITIES = ["Critical", "High", "Medium", "Low", "Info"];
// const SEVERITY_COLORS = ["#D32F2F", "#F57C00", "#FFC107", "#388E3C", "#1976D2"];
// const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

// const ReportsCharts = ({ reportsData }) => {
//     const [selectedSlice, setSelectedSlice] = useState(null);
//     //console.log(selectedSlice);


//     const ticketCounts = { opened: 0, pending: 0, resolved: 0, closed: 0 };
//     reportsData?.incidentgraph?.forEach(({ status, statusCount }) => {
//         const key = status.toLowerCase();
//         if (ticketCounts[key] !== undefined) {
//             ticketCounts[key] += statusCount;
//         }
//     });

//     // Pie Chart Data (Severity)
//     const pieChartData = [
//         ["Severity", "Count"],
//         ["Critical", reportsData?.criticalviolation || 0],
//         ["High", reportsData?.highrisk || 0],
//         ["Medium", reportsData?.mediumrisk || 0],
//         ["Low", reportsData?.lowrisk || 0],
//         ["Info", reportsData?.inforisk || 0]
//     ];
//     // reportsData.criticalviolation === reportsData.overallrisk
//     const pieChartOptions = {
//         is3D: true,
//         pieSliceText: "percentage",
//         pieSliceTextStyle: { fontSize: 14 },
//         focusTarget: "category", // Highlights the whole category on hover
//         slices: {
//             0: {
//                 color: "#D32F2F", offset: selectedSlice === 0 ? 0.15 :
//                     0
//             }, // Dark Red
//             1: {
//                 color: "#F57C00", offset: selectedSlice === 1 ? 0.15 :
//                     0
//             }, // Orange
//             2: {
//                 color: "#FFC107", offset: selectedSlice === 2 ? 0.15 :
//                     0
//             }, // Yellow
//             3: {
//                 color: "#388E3C", offset: selectedSlice === 3 ? 0.15 :
//                     0
//             }, // Green
//             4: {
//                 color: "#1976D2", offset: selectedSlice === 4 ? 0.15 :
//                     0
//             }  // Blue
//         },
//         backgroundColor: "transparent",
//         legend: "none",
//         tooltip: { textStyle: { fontSize: 16, bold: false } },

//     };

//     const handleChartClick = ({ chartWrapper }) => {
//         const chart = chartWrapper.getChart();
//         const selection = chart.getSelection();
//         if (selection.length > 0) {
//             const sliceIndex = selection[0].row;
//             setSelectedSlice(sliceIndex);
//         }
//     };

//     // Bar Chart Data (Tickets per Month & Status)
//     const filterCurrentYearData = (incidentgraph) => {
//         const currentYear = new Date().getFullYear();
//         let filteredData = incidentgraph?.filter((entry) => {
//             const entryDate = new Date(entry.monthName + " 1, " + currentYear);
//             return entryDate.getFullYear() === currentYear;
//         }) || [];

//         // Ensure at least Jan - June are present
//         const monthsInData = new Set(filteredData.map(entry => entry.monthName));
//         MONTHS.slice(0, 6).forEach(month => {
//             if (!monthsInData.has(month)) {
//                 filteredData.push({
//                     monthName: month, opened: 0,
//                     pending: 0, resolved: 0, closed: 0
//                 });
//             }
//         });

//         return filteredData.sort((a, b) => MONTHS.indexOf(a.monthName)
//             - MONTHS.indexOf(b.monthName));
//     };

//     const currentYearData =
//         filterCurrentYearData(reportsData.incidentgraph);

//     // Process data for the chart
//     const barChartData = currentYearData.reduce((acc, entry) => {
//         const month = entry.monthName;
//         const status = entry.status?.toLowerCase();
//         const count = entry.statusCount || 0;

//         let monthEntry = acc.find((e) => e.month === month);
//         if (!monthEntry) {
//             monthEntry = {
//                 month, opened: 0, pending: 0, resolved: 0,
//                 closed: 0
//             };
//             acc.push(monthEntry);
//         }

//         if (status) {
//             monthEntry[status] = (monthEntry[status] || 0) + count;
//         }
//         return acc;
//     }, []);

//     return (

//         <section>


//             <div className="flex flex-col xl:flex-row gap-4 items-center lg:items-start">
//                 {/* Pie Chart */}
//                 <figure className="pieChart mainSection w-full xl:w-1/3 h-[500px]">
//                     <h5 className="text-white text-left my-2">Severity</h5>
//                     <Chart chartType="PieChart" data={pieChartData} loader={<Loader />} options={pieChartOptions} width="100%" height="350px"
//                         chartEvents={[
//                             {
//                                 eventName: "select", callback:
//                                     handleChartClick
//                             },
//                         ]} />
//                     <div className="flex justify-center xl:gap-3 gap-2
// mt-4 sm:flex-nowrap flex-wrap mx-auto">
//                         {SEVERITIES.map((severity, index) => (
//                             <div key={index} className="flex items-center space-x-2">
//                                 <span className="w-4 h-4 rounded-full"
//                                     style={{ backgroundColor: SEVERITY_COLORS[index] }}></span>
//                                 <span className="text-sm font-medium capitalize text-white">{severity}</span>
//                             </div>
//                         ))}
//                     </div>
//                 </figure>

//                 {/* Bar Chart */}
//                 <figure className="barGraph mainSection w-full lg:flex-1 min-w-0 min-h-[500px]">
//                     <h5 className="text-white text-left my-2">Ticket Management(Current Year)</h5>
//                     <ResponsiveContainer width="100%" height={400}>
//                         <BarChart data={barChartData} margin={{
//                             top:
//                                 20, right: 30, left: 0, bottom: 20
//                         }}>
//                             {/* Grid & Axis */}
//                             <CartesianGrid strokeDasharray="3 3"
//                                 stroke="#444" />
//                             <XAxis dataKey="month" tick={{
//                                 fill: "#ddd"
//                             }} />
//                             <YAxis tick={{ fill: "#ddd" }} />
//                             <Tooltip contentStyle={{
//                                 background:
//                                     "#222", borderRadius: "8px", color: "white"
//                             }} />
//                             {/* <Legend wrapperStyle={{ color: "white",fontSize: "14px" }}/> */}

//                             {/* Gradient for Better Look */}
//                             <defs>
//                                 <linearGradient id="openedGradient"
//                                     x1="0" y1="0" x2="0" y2="1">
//                                     <stop offset="5%"
//                                         stopColor="#4CAF50" stopOpacity={0.8} />
//                                     <stop offset="95%"
//                                         stopColor="#4CAF50" stopOpacity={0.2} />
//                                 </linearGradient>
//                                 <linearGradient id="pendingGradient"
//                                     x1="0" y1="0" x2="0" y2="1">
//                                     <stop offset="5%"
//                                         stopColor="#FFEB3B" stopOpacity={0.8} />
//                                     <stop offset="95%"
//                                         stopColor="#FFEB3B" stopOpacity={0.2} />
//                                 </linearGradient>
//                                 <linearGradient id="resolvedGradient"
//                                     x1="0" y1="0" x2="0" y2="1">
//                                     <stop offset="5%"
//                                         stopColor="#03A9F4" stopOpacity={0.8} />
//                                     <stop offset="95%"
//                                         stopColor="#03A9F4" stopOpacity={0.2} />
//                                 </linearGradient>
//                                 <linearGradient id="closedGradient"
//                                     x1="0" y1="0" x2="0" y2="1">
//                                     <stop offset="5%"
//                                         stopColor="#F44336" stopOpacity={0.8} />
//                                     <stop offset="95%"
//                                         stopColor="#F44336" stopOpacity={0.2} />
//                                 </linearGradient>
//                             </defs>

//                             {/* Bars with Gradient Colors */}
//                             {Object.keys(STATUS_COLORS).map((status) => (
//                                 <Bar key={status} dataKey={status} stackId="a" fill={STATUS_COLORS[status]} barSize={40} />
//                             ))}
//                         </BarChart>
//                     </ResponsiveContainer>
//                     <div className="flex justify-center gap-3 flex-wrap">
//                         {Object.keys(STATUS_COLORS2).map((status,
//                             index) => (
//                             <div key={index} className="flex items-center space-x-2">
//                                 <span className="w-4 h-4 rounded-full"
//                                     style={{ backgroundColor: STATUS_COLORS2[status] }}></span>
//                                 <span className="text-sm font-medium capitalize text-white">{status}</span>
//                             </div>
//                         ))}
//                     </div>
//                 </figure>
//             </div>
//         </section>
//     );

// };

// export default ReportsCharts;

