import React, { useState } from "react";
import { BASE_URL } from '../../constant/const.js';
import axios from "axios";
import UserStore from '../../../src/store/UserStore.js';
import { handleResponse } from "../utility/ExceptionHandel";
import Loader from "../commonForm/Loader.js";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import Country from "../commonForm/Country.js";
import State from "../commonForm/State.js";
import City from "../commonForm/City.js";
import DatePicker from "react-datepicker";
import { t } from "../Language/Translator.js";

const AddUser = () => {

    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, SetEmail] = useState('');
    const [username, setUsername] = useState('');
    const [phoneno, setPhoneno] = useState('');
    const [alternetphoneno, setAlternetPhoneno] = useState('');
    const [role, setRole] = useState('');
    const [addressone, setAddressone] = useState('');
    const [addresstwo, setAddresstwo] = useState('');
    const [dob, setDob] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [status, setStatus] = useState('');
    const user_data = UserStore((state) => state.user);
    const token = user_data;


    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleStatus = (event) => {
        setStatus(event.target.value);
    };

    const navigate = useNavigate();

    const validateEmail = (email) => {
        // Regular expression for validating email
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const repeatedDotPattern = /\.{2,}|\.com\.com/i; // Pattern to check for repeated ".com" or multiple dots

        return emailPattern.test(email) && !repeatedDotPattern.test(email);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (email && !validateEmail(email)) {
            toast.error('Invalid email format. Please ensure you do not use repeated ".com".');
            setLoading(false);
            return;
        }

        const userData = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            user_type: role,
            phone: phoneno,
            alternate_phone: alternetphoneno,
            dob: dob ? dob.toLocaleDateString('en-CA') : null,
            address: addressone,
            address2: addresstwo,
            state: selectedState,
            city: selectedCity,
            zip: zipcode,
            country: selectedCountry,
            isActive:true,
        }
        // if (!validateEmail(email)) {
        //     toast.error('Invalid email format. Please ensure you do not use repeated ".com".');
        // }

        // console.log(userData);


        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.post(`${BASE_URL}user/create-user`, userData, config);
            toast.success(response.data.message);
            navigate('/userlist')
            setLoading(false);

        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }

            setLoading(false);
        }
    };

    function handleCountrySelect(country) {
        setSelectedCountry(country);
        setSelectedState('');
        setSelectedCity('');
    }

    function handleStateSelect(state) {
        setSelectedState(state);
        setSelectedCity('');
    }

    return (
        <div>

            <div className="mainBody">
                <div className="top_section">
                    <div className="pagesNamelink">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/dashboard">{t("admindashboard.dashboard")}</Link></li>
                                <li className="breadcrumb-item"><Link to="/userlist">{t("users.user")}</Link></li>
                                <li className="breadcrumb-item text-white">{t("addeditusers.adduser")}</li>
                            </ol>
                        </nav>
                        <h4 className="text-white">{t("addeditusers.adduser")}</h4>
                    </div>
                </div>

                <div className="mainSection">
                    <form onSubmit={handleSubmit} className="row g-3">

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.firstname")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.firstname")}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.lastname")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.lastname")}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.username")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.username")}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.email")}</label>
                            <input type="email" className="form-control" placeholder={t("addeditusers.email")}
                                value={email}
                                onChange={(e) => SetEmail(e.target.value)}
                            />
                        </div>
                        <ToastContainer />

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.phoneno")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.phoneno")}
                                value={phoneno}
                                onChange={(e) => setPhoneno(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.alternatephoneno")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.alternatephoneno")}
                                value={alternetphoneno}
                                onChange={(e) => setAlternetPhoneno(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.dateofbirth")}</label>
                            <DatePicker
                                selected={dob}
                                onChange={(date) => setDob(date)}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                maxDate={new Date().setDate(new Date().getDate() - 1)}
                                dateFormat="MM/dd/yyyy"
                                wrapperClassName='w-full'
                                className="form-control"
                                placeholderText="MM/DD/YYYY"
                            />
                            {/* <input type="date" className="form-control" name='DOB'
                            value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                max={yesterday}
                            /> */}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.role")}</label>
                            <select className="form-control"
                                value={role}
                                onChange={handleRoleChange}
                            >
                                <option selected value="">{t("addeditusers.selectrole")}</option>
                                <option value="admin">Admin</option>
                                <option value="analyst">Analyst</option>
                            </select>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <label className="form-label">{t("addeditusers.address")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.address")}
                                value={addressone}
                                onChange={(e) => setAddressone(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <label className="form-label">{t("addeditusers.address2")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.address2")}
                                value={addresstwo}
                                onChange={(e) => setAddresstwo(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.status")}</label>
                            <select className="form-control"
                                value={status}
                                onChange={handleStatus}
                            >
                                <option selected value="">{t("addeditusers.selectstatus")}</option>
                                <option value={true}>Active</option>
                                <option value={false}>Inactive</option>


                            </select>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.country")}</label>
                            <Country onCountrySelect={handleCountrySelect} selectedvaluecountry={selectedCountry} />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.state")}</label>
                            <State countryId={selectedCountry} onStateSelect={handleStateSelect} selectedvaluestate={selectedState} />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.city")}</label>
                            <City stateId={selectedState} onCitySelect={setSelectedCity} selectedvaluecity={selectedCity} />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">{t("addeditusers.zipcode")}</label>
                            <input type="text" className="form-control" placeholder={t("addeditusers.zipcode")}
                                value={zipcode}
                                onChange={(e) => setZipcode(e.target.value)}
                            />
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                            {loading ? <Loader /> : ''}
                            <button type="submit"
                                class={`secondary_btn ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={loading}
                            >
                                {t("addeditusers.submit")}</button>

                        </div>
                    </form>
                </div>
            </div>
        </div>



    )
}

export default AddUser;