import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../constant/const.js';
import UserStore from '../../../src/store/UserStore.js';

import edit from "../../assets/images/Icons/edit.svg";
import deleteicon from "../../assets/images/Icons/delete.svg";

import { Link, useNavigate } from 'react-router-dom';

import Loader from '../commonForm/Loader.js';
import { encryptData, decryptData } from "../utility/EncryptDecrypt.js";
import { handleResponse } from '../utility/ExceptionHandel.js';

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Swal from 'sweetalert2';
import Pagination from '../commonForm/Pagination.js';
import ToggleButton from '../commonForm/ToggleButton.js';
import { t } from '../Language/Translator';


const UserList = () => {
    const [userdata, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    const [userSearchData, setUserSearchData] = useState(null);

    const [userID, setuserID] = useState('');
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [email, SetEmail] = useState('');
    const [phoneno, setPhoneno] = useState('');
    const [role, setRole] = useState('');
    const [status, setStatus] = useState('')



    const user_data = UserStore((state) => state.user);
    const token = user_data;
    const navigate = useNavigate();
    //const token = decryptData(user_data);

    const [toggle, setToggle] = useState({ isActive: false });

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleToggle = () => {
        setToggle(prevItem => ({ ...prevItem, isActive: !prevItem.isActive }));
    };

    useEffect(() => {
        if (userSearchData) {
            // setLoading(true);
            fetchSearchData();
        }
        else {
            fetchData();
        }

    }, [currentPage, token, userSearchData]);

    const paginate = async(pageNumber) => {
        setCurrentPage(pageNumber);
        if(userSearchData){
            await fetchSearchData();
            setUserSearchData(prev=>({...prev,page:currentPage}));
        }
        else{
            fetchData();
        }
    };

    //fetch all data
    async function fetchData() {
        setLoading(true);
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.get(`${BASE_URL}user/users?page=${currentPage}`, config);
            setData(response.data.data || []);
            setTotalItems(response.data.pagination.totalItems);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
            //  handleResponse(error);
        }
    }
    //fetch search all data
    async function fetchSearchData() {
        try {
            const searchPayload={
                ...userSearchData,page:currentPage
            }
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.post(`${BASE_URL}user/search-user`, searchPayload, config);
            setData(response.data.data || []);
            setTotalItems(response.data.pagination.totalItems);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
            //  handleResponse(error);
        }
    }

    //delete user 
    async function handleDelete(user_uuid) {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.delete(`${BASE_URL}user/delete-user/${user_uuid}`, config);

            toast.success(response.data.message);
        } catch (error) {
            if (error.response) {
                toast.warning(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        }

    }

    function deleteUseralert(user_uuid) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this item!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#19C489',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(user_uuid);
                setData(prevData => prevData.filter(item => item.user_uuid !== user_uuid));
                Swal.fire(
                    'Deleted!',
                    'Your item has been deleted.',
                    'success'
                );
            }
        });
    }

    //search user
    const HandleSearch = (event) => {
        event.preventDefault();
        const zeroPattern = /^0+$/; // Matches "0", "00", "000", etc.
        if (zeroPattern.test(userID)) {
            toast.error('User Id must be a Number. ');
            return; // Stop further processing
        };

        if (!userID && !username && !name && !email && !role && !phoneno && !status) {
            toast.error('Please select at least one filter.');
            return; // Stop further processing
        }

        const userSearchData = {
            user_id: userID,
            user_name: username,
            full_name: name,
            email: email,
            role: role,
            phone: phoneno,
            status: status || '',
            page: 1,
            pageSize: 10,
        }

        setUserSearchData(userSearchData);
    }
    //clear filter
    function handleClearFilter(e) {
        e.preventDefault();
        document.getElementById("filterForm").reset();
        setuserID('');
        setUsername('');
        setName('');
        SetEmail('');
        setPhoneno('');
        setRole('');
        setStatus('');
        setUserSearchData(null);
    }


    const handleEditUser = (e, user_uuid) => {
        e.preventDefault();
        navigate(`/edituser/${(user_uuid)}`)
    }





    return (

        <div>
            <ToastContainer />
            <div className="top_section">
                <div className="pagesNamelink">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">{t("admindashboard.dashboard")}</Link></li>
                            <li className="breadcrumb-item text-white">{t("users.user")}</li>
                            {/* <li className="breadcrumb-item" aria-current="page">User</li> */}
                        </ol>
                    </nav>
                    <h4 className="text-white">{t("users.userslist")}</h4>
                </div>
                <Link to="/adduser" className="main_btn no-underline"><i className="fa-solid fa-plus"></i>{t("users.adduser")}</Link>

            </div>
            <div className="mainSection">
                <div className='row g-3 align-items-end justify-end mb-3'>
                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-12">
                        <button className="cancel_btn ms-auto" onClick={handleClearFilter}>{t("users.clearfilters")}</button>
                    </div>
                </div>

                <div className="mainTable table-responsive">
                    {loading ? <Loader /> : ''}
                    <form id="filterForm" onSubmit={HandleSearch}>
                        <table id="example" className="table table-dark table-striped table-hover">
                            {!loading &&
                                <>
                                    <thead>
                                        <tr>
                                            <th>{t("users.userid")}</th>
                                            <th>{t("users.username")}</th>
                                            <th>{t("users.name")}</th>
                                            <th>{t("users.email")}</th>
                                            <th>{t("users.phoneno")}</th>
                                            <th>{t("users.role")}</th>
                                            <th>{t("users.status")}</th>
                                            <th>{t("users.actions")}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <th>
                                                <input type="text" className="form-control" placeholder={t("users.userid")}
                                                    value={userID}
                                                    onChange={(e) => setuserID(e.target.value)}
                                                />
                                            </th>
                                            <th>
                                                <input type="text" className="form-control" placeholder={t("users.username")}
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                />
                                            </th>
                                            <th>
                                                <input type="text" className="form-control" placeholder={t("users.name")}
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </th>
                                            <th>
                                                <input type="text" className="form-control" placeholder={t("users.email")}
                                                    value={email}
                                                    onChange={(e) => SetEmail(e.target.value)}
                                                />
                                            </th>
                                            <th>
                                                <input type="text" className="form-control" placeholder={t("users.phoneno")}
                                                    value={phoneno}
                                                    onChange={(e) => setPhoneno(e.target.value)}
                                                />
                                            </th>
                                            <th>
                                                <select className="form-select"
                                                    value={role}
                                                    onChange={handleRoleChange}
                                                >
                                                    <option selected>Role</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="analyst">Analyst</option>
                                                </select>
                                            </th>

                                            <th>
                                                <select className="form-select"
                                                    value={status}
                                                    onChange={handleStatusChange}
                                                >
                                                    <option selected>Status</option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>In Active</option>
                                                </select>
                                            </th>
                                            <th>
                                                <button type="submit" className="secondary_btn">{t("users.apply")}</button>
                                            </th>

                                        </tr>
                                        {userdata.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.userId}</td>
                                                <td>{item.user_name}</td>
                                                <td>{item.first_name}{item.last_name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.phone}</td>
                                                <td className='capitalize'>{item.user_type}</td>
                                                <td>
                                                    <ToggleButton
                                                        item={{ isActive: item.isActive }}
                                                        onToggle={item.isActive ? null : handleToggle} // Make it non-clickable if isActive is true
                                                        disabled={item.isActive} // Disable the toggle button if isActive is true
                                                    />
                                                </td>

                                                <td>
                                                    <div className="actionBtn">
                                                        <div className="actionBtn flex justify-between items-center">
                                                            {/** Adjusted div, added tailwind class for aligning horizontal */}

                                                            <div className="flex items-center ml-1">
                                                                {/* <button to={`/edituser/${(item.user_uuid)}`} className="ml-2 Edit">
                                                            <img src={edit} alt="Edit" />
                                                        </button> */}
                                                                <button onClick={(e) => handleEditUser(e, item.user_uuid)} className="ml-2 Edit">
                                                                    <img src={edit} alt="Edit" />
                                                                </button>

                                                                <button type="button" onClick={() => deleteUseralert(item.user_uuid)} className="ml-2 Delete">
                                                                    <img src={deleteicon} alt="Delete" />
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                         { (userdata?.length <= 0 || userSearchData?.length <= 0) && <p className='text-center text-white mt-4'>No matching results found !</p>}
                                    </tbody>
                                </>
                            }

                        </table>
                    </form>
                </div>
                {
                    userdata.length > 0 &&
                    <div>
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={totalItems}
                            paginate={paginate}
                            currPage={currentPage}
                        />
                    </div>
                }
            </div>

        </div>


    )
}

export default UserList;