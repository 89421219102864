import React, { useState } from 'react';
import '../../App.css';
import { BASE_URL } from '../../constant/const.js';
import VerifyOtp from './VerifyOtp.js';
import AuthStore from '../../../src/store/AuthStore.js'
import Loader from '../commonForm/Loader.js';
import ReCaptcha from 'react-google-recaptcha';

import { default as RightMenu } from "./RightMenu.js";
import logo from "../../assets/images/logo.png";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { t } from '../Language/Translator.js';


// let error_count = 1;
// const siteKey = '6Lf8CqQqAAAAAIbrUvMsrj-hzvvuBfaS4yNGVqRX';
function LoginForm() {
    const [email, setemail] = useState('');
    const [password, setPassword] = useState('');
    // const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // const [captchaValue, setCaptchaValue] = useState('');

    const setUser = AuthStore((state) => state.setUser);

    const [isOtpOpen, setIsOtpOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);


    // const handleCaptchaChange = (value) => {
    //     setCaptchaValue(value);
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        // if (error_count >= 2) {
        //     if (captchaValue) {
        //         // Now proceed with your form data submission logic
        //         alert("Your CAPTCHA check passed!");

        //     } else {
        //         // Handle any errors here.
        //         console.warn(`Failed to validate form due to invalid reCAPTCHA result.`);
        //     }
        // }
        try {
            const response = await fetch(BASE_URL + 'auth/sign-in', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_name: email, password }),
            });
            const data = await response.json();
           
            
            if (data.status == 'success') {
                toast.success(data.message);
                setLoading(false);
                setUser(data);
                setIsSuccess(true);
                setIsOtpOpen(true);
            } else {
                //setError(data.message);
                
                
                toast.warning(data.message);
            }
        } catch (error) {
            toast.error(error.message);
            //setError(error.message);
        } finally {
            // error_count++;
            setLoading(false);
        }
    };





    return (
        <div className="login_bg">
            <ToastContainer />
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 login_wrapper">
                        <div className="login-outer">
                            <div className="login_box ">
                                <div className="Main_logo text-center">
                                    <img src={logo} alt="logo image" className="logo_login img-fluid" />
                                </div>

                                <form onSubmit={handleSubmit} className="w-100">
                                    <h2 className="login_title">{t("login.logintitle")}</h2>
                                    <div className="mb-xxl-4 mb-3">
                                        <label for="exampleInputEmail1" className="form-label">{t("login.username")}</label>
                                        <div className="position-relative">
                                            <input type="text" className="form-control" placeholder={t("login.username")}
                                                id="email"
                                                name="email"
                                                onChange={(event) => setemail(event.target.value)}

                                            />
                                        </div>

                                    </div>
                                    <div className="mb-xxl-4 mb-3">
                                        <label className="form-label">{t("login.password")}</label>
                                        <div className="position-relative">
                                            <input type="password" className="form-control" placeholder={t("login.password")}
                                                id="password"
                                                name="password"
                                                onChange={(event) => setPassword(event.target.value)}
                                            />

                                        </div>

                                    </div>
                                    <div className="d-flex align-items-center justify-content-end mb-xxl-3 mb-0">
                                        <Link className="forgot_a" to="/ForgotPassword">
                                            {t("login.forgetpassword")}
                                        </Link>
                                    </div>
                                    <button type="submit"
                                        class={`login_btn mt-3 mb-xxl-3 mb-xl-3 mb-lg-1 mb-md-2 mb-3 text-white ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={loading}
                                    >
                                        {t("login.sendotp")}</button>
                                    {loading ? <Loader /> : ''}


                                </form>

                            </div>
                        </div>
                    </div>
                    <RightMenu />
                </div>
            </div>
            {isSuccess && (
                <VerifyOtp isOpen={isOtpOpen} setIsOtpOpen={setIsOtpOpen} />
            )}
        </div>

    );
}


export default LoginForm;