import React, { useEffect, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, LineChart, Line, } from "recharts";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useUserStore from "../../store/UserStore";
import { BASE_URL } from "../../constant/const";
import { t } from '../Language/Translator';

const AdminAreaChart = () => {
  const risk_data = useUserStore((state) => state.user);
  const token = risk_data;

  const [overallData, setOverallData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOverallData();
  }, []);

  const fetchOverallData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: token,
        },
      };
      const response = await axios.get(`${BASE_URL}dashboard/toprisks/all`, config);
      setOverallData(response.data.topseverities || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching overall data:", error);
      setLoading(false);
    }
  };

  const processOverallData = (data) => {
    const monthOrder = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthMap = {};

    data.forEach((entry) => {
      const { month, severity, count } = entry;
      if (!monthMap[month]) {
        monthMap[month] = { month };
      }
      monthMap[month][severity] = (monthMap[month][severity] || 0) + count;
    });
    return Object.values(monthMap)
      .map((entry) => ({
        month: entry.month,
        critical: entry.critical || 0,
        high: entry.high || 0,
        // medium: entry.medium || 0,
        // low: entry.low || 0,
        // info: entry.info || 0,
      }))
      .sort((a, b) => monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month));
  };

  const chartData = processOverallData(overallData);

  return (
    <div className="ProfileMiddleContainer flex flex-row gap-0">
      <div className="h-full rounded-xl flex flex-col flex_spacing">
        <div className="mt-2 flex flex-col justify-between header_flex">
          <h5 className="text-white text-center my-2">{t("admindashboardgraphs.linecharttitle")}</h5>
          {/* <div className="flex items-center justify-center  my-2">
            <DatePicker
              selected={selectedMonth}
              onChange={handleMonthChange}
              dateFormat="MMMM"
              showMonthYearPicker
              placeholderText="Select Month"
              className="bg-black text-white px-2 py-1 rounded-md w-5/6 h-full text-sm"
            />
            <button
              className="bg-blue-500 text-white px-4 py-1 rounded-md"
              onClick={handleAllClick}
            >
              All
            </button>
          </div> */}
        </div>

        <div className="flex flex-col w-full mt-3">
          <ResponsiveContainer width='100%' height={370}>
            <LineChart data={chartData}>
              <XAxis dataKey="month" tick={{ fill: "grey", fontSize: 18 }} />
              <YAxis tick={{ fill: "grey", fontSize: 18 }} />
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 3" stroke="#444" />
              {/* {chartData.length > 0 &&
                Object.keys(chartData)
                  .filter((key) => key !== "month")
                  .map((risk, index) => (
                    <Line
                      key={index}
                      type="monotone"
                      dataKey={risk}
                      stroke={getColor(risk)}
                      fillOpacity={0.7}
                      fill={getColor(risk)} // Fill the area under the line
                    />
                  ))} */}
              <Line type="monotone" dataKey="critical" stroke={getColor('critical')} />
              <Line type="monotone" dataKey="high" stroke={getColor('high')} />
              <Line type="monotone" dataKey="medium" stroke={getColor('medium')} />
              <Line type="monotone" dataKey="low" stroke={getColor('low')}/>
              <Line type="monotone" dataKey="info" stroke={getColor('info')}/>
            </LineChart>
          </ResponsiveContainer>
          <div className="flex justify-center gap-3 flex-wrap mt-3">
            <div className="flex items-center space-x-2">
              <span className="w-4 h-4 rounded-full" style={{ backgroundColor: 'red' }}></span>
              <span className="text-sm font-medium capitalize text-white">Critical</span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="w-4 h-4 rounded-full" style={{ backgroundColor: 'orange' }}></span>
              <span className="text-sm font-medium capitalize text-white">High</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getColor = (risk) => {
  const colors = {
    critical: "#D32F2F",
    high: "#F57C00",
    medium: "#FFC107",
    low: "#388E3C",
    info: "#1976D2",
  };
  return colors[risk] || "#fff";
};

const CustomTooltip = ({ active, payload, selectedMonth }) => {
  //console.log(payload);
  
  if (active && payload && payload.length) {
    return (
      <div className="bg-gray-700 p-2 rounded-md text-white">
        <p>{payload[0].payload.month}</p>
        {payload.map((entry, index) => (
          
          <p key={index} style={{ color: entry.color }}>
            {entry.name}: {entry.value}
          </p>
         
        ))}
      </div>
    );
  }
  return null;
};

export default AdminAreaChart;

