import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../constant/const';
import Country from '../../commonForm/Country';
import State from '../../commonForm/State';
import City from '../../commonForm/City';
import useUserStore from '../../../store/UserStore';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import EditStepper from './EditStepper';
import { t } from '../../Language/Translator';

const EditCustomerStep1 = ({ customerUuid, activeStep, setActiveStep }) => {
    const customer_data = useUserStore((state) => state.user);
    const token = customer_data;

    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [addressTwo, setAddressTwo] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [alternatePhoneNo, setAlternatePhoneNo] = useState('');
    const [email, setEmail] = useState('');
    const [isActive, setIsActive] = useState('');
    const [toggle, setToggle] = useState({ isActive: false });
    const [initialValues, setInitialValues] = useState({});


    const handleStatus = (event) => {
        setIsActive(event.target.value);
    };



    const handleSubmit = async (event) => {
        event.preventDefault();

        let customerStatus;
        if (isActive === "Active") {
            customerStatus = true;
        } else if (isActive === "Inactive") {
            customerStatus = false;
        } else {
            toast.warning("Please Provide Status.");
            return;
        }

        // Gather current form values for submission
        const currentValues = {
            company_name: companyName,
            address: address,
            address2: addressTwo,
            state: state,
            city: city,
            zip: zipCode,
            country: country,
            phone: phoneNo,
            alternate_phone: alternatePhoneNo,
            // email:email,
            isActive: customerStatus,
        };


        setLoading(true);
        const CustomerData = currentValues;

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            };
            console.log(CustomerData);

            // Make the PUT request to update customer information
            const response = await axios.put(`${BASE_URL}customer/update-customer/${customerUuid}`, CustomerData, config);


            // Check the response status and display appropriate messages
            if (response.data && response.data.status === 'success') {
                toast.success(response.data.message); // Success message
                await fetchData();
                setActiveStep(1); // Move to the next step
            } else {
                toast.error("Failed to update customer data."); // Error message for failure
            }
        } catch (error) {
            // Handle errors coming from the API or network issues
            if (error.response) {
                toast.warning(error.response.data.message); // Display API error message
            } else {
                toast.error("An unexpected error occurred: " + error.message); // Display generic error message
            }
        } finally {
            setLoading(false); // Reset loading state regardless of success or failure
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            };
            const response = await axios.get(`${BASE_URL}customer/view-customer/${customerUuid}`, config);

            if (response.data?.status === 'success' && response.data?.data?.length > 0) {
                const customerData = response.data.data[0];

                // Populate the form fields with data
                setCompanyName(customerData.first_name || '');
                setAddress(customerData.address || '');
                setAddressTwo(customerData.address2 || '');
                setCity(customerData.city || '');
                setState(customerData.state || '');
                setZipCode(customerData.zip || '');
                setCountry(customerData.country || '');
                setPhoneNo(customerData.phone || '');
                setAlternatePhoneNo(customerData.alternate_phone || '');
                setEmail(customerData.email || '');
                setIsActive(customerData.isActive ? 'Active' : 'Inactive');

                // Store initial values for comparison
                setInitialValues({
                    company_name: customerData.first_name || '',
                    address: customerData.address || '',
                    address2: customerData.address2 || '',
                    state: customerData.state || '',
                    city: customerData.city || '',
                    zip: customerData.zip || '',
                    country: customerData.country || '',
                    phone: customerData.phone || '',
                    alternate_phone: customerData.alternate_phone || '',
                    email: customerData.email || '',
                    isActive: customerData.isActive ? 'Active' : 'Inactive',
                });
            } else {
                throw new Error("No customer data found");
            }
        } catch (error) {
            if (error.response) {
                toast.warning(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        }
    }

    return (
        <>
            <ToastContainer />
            <div className='mainBody'>
                <div className="top_section">
                    <div className="pagesNamelink">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/dashboard">{t("admindashboard.dashboard")}</a></li>
                                <li className="breadcrumb-item"><a href="/customerlist">{t("customers.customers")}</a></li>
                                <li className="breadcrumb-item active text-white" aria-current="page">{t("customerstep1.editcustomer")}</li>
                            </ol>
                        </nav>
                        <h4 className="text-white">{t("customerstep1.editcustomer")}</h4>
                    </div>
                </div>
                <div className='mainSection mt-3 mb-20'>
                    <EditStepper activeStep={activeStep} setActiveStep={setActiveStep} />
                    <div className="tab-pane fade ml-4 mr-4 mt-5 text-white show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">
                        <h3 className="wizardHead">{t("customerstep1.companyinformation")}</h3>
                        <form className="row g-3" onSubmit={handleSubmit}>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("customerstep1.companyname")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.companyname")} value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("customerstep1.address")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.address")} value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <label className="form-label">{t("customerstep1.address2")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.address")} value={addressTwo} onChange={(e) => setAddressTwo(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.status")}</label>
                                <select className="form-control" value={isActive} onChange={handleStatus}>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.country")}</label>
                                <Country onCountrySelect={setCountry} selectedvaluecountry={country} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.state")}</label>
                                <State countryId={country} onStateSelect={setState} selectedvaluestate={state} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.city")}</label>
                                <City stateId={state} onCitySelect={setCity} selectedvaluecity={city} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.zipcode")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.zipcode")} value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.phoneno")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.phoneno")} value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.alternatephoneno")}</label>
                                <input type="text" className="form-control" placeholder={t("customerstep1.alternatephoneno")} value={alternatePhoneNo} onChange={(e) => setAlternatePhoneNo(e.target.value)} />
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">{t("customerstep1.email")}</label>
                                <input type="email" className="form-control" readOnly placeholder={t("customerstep1.email")} value={email} />
                            </div>
                            <div className="col-12 d-flex justify-content-end">
                                <button type="submit" className={`secondary_btn ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={loading}>
                                    {t("customerstep1.next")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditCustomerStep1;
