// import React, { useState} from 'react';
// import { useParams } from 'react-router-dom';
// import EditCustomerStep1 from './EditCustomerStep1';
// import EditCustomerStep2 from './EditCustomerStep2';
// import EditCustomerStep3 from './EditCustomerStep3';
// import EditCustomerStep4 from './EditCustomerStep4';

// const EditCustomer = () => {
//   const [activeStep, setActiveStep] = useState(0)
  
//   const { customer_uuid } = useParams() // Get the customer UUID from the URL

//   return (
//     <div>
//       {activeStep === 0 && (
//         <EditCustomerStep1
//           customerUuid={customer_uuid} // Pass the customer UUID to the step component
//           setActiveStep={setActiveStep}
//           activeStep={activeStep}
//         />
//       )}
//       {activeStep === 1 && (
//         <EditCustomerStep2
//           customerUuid={customer_uuid}
//           setActiveStep={setActiveStep}
//           activeStep={activeStep}
//         />
//       )}
//       {activeStep === 2 && (
//         <EditCustomerStep3
//          customerUuid={customer_uuid}
//           setActiveStep={setActiveStep}
//           activeStep={activeStep}
//         />
//       )}
//       {activeStep === 3 && (
//         <EditCustomerStep4
//           customerUuid={customer_uuid}
//           setActiveStep={setActiveStep}
//           activeStep={activeStep}
//         />
//       )}
//     </div>
//   );
// };

// export default EditCustomer;



import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import EditCustomerStep1 from './EditCustomerStep1';
import EditCustomerStep2 from './EditCustomerStep2';
import EditCustomerStep3 from './EditCustomerStep3';
import EditCustomerStep4 from './EditCustomerStep4';

const EditCustomer = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { customer_uuid } = useParams(); // Get the customer UUID from the URL

  return (
    <div>
      {activeStep === 0 && (
        <EditCustomerStep1
          customerUuid={customer_uuid} // Pass the customer UUID to the step component
          setActiveStep={setActiveStep}
          activeStep={activeStep}
        />
      )}
      {activeStep === 1 && (
        <EditCustomerStep2
          customerUuid={customer_uuid}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
        />
      )}
      {activeStep === 2 && (
        <EditCustomerStep3
          customerUuid={customer_uuid}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
        />
      )}
      {activeStep === 3 && (
        <EditCustomerStep4
          customerUuid={customer_uuid}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
        />
      )}
    </div>
  );
};

export default EditCustomer;
