import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import fileUploadImg from "../../assets/images/Icons/fileupload.svg";
import { toast, ToastContainer } from 'react-toastify'
import { getCustomers } from '../../services/customer_api';
import { getAllUsers } from '../../services/user_api';
import useUserStore from '../../store/UserStore';
import { Link, Links, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { getAlertsForTickets, getTicket, removeTicketAttachment, updateTicket, updateTicketByCustomer } from '../../services/ticket_api';
import Loader from '../commonForm/Loader';
import { IoIosClose, IoMdClose } from 'react-icons/io';
import { t } from '../Language/Translator';
import { url } from '../../constant/const';

const EditTicket = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [dropdownSrcPort, setDropdownSrcPort] = useState("default");
    const [manualSrcPort, setManualSrcPort] = useState("");
    const [dropdownDstPort, setDropdownDstPort] = useState("default");
    const [manualDstPort, setManualDstPort] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [removedFiles, setRemovedFiles] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();

    const user_data = useUserStore((state) => state.user);
    const token = user_data;
    const userInfo = useUserStore((state) => state.userInfo);
    const isNotCustomer = !['customer', 'secondary_customer'].includes(userInfo?.user_type);

    const queryClient = useQueryClient();
    const { data: ticket, isLoading, error: ticketError } = useQuery({ queryKey: ['ticket', id], queryFn: () => getTicket(token, id), cache: 0 })
    const { data: customerData, error: customerError } = useQuery({ queryKey: ['editTicket(customer)'], queryFn: () => getCustomers(token), enabled: isNotCustomer })
    const { data: usersData, error: usersError } = useQuery({ queryKey: ['editTicket(users)'], queryFn: () => getAllUsers(token), enabled: isNotCustomer })
    const { data: alertData, error: alertError } = useQuery({ queryKey: ['alertData(editTicket)'], queryFn: () => getAlertsForTickets(token), enabled: isNotCustomer })
    console.log(ticket);
    useEffect(() => {
        const predefinedPorts = ['53', '80', '443', '21', '22'];
        if (ticket?.data?.src_port) {
            if (predefinedPorts.includes(ticket.data.src_port)) {
                setDropdownSrcPort(ticket.data.src_port.toString());
                setManualSrcPort("");
            } else {
                setDropdownSrcPort("SRCnone");
                setManualSrcPort(ticket.data.src_port.toString());
            }
        }
        if (ticket?.data?.dst_port) {
            if (predefinedPorts.includes(ticket.data.dst_port)) {
                setDropdownDstPort(ticket.data.dst_port.toString());
                setManualDstPort("");
            } else {
                setDropdownDstPort("DSTnone");
                setManualDstPort(ticket.data.dst_port.toString());
            }
        }
        if (ticket?.data?.timestamp) {
            setSelectedDate(new Date(ticket.data.timestamp));
        }
        if (ticket?.data?.attachments) {
            setSelectedFiles((ticket.data.attachments));
        }
    }, [ticket]);

    async function handleEditSubmitForm(e) {
        e.preventDefault();
        const formdata = new FormData(e.target);
        // //console.log(Object.fromEntries(formdata.entries()))
        if (!isNotCustomer) {
            mutate(formdata);

        } else {
            const finalSrcPort = (dropdownSrcPort && dropdownSrcPort !== 'SRCnone') ? dropdownSrcPort : manualSrcPort;
            if (finalSrcPort) {
                formdata.set("src_port", finalSrcPort);
            }
            const finalDstPort = (dropdownDstPort && dropdownDstPort !== 'DSTnone') ? dropdownDstPort : manualDstPort;
            if (finalDstPort) {
                formdata.set("dst_port", finalDstPort);
            }

            formdata.delete('src_port_dropdown');
            formdata.delete('src_port_manual');
            formdata.delete('dst_port_dropdown');
            formdata.delete('dst_port_manual');
            formdata.delete('customer_summary');

            formdata.delete("attachments");

            selectedFiles.forEach((file) => {
                if (file instanceof (File)) {
                    formdata.append("attachments", file)
                }
            })

            if (selectedDate) {
                // const formattedDate = format(selectedDate, "MM/dd/yyyy hh:mm a");
                const utcDate = new Date(selectedDate).toISOString();
                formdata.set("timestamp", utcDate);
            }

            try {
                for (const file of removedFiles) {
                    await removeTicketAttachment(token, ticket?.data?.ticket_uuid, file.filename);
                }
                setRemovedFiles([]);
            } catch (error) {
                toast.error("Error removing files: " + error.message);
                return;
            }

            //console.log(Object.fromEntries(formdata.entries()));
            for (let pair of formdata.entries()) {
                //console.log(pair[0], pair[1]);
            }

            mutate(formdata);
        }

    }

    function handleFileChange(e) {
        const files = e.target.files;
        console.log(files);

        setSelectedFiles(prevFiles => [...prevFiles, ...Array.from(files)])
    }

    function removeFile(fileToRemove) {
        //console.log(fileToRemove);
        //console.log(fileToRemove.filename);
        setSelectedFiles(prevFiles =>
            prevFiles.filter(file => file !== fileToRemove)
        )
        // remember this below code ensures to add only the whichthat are coming from view ticket api lol...
        if (fileToRemove.filename || fileToRemove.originalname) {
            setRemovedFiles(prev => [...prev, fileToRemove]);
        }
    }

    const { mutate, isPending: isUpdating } = useMutation({
        mutationFn: (formdata) => isNotCustomer ? updateTicket(token, formdata, id) : updateTicketByCustomer(token, formdata, id),
        onSuccess: async function (data) {
            queryClient.invalidateQueries({ queryKey: ['ticket', id] });
            toast.success(data.message);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            navigate('/tickets');
        },
        onError: function (error) {
            toast.error(error.message);
        },
    });

    return (
        <>
            <ToastContainer />
            <div className="top_section">
                <div className="pagesNamelink">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">{t("admindashboard.dashboard")}</Link></li>
                            <li className="breadcrumb-item"><Link to="/tickets">{t("tickets.ticket")}</Link></li>
                            <li className="breadcrumb-item text-white">{t("addeditticket.editticket")}</li>
                        </ol>
                    </nav>
                    <h4 className="text-white">{t("addeditticket.editticket")}</h4>
                </div>
            </div>
            <div className="mainSection">
                {isLoading && <Loader />}
                {ticket &&
                    <>
                        {!ticketError ?
                            <form className="row g-3" onSubmit={handleEditSubmitForm}>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.alerttype")}</label>
                                    <select className="form-select" name='alert_type' defaultValue={ticket.data.alert_type_id} disabled={!isNotCustomer}>
                                        {!isNotCustomer && <option value='defaultAlertType'>{ticket.data.alert_type}</option>}
                                        <option disabled={true}>--Select Alert Type--</option>
                                        {alertData && alertData.data.map((alert) => {
                                            return (
                                                <option key={alert.id} value={alert.id} className='capitalize'>{alert.alert_type}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.alert")}</label>
                                    <input type="text" className="form-control" placeholder={t("addeditticket.alert")} name='alert' defaultValue={ticket.data.alert} disabled={!isNotCustomer} />

                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.severitytype")}</label>
                                    <select className="form-select" name='severity' defaultValue={ticket.data.severity} disabled={!isNotCustomer}>
                                        <option disabled={true} >--Select Severity Type--</option>
                                        <option value='info'>Information</option>
                                        <option value='low'>Low</option>
                                        <option value='medium'>Medium</option>
                                        <option value='high'>High</option>
                                        <option value='critical'>Critical</option>
                                    </select>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.customer")}</label>
                                    <select className="form-select" name='customer_id' defaultValue={ticket.data.customer_id} disabled={!isNotCustomer}>
                                        {!isNotCustomer && <option value='defaultCustomer'>{ticket.data.customer_first_name + ' ' + ticket.data.customer_last_name}</option>}
                                        <option value='default' disabled={true}>--Select Customer--</option>
                                        {customerData && customerData.data.map((customer, index) => {
                                            return (
                                                <option key={index} value={customer.customerId}>{customer.company_name}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.domain")}</label>
                                    <input type="text" className="form-control" placeholder="yahoo.com" name='domain' defaultValue={ticket.data.domain} disabled={!isNotCustomer} />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.assignto")}</label>
                                    <select className="form-select" name='assigned_to' defaultValue={ticket.data.analyst_id} disabled={!isNotCustomer}>
                                        {!isNotCustomer && <option value='defaultAnalyst'>{ticket.data.analyst_first_name + ' ' + ticket.data.analyst_last_name}</option>}
                                        <option disabled={true} >--Select Assign To--</option>
                                        {usersData && usersData.data.map((user, index) => {
                                            return (
                                                <option key={index} value={user.userId}>{user.first_name + ' ' + user.last_name}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.sourceip")}</label>
                                    <input type="text" className="form-control" placeholder={t("addeditticket.sourceip")} name='source_ip' defaultValue={ticket.data.source_ip} disabled={!isNotCustomer} />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.sourceport")}</label>
                                    <select className="form-select" name='src_port_dropdown' value={dropdownSrcPort} onChange={(e) => setDropdownSrcPort(e.target.value)} disabled={!isNotCustomer}>
                                        <option disabled={true} value='default'>--Select Source Port--</option>
                                        <option value={53}>DNS(53)</option>
                                        <option value={80}>HTTP(80)</option>
                                        <option value={443}>HTTPS(443)</option>
                                        <option value={21}>FTP(21)</option>
                                        <option value={22}>SSH(22)</option>
                                        <option value='SRCnone'>None</option>
                                    </select>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.sourceportmanual")}</label>
                                    <input type="text" className="form-control" placeholder={t("addeditticket.sourceportmanual")} name="src_port_manual" value={manualSrcPort} disabled={(dropdownSrcPort && dropdownSrcPort !== 'SRCnone') || !isNotCustomer} onChange={(e) => setManualSrcPort(e.target.value)} />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.destinationip")}</label>
                                    <input type="text" className="form-control" placeholder={t("addeditticket.destinationip")} name='destination_ip' defaultValue={ticket.data.destination_ip} disabled={!isNotCustomer} />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.destinationport")}</label>
                                    <select className="form-select" name='dst_port_dropdown' value={dropdownDstPort} onChange={(e) => setDropdownDstPort(e.target.value)} disabled={!isNotCustomer}>
                                        <option disabled={true} value='default'>--Select Destination Port--</option>
                                        <option value={53}>DNS(53)</option>
                                        <option value={80}>HTTP(80)</option>
                                        <option value={443}>HTTPS(443)</option>
                                        <option value={21}>FTP(21)</option>
                                        <option value={22}>SSH(22)</option>
                                        <option value='DSTnone'>None</option>
                                    </select>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.destinationportmanual")}</label>
                                    <input type="text" className="form-control" placeholder={t("addeditticket.destinationportmanual")} name='dst_port_manual' value={manualDstPort} onChange={(e) => setManualDstPort(e.target.value)} disabled={(dropdownDstPort && dropdownDstPort !== 'DSTnone') || !isNotCustomer} />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.virustotallink")}</label>
                                    <input type="text" className="form-control" placeholder={t("addeditticket.virustotallink")} name='virus_total_link' defaultValue={ticket.data.virus_total_link} disabled={!isNotCustomer} />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.abuseddblink")}</label>
                                    <input type="text" className="form-control" placeholder={t("addeditticket.abuseddblink")} name='abuse_db_link' defaultValue={ticket.data.abuse_db_link} disabled={!isNotCustomer} />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.timestamp")}</label>
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                        showTimeSelect
                                        showMonthDropdown
                                        timeIntervals={5}
                                        dateFormat="MM/dd/yyyy h:mm a"
                                        className="form-control"
                                        wrapperClassName='w-full'
                                        placeholderText="MM/DD/YYYY HH:MM"
                                        disabled={!isNotCustomer}
                                    />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.processnamefilename")}</label>
                                    <input type="text" className="form-control" placeholder={t("addeditticket.processnamefilename")} name='fileName' defaultValue={ticket.data.fileName} disabled={!isNotCustomer} />
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <label className="form-label">{t("addeditticket.filehash")}</label>
                                    <input type="text" className="form-control" placeholder={t("addeditticket.filehash")} name='fileHash' defaultValue={ticket.data.fileHash} disabled={!isNotCustomer} />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">{t("addeditticket.status")}</label>
                                    <select className="form-select" name='ticket_status' defaultValue={(ticket.data.status).toLowerCase()} disabled={!isNotCustomer}>
                                        <option disabled={true} >Select Status</option>
                                        <option value="opened">Opened</option>
                                        <option value="pending">Pending</option>
                                        <option value="resolved">Resolved</option>
                                        <option value="closed">Closed</option>
                                    </select>
                                </div>
                                <div className="com-md-12">
                                    <div className="row g-3">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                            <label className="form-label">{t("addeditticket.attachments")}</label>
                                            <input className="form-control uploadFile d-none" type="file" onChange={handleFileChange} id="attachments" name="attachments" multiple disabled={!isNotCustomer} />
                                            <label htmlFor="attachments" className="uploadFile">
                                                <img src={fileUploadImg} alt="uploadFile" />
                                                <span>
                                                    <span>{t("addeditticket.browse")}</span> {t("addeditticket.file")}
                                                </span>
                                                <span>{selectedFiles.length} {t("addeditticket.filesselected")}</span>
                                            </label>
                                            <div className='flex flex-wrap gap-2 mt-1'>
                                                {selectedFiles.map((file) => {
                                                    return (
                                                        <span className='bg-slate-400 flex px-1.5 py-1 rounded-lg text-center' key={file.filename}>
                                                            <span className='text-decoration-none'>
                                                                {file.originalname || file.name}
                                                            </span>
                                                            {
                                                                isNotCustomer &&
                                                                <button
                                                                    type="button"
                                                                    className="text-red-800 ms-1 rounded-full p-0"
                                                                    onClick={() => removeFile(file)}
                                                                >
                                                                    <IoMdClose />
                                                                </button>
                                                            }
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                            <label className="form-label">{t("addeditticket.summary")}</label>
                                            <textarea className="form-control" placeholder={t("addeditticket.summary")} name='summary' rows="4" defaultValue={ticket.data.summary} disabled={!isNotCustomer}></textarea>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                            <label className="form-label">{t("addeditticket.customersummary")}</label>
                                            <textarea className="form-control" placeholder={t("addeditticket.customersummaryplaceholder")} name='customer_summary' rows="4" defaultValue={ticket.data.customer_summary}></textarea>
                                        </div>

                                    </div>
                                </div>


                                <div className="col-12 d-flex justify-content-end">
                                    {/* <span className='me-3'>{isAdding && <Loader />}</span> */}
                                    <span className='me-3'>{isUpdating && <Loader />}</span>
                                    <Link to={'/tickets'} className="cancel_btn me-3 no-underline">{t("addeditticket.cancel")}</Link>
                                    <button type='submit' className="secondary_btn" disabled={isUpdating}>{t("addeditticket.submit")}</button>
                                </div>
                            </form>
                            :
                            <p>Failed to fetch ticket !</p>

                        }
                    </>
                }

            </div>
        </>
    )
}

export default EditTicket