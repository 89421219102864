import React, { useState } from 'react';
import { BASE_URL } from '../../constant/const';
import useUserStore from '../../store/UserStore';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const UpdatePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    
    const updatedPassword = useUserStore((state) => state.user);
    const token = updatedPassword;
    const userInfo = useUserStore((state) => state.userInfo);

    const EyeStyle={
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        color: '#666',
        pointerEvents: 'auto',
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const PasswordData = {
            email: userInfo.email,
            old_password: oldPassword,
            new_password: newPassword,
            confirm_new_password: confirmPassword,
        };

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                }
            };
            const response = await axios.put(`${BASE_URL}/common/change-password`, PasswordData, config);
            toast.success(response.data.status=='success' && response.data.message);
        } catch (error) {
            toast.error(error.response?.data?.message || "Something went wrong!");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
        <ToastContainer/>
            <div className='mainBody'>
                <div className="top_section">
                    <div className="pagesNamelink">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/viewprofile">View Profile</Link></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Change Password</li>
                            </ol>
                        </nav>
                        <h4 className="text-white">Change Password</h4>
                    </div>
                </div>
                <div className='mainSection mt-3 mb-5 min-h-[250px]'>
                    <div className="tab-pane fade ml-4 mr-4 mt-5 text-white show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">

                        <form className="row g-3 mt-2" onSubmit={handleSubmit}>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label htmlFor="oldpassword" className="form-label">Old password</label>
                                <div className="position-relative">
                                    <input className="form-control" placeholder="Old password"
                                        id="oldpassword"
                                        name="oldpassword"
                                        value={oldPassword}
                                        onChange={(event) => setOldPassword(event.target.value)}
                                        type={showOldPassword ? "text" : "password"}
                                    />
                                    <span onClick={() => setShowOldPassword(!showOldPassword)} className="eye-icon">
                                        <FontAwesomeIcon icon={showOldPassword ? faEye : faEyeSlash} style={EyeStyle}/>
                                    </span>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label htmlFor="newpassword" className="form-label">New password</label>
                                <div className="position-relative">
                                    <input className="form-control" placeholder="New password"
                                        id="newpassword"
                                        name="newpassword"
                                        value={newPassword}
                                        onChange={(event) => setNewPassword(event.target.value)}
                                        type={showNewPassword ? "text" : "password"}
                                    />
                                    <span onClick={() => setShowNewPassword(!showNewPassword)} className="eye-icon">
                                        <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} style={EyeStyle}/>
                                    </span>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label htmlFor="confirm_password" className="form-label">Confirm password</label>
                                <div className="position-relative">
                                    <input className="form-control" placeholder="Confirm password"
                                        id="confirm_password"
                                        name="confirm_password"
                                        value={confirmPassword}
                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                        type={showConfirmPassword ? "text" : "password"}
                                    />
                                    <span onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="eye-icon">
                                        <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} style={EyeStyle}/>
                                    </span>
                                </div>
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <div className="position-relative">
                                    <button className="mt-4 secondary_btn" disabled={loading}>
                                        {loading ? 'Updating...' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </>
    );
}

export default UpdatePassword;
