import useUserStore from '../../store/UserStore';


const Logout = () => {
    const clearUser = useUserStore.getState().clearUser;
    
    // Clear user data from the store
    clearUser();
    
    // Clear all local storage data
    localStorage.clear();
    
    // Redirect to login page
    window.location.href = '/';
}

export default Logout



