import React from 'react'
import Loader from '../commonForm/Loader';
import { format } from 'date-fns'
import { t } from '../Language/Translator';

const ReportTable = ({reportsData,isLoading,error}) => {

    // const tableData=reportsData

    return (

        <>
            <div className="mainTable table-responsive">

                {isLoading && <Loader />}
                <table id="example" className="table table-striped table-dark">
                    {!isLoading && !error && reportsData &&
                        <>
                            <thead>
                                <tr>
                                    <th>{t("tickets.ticketid")}</th>
                                    <th>{t("tickets.alert")}</th>
                                    <th>{t("tickets.customer")}</th>
                                    <th>{t("tickets.severity")}</th>
                                    <th>{t("tickets.analysts")}</th>
                                    <th>{t("tickets.status")}</th>
                                    <th>{t("tickets.timestamp")}</th>
                                    <th>{t("addeditticket.sourceip")}</th>
                                    <th>{t("addeditticket.sourceport")}</th>
                                    <th>{t("addeditticket.destinationip")}</th>
                                    <th>{t("addeditticket.destinationport")}</th>
                                    <th>{t("addeditticket.domain")}</th>
                                    <th>{t("addeditticket.virustotallink")}</th>
                                    <th>{t("addeditticket.abuseddblink")}</th>
                                </tr>

                            </thead>
                            <tbody>
                                {reportsData.data.length > 0 && reportsData.data.map((ticket, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{ticket.id}</td>
                                            <td>{ticket.alert}</td>
                                            <td>{ticket.customer_name}</td>
                                            <td>
                                                <div className={`sevBtn Sev${ticket.severity} capitalize`}>{ticket.severity}</div>
                                            </td>
                                            <td>{ticket.analyst_name}</td>
                                            <td className={`status${(ticket.status).charAt(0).toUpperCase() + ticket.status.slice(1)} capitalize`}>{ticket.status}</td>
                                            <td>{format(ticket.timeStamp, "MM-dd-yyyy HH:mm:ss")}</td>
                                            <td >{ticket.source_ip}</td>
                                            <td >{ticket.src_port}</td>
                                            <td>{ticket.destination_ip}</td>
                                            <td >{ticket.dst_port}</td>
                                            <td >{ticket.domain}</td>
                                            <td >{ticket.virus_total_link}</td>
                                            <td >{ticket.abuse_db_link}</td>
                                        </tr>

                                    );
                                })}
                                {reportsData.data.length == 0 && <p className='text-center text-white mt-4'>{t("report.noresultsfound")}</p>}

                            </tbody>
                        </>
                    }
                </table>

            </div>
        </>

    )
}

export default ReportTable