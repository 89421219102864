import { format } from "date-fns";

// export function analyzeIncidentData(incidentgraph) {
//     const monthCounts = {}; // Store total ticket count per month
//     const pendingCounts = {}; // Store pending count per month
//     let totalTickets = 0;
//     let resolvedTickets = 0;
//     if (incidentgraph) {

//         // Aggregate data
//         incidentgraph?.forEach(({ monthName, status, statusCount }) => {
//             // Track total tickets per month
//             if (!monthCounts[monthName]) {
//                 monthCounts[monthName] = 0;
//             }
//             monthCounts[monthName] += statusCount;

//             // Track pending tickets per month (assuming "Pending" is critical)
//             if (status === "Pending") {
//                 if (!pendingCounts[monthName]) {
//                     pendingCounts[monthName] = 0;
//                 }
//                 pendingCounts[monthName] += statusCount;
//             }

//             // Track overall ticket resolution rate
//             totalTickets += statusCount;
//             if (status === "Resolved" || status === "Closed") {
//                 resolvedTickets += statusCount;
//             }
//         });

//         // Determine the months with highest and lowest ticket counts
//         const sortedMonths = Object.entries(monthCounts).sort((a, b) => b[1] - a[1]);
//         const highestMonth = sortedMonths[0][0]; // Month with highest tickets
//         const lowestMonth = sortedMonths[sortedMonths.length - 1][0]; // Month with lowest tickets

//         // Find the month with the most "critical" tickets (Pending tickets)
//         const criticalMonth = Object.entries(pendingCounts).sort((a, b) => b[1] - a[1])[0]?.[0] || "N/A";

//         // Calculate resolution rate
//         const resolutionRate = totalTickets > 0 ? ((resolvedTickets / totalTickets) * 100).toFixed(2) + "%" : "0%";

//         return {
//             highestMonth,
//             lowestMonth,
//             criticalMonth,
//             resolutionRate
//         };
//     }

// }


export function generateMonthlyTable(pdf, ticketData) {
    const tableColumn = ["Month", "Opened", "Pending", "Resolved", "Closed", "Total", "Resolution Rate"];
    const tableRows = [];

    // Process data month-wise (accumulate values instead of overwriting)
    const monthlyData = {};
    ticketData.forEach(({ monthName, status, statusCount }) => {
        if (!monthlyData[monthName]) {
            monthlyData[monthName] = { Opened: 0, Pending: 0, Resolved: 0, Closed: 0 };
        }
        monthlyData[monthName][status] += statusCount; // Accumulate counts
    });

    // Populate rows
    Object.keys(monthlyData).forEach((month) => {
        const { Opened = 0, Pending = 0, Resolved = 0, Closed = 0 } = monthlyData[month];
        const total = Opened + Pending + Resolved + Closed;

        // Calculate Resolution Rate correctly
        const resolutionRate = total > 0 ? Math.round(((Resolved + Closed) / total) * 100) : "0";

        tableRows.push([month, Opened, Pending, Resolved, Closed, total, `${resolutionRate}%`]);
    });

    // Table styling
    pdf.autoTable({
        startY: 25,
        head: [tableColumn],
        body: tableRows,
        theme: "striped",
        styles: { fontSize: 11, cellPadding: 3 },
        headStyles: { fillColor: '#005198', textColor: [255, 255, 255] },
        alternateRowStyles: { fillColor: [240, 240, 240] },
        margin: { top: 30 }
    });

    return pdf.lastAutoTable.finalY;
}

export function generateDetailedReport(pdf, reportsData, startY) {
    const pageWidth = pdf.internal.pageSize.width;

    // Title
    pdf.setFontSize(20);
    pdf.setTextColor("#005198");
    pdf.text("Detailed Ticket Report", pageWidth / 2, startY, { align: "center" });

    // Define table columns
    const tableColumn = [
        "Event Name", 
        "Source IP Address", 
        "Source Port", 
        "Destination IP Address", 
        "Destination Port", 
        "Timestamp"
    ];

    // Table data with proper formatting
    const tableRows = reportsData?.data?.map(ticket => ([
        ticket.alert || " ",
        ticket.source_ip || " ",
        ticket.src_port || " ",
        ticket.destination_ip || " ",
        ticket.dst_port || " ",
        format(new Date(ticket.timeStamp), "MM/dd/yyyy HH:mm") || " "
    ]));

    // Generate the table with better alignment
    pdf.autoTable({
        startY: startY + 10,
        head: [tableColumn],
        body: tableRows,
        theme: "striped",
        styles: { 
            fontSize: 10, 
            cellPadding: 4, 
            overflow: "linebreak",
            valign: "middle",
        },
        headStyles: { fillColor: "#005198", textColor: [255, 255, 255] },
        alternateRowStyles: { fillColor: [240, 240, 240] },
        margin: { top: 20,left:8 },
        columnStyles: {
            0: { cellWidth: 40 },  // Event Name
            1: { cellWidth: 35 },  // Source IP Address
            2: { cellWidth: 25 },  // Source Port
            3: { cellWidth: 35 },  // Destination IP Address
            4: { cellWidth: 28 },  // Destination Port
            5: { cellWidth: 30},  // Timestamp
        },
        didDrawCell: (data) => {
            if (data.column.index === 5 && data.cell.raw.length > 20) {
                pdf.setFontSize(8);
            }
        },
        willDrawCell: (data) => {
            if (data.row.section === "body") {
                // Ensuring entire row moves to next page if necessary
                const availablePageHeight = pdf.internal.pageSize.height - data.row.y;
                if (availablePageHeight < 15) {
                    pdf.addPage();
                    data.row.y = pdf.autoTable.previous.finalY + 10;
                }
            }
        },
        rowPageBreak: "avoid"  // Prevents row splitting across pages
    });
}