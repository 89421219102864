
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './assets/css/style.css';
import './assets/css/fontawesome.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/datatables.min.css';
import './assets/css/jquery.dataTables.min.css';
import './assets/css/responsive.dataTables.min.css';
import './assets/css/responsive.css';
import './assets/css/style.css.map';
import './assets/js/script';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import LoginForm from './components/Auth/LoginForm';
import MasterLayout from './components/Dashbaord/MasterLayout';
import loginBg from './images/login_bg.png';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import PrivateRoute from './components/PrivateRoute';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import UserList from './components/User/UserList';
import AddUser from './components/User/AddUser';
import Logout from './components/Auth/Logout';
import AddTicket from './components/Ticket/AddTicket';
import TicketList from './components/Ticket/TicketList';
import EditTicket from './components/Ticket/EditTicket';
import ArchivedTicket from './components/Ticket/ArchivedTicket';
import EditUser from './components/User/EditUser';
import CustomerInfraList from './components/CustomerInfrastructure/CustomerInfraList';
import AddCustomerInfra from './components/CustomerInfrastructure/AddCustomerInfra';

import AddCustomer from './components/Customer/AddCustomer/AddCustomer';
import CustomerList from './components/Customer/CustomerList';
import NotFound from './components/NotFound/NotFound';
import EditCustomer from './components/Customer/EditCustomer/EditCustomer';
import ReportContainer from './components/ReportsPage/ReportContainer';
import UpdatePassword from './components/Auth/UpdatePassword';
import ViewProfile from './components/Auth/ViewProfile';
import Call from './components/Twilio/Call';

function App() {
  const queryClient = new QueryClient();


  return (
    <div className="h-screen bg-cover bg-center" style={{ backgroundImage: `url(${loginBg})` }}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/logout" element={<MasterLayout><PrivateRoute component={Logout} /></MasterLayout>} />
            <Route path='/updatepassword' element={<MasterLayout><PrivateRoute component={UpdatePassword} /></MasterLayout>} />
            <Route path='/viewprofile' element={<MasterLayout><PrivateRoute component={ViewProfile} /></MasterLayout>} />

            <Route path="/Dashboard" element={<MasterLayout><PrivateRoute component={AdminDashboard} /></MasterLayout>} />
            <Route path="/customerlist" element={<MasterLayout><PrivateRoute component={CustomerList} /></MasterLayout>} />
            <Route path="/tickets" element={<MasterLayout><PrivateRoute component={TicketList} /></MasterLayout>} />
            <Route path="/tickets/addTicket" element={<MasterLayout><PrivateRoute component={AddTicket} /></MasterLayout>} />
            <Route path="/tickets/editTicket/:id" element={<MasterLayout><PrivateRoute component={EditTicket} /></MasterLayout>} />
            <Route path="/tickets/archivedTickets" element={<MasterLayout><PrivateRoute component={ArchivedTicket} /></MasterLayout>} />
            <Route path="/customerInfrastructure" element={<MasterLayout><PrivateRoute component={CustomerInfraList} /></MasterLayout>} />
            <Route path="/customerInfrastructure/add" element={<MasterLayout><PrivateRoute component={AddCustomerInfra} /></MasterLayout>} />
            <Route path="/customerInfrastructure/add/:c_id" element={<MasterLayout><PrivateRoute component={AddCustomerInfra} /></MasterLayout>} />
            <Route path="/reports" element={<MasterLayout><PrivateRoute component={ReportContainer} /></MasterLayout>} />
            <Route path="/userlist" element={<MasterLayout><PrivateRoute component={UserList} /></MasterLayout>} />
            <Route path="/adduser" element={<MasterLayout><PrivateRoute component={AddUser} /></MasterLayout>} />
            <Route path="/edituser/:user_uuid" element={<MasterLayout><PrivateRoute component={EditUser} /></MasterLayout>} />
            <Route path='/addcustomer' element={<MasterLayout><PrivateRoute component={AddCustomer} /></MasterLayout>} />
            <Route path='/edit-customer/:customer_uuid' element={<MasterLayout><PrivateRoute component={EditCustomer} /></MasterLayout>} />
            <Route path='/call' element={<PrivateRoute component={Call} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;


