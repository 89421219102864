import { create } from "zustand";
import { persist } from "zustand/middleware";

const useUserStore = create(
  persist(
    (set) => ({
      user: null, // Add user data to the store
      userInfo: null,
      setUser: (user) => set({ user }), // Action to set user data
      setUserInfo:(userInfo) => {
        set({ userInfo }); // Update state
        // Persist userInfo to local storage
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
      }, // Action to set more user data
      clearUser: () => set({ user: null}), // Action to clear user data
    }),
    {
      name: "user-storage", // Name of the item in storage
      getStorage: () => localStorage, // Use localStorage to persist data
    }
  )
);

export default useUserStore;
