import React, { useEffect, useState } from 'react';
import useUserStore from '../../../store/UserStore';
import axios from 'axios';
import { BASE_URL } from '../../../constant/const';
import EditIcon from '../../../assets/images/Icons/edit.svg';
import DeleteIcon from '../../../assets/images/Icons/delete.svg';
import { toast, ToastContainer } from 'react-toastify';
import AddLocation from '../AddCustomer/Location/AddLocation';
import Swal from 'sweetalert2';
import EditStepper from './EditStepper';
import { t } from '../../Language/Translator';

const EditCustomerStep2 = ({ activeStep, setActiveStep, customerUuid }) => {
    const [locationData, setLocationData] = useState([]);
     const [loading, setLoading] = useState(true);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);
    const location_data = useUserStore((state) => state.user);
    const token = location_data;
    const customerId = localStorage.getItem('customerId-edit');
    const companyname = localStorage.getItem('companyname-edit')

    useEffect(() => {
        const getAllLocations = async () => {
            setLoading(true);
            try {
                const config = {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": token,
                    },
                };
                const response = await axios.get(`${BASE_URL}customer/fetchCustomerLocations/${customerId}`, config);
                setLocationData(response.data.data || []);
            } catch (error) {
                toast.error(error.message || "Error fetching locations");
            } finally {
                setLoading(false);
            }
        };

        getAllLocations();
    }, [customerId, token]);

    //--------------------------------------DELETE LOCATION RECORD------------------------------------------


    async function handleDeleteCustomerlocation(id) {
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": token,
                },
            };
            const response = await axios.delete(`${BASE_URL}customer/delete-customer-location/${customerId}/${id}`, config);
            toast.success(response.data.message);
        }
        catch (error) {
            if (error.response) {
                toast.warning(error.response.data.message);
            }
            else {
                toast.error(error.message);
            }
        }
    };


    function deleteCustomerlocationalert(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this customer!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#19C489',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                handleDeleteCustomerlocation(id);
                setLocationData(prevData => prevData.filter(item => item.id !== id));
                Swal.fire(
                    'Deleted!',
                    'Your item has been deleted.',
                    'success'
                );
            }
        });
    };






    const handleAddLocation = () => {
        setSelectedLocation(null); // Reset for adding new location
        setAddLocationModalOpen(true);
    };

    const handleEditCustomerLocation = (location) => {
        setSelectedLocation(location); // Set the selected location for editing
        setAddLocationModalOpen(true);
    };

    const updateLocationData = (updatedLocation) => {
        setLocationData(prevData => {
            const existingLocationIndex = prevData.findIndex(loc => loc.id === updatedLocation.id);
            if (existingLocationIndex > -1) {
                // Update existing location
                return prevData.map(loc => (loc.id === updatedLocation.id ? updatedLocation : loc));
            } else {
                // Add new location if it does not exist
                return [...prevData, updatedLocation];
            }
        });
    };

    const handleNext = (event) => {
        setActiveStep(2);
        event.preventDefault();
    };

    const handlePrevious = () => {
        setActiveStep(0);
    };

    return (
        <>
            <div className='mainBody'>
                <ToastContainer/>
                <div className="top_section">
                    <div className="pagesNamelink">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item"><a href="/dashboard">{t("admindashboard.dashboard")}</a></li>
                              <li className="breadcrumb-item"><a href="/customerlist">{t("customers.customers")}</a></li>
                                <li className="breadcrumb-item active text-white" aria-current="page">{t("customerstep1.editcustomer")}</li>
                            </ol>
                        </nav>
                        <h4 className="text-white">{t("customerstep2.editlocation")} {t("customerstep2.for")} {companyname}</h4>
                    </div>
                </div>
                <div className='mainSection mt-3 mb-20'>
                    <div>
                        <EditStepper activeStep={activeStep} setActiveStep={setActiveStep} />
                    </div>

                    <div className="tab-pane fade ml-4 mr-4 mt-5 text-white show active">
                        <div className="tab-pane flex flex-row justify-between fade ml-4 mr-4 mt-5 text-white show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">
                            <h3 className="wizardHead">{t("customerstep2.locations")}</h3>
                            <div className='mb-3'>
                                <button to="" className="main_btn" onClick={handleAddLocation}><i className="fa-solid fa-plus"></i>{t("customerstep2.addlocation")}</button>
                            </div>
                        </div>

                        <div className="mainTable table-responsive">
                            <table className="table table-striped table-dark">
                                <thead>
                                    <tr>
                                        <th>{t("customerstep2.id")}</th>
                                        <th>{t("customerstep2.locationtype")}</th>
                                        {/* <th>{t("customerstep2.companyname")}</th> */}
                                        <th>{t("customerstep2.phoneno")}</th>
                                        <th>{t("customerstep2.email")}</th>
                                        <th>{t("customerstep2.address")}</th>
                                        <th>{t("customerstep2.actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {locationData.map((location, index) => (
                                        <tr key={index}>
                                            <td>{location.id}</td>
                                            <td>{location.location_type}</td>
                                            {/* <td>{location.company_name}</td> */}
                                            <td>{location.phone}</td>
                                            <td>{location.email}</td>
                                            <td>{location.address}</td>
                                            <td>
                                                <div className="actionBtn flex gap-3 items-center">
                                                    <button className="Edit" onClick={() => handleEditCustomerLocation(location)}>
                                                        <img src={EditIcon} alt="Edit" />
                                                    </button>
                                                    <button className="Delete" onClick={() => deleteCustomerlocationalert(location.id)}>
                                                        <img src={DeleteIcon} alt="Delete" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="col-12 d-flex justify-content-end gap-3 mt-20">
                            <button onClick={handlePrevious} className={`cancel_btn`}>{t("customerstep2.previous")}</button>
                            <button onClick={handleNext} className={`secondary_btn`}>{t("customerstep2.next")}</button>
                        </div>
                    </div>
                </div>
            </div>
            {addLocationModalOpen && (
                <AddLocation
                    AddLocationModalOpen={addLocationModalOpen}
                    setAddLocationModalOpen={setAddLocationModalOpen}
                    customerUuid={customerUuid}
                    addNewLocation={updateLocationData}
                    selectedLocation={selectedLocation}
                />
            )}
        </>
    );
};

export default EditCustomerStep2;

