// import React, { useEffect, useState } from 'react';
// import useUserStore from '../../store/UserStore';
// import axios from 'axios';
// import { BASE_URL } from '../../constant/const';
// import { url } from '../../constant/const';
// import { toast, ToastContainer } from 'react-toastify';
// import Loader from '../commonForm/Loader';
// import Logo from '../../assets/images/dummypro.jpg';


// const ViewProfile = () => {

//     const [loading, setLoading] = useState(false);
//     const [firstName, setFirstName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [email, setEmail] = useState('');
//     const [phoneno, setPhoneno] = useState('');
//     const [alternetphoneno, setAlternetPhoneno] = useState('');
//     const [userType, setUserType] = useState('');
//     const [profileImage, setProfileImage] = useState(Logo);
//     const [selectedFile, setSelectedFile] = useState(null);

//     const user_data = useUserStore((state) => state.user);
//     const token = user_data;



//     async function fetchData() {

//         try {

//             setLoading(true);

//             const config = {
//                 headers: {
//                     'Content-type': 'application/json',
//                     Authorization: token,
//                 },

//             };

//             const response = await axios.get(`${BASE_URL}common/profile-view`, config);
//             toast.success(response.data.message);

//             const data = response.data.data;
//             setFirstName(data.first_name);
//             setLastName(data.last_name);
//             setEmail(data.email);
//             setPhoneno(data.phone);
//             setAlternetPhoneno(data.alternate_phone);
//             setUserType(data.user_type);
//             if (data.profilePic) {
//                 // setProfileImage(`${BASE_URL}${data.profilePic}`);
//                 setProfileImage(data.profilePic);
//             } else {
//                 setProfileImage(Logo); // Fallback to a default logo if no image
//             }
//             setLoading(false);

//         } catch (error) {

//             if (error.response) {
//                 toast.warning(error.response.data.message);
//             } else {
//                 toast.error(error.message);
//             }
//             setLoading(false);

//         }

//     }

//     useEffect(() => {
//         fetchData();
//     }, [token, BASE_URL]);



//     const handleSubmit = async (event) => {

//         event.preventDefault();

//         setLoading(true);

//         const formData = new FormData();
//         formData.append('first_name', firstName);
//         formData.append('last_name', lastName);
//         formData.append('phone', phoneno);
//         formData.append('alternate_phone', alternetphoneno);

//         if (selectedFile) {
//             formData.append('attachments', selectedFile);
//         }

//         try {

//             const config = {

//                 headers: {
//                     Authorization: token,
//                 },

//             };
//             const response = await axios.put(`${BASE_URL}common/profile-update`, formData, config);
//             toast.success(response.data.message);
//             // setProfileImage(response.data.data.profilePic);
//             if(selectedFile) {
//                 setProfileImage(URL.createObjectURL(selectedFile));
//             }
//             else if (response.data.data.profilePic)
//             {
//                 setProfileImage(response.data.data.profilePic)
//             }
//             else 
//             {
//                 setProfileImage(Logo);
//             }
//             setSelectedFile(null)
//             fetchData();
//         } catch (error) {
//             if (error.response) {
//                 toast.warning(error.response.data.message);
//             } else {
//                 toast.error(error.message);
//             }
//         } finally {
//             setLoading(false);
//         }

//     };



//     const handleImageChange = (event) => {

//         const file = event.target.files[0];
//         if (file) {
//             setSelectedFile(file);
//             setProfileImage(URL.createObjectURL(file));
//         }
//     };


//     return (

//         <>

//             <ToastContainer />
//             <div className="mainBody">
//                 <div className="top_section">
//                     <div className="pagesNamelink">
//                         <nav aria-label="breadcrumb">
//                             <ol className="breadcrumb">
//                                 <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
//                                 <li className="breadcrumb-item active text-white" aria-current="page">View Profile</li>
//                             </ol>
//                         </nav>
//                         <h4 className="text-white">View Profile</h4>
//                     </div>
//                 </div>

//                 <div className="mainSection">

//                     <div className="profile-image-container" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
//                         <input
//                             type="file"
//                             accept="image/*"
//                             onChange={handleImageChange}
//                             style={{ display: 'none' }}
//                             id="profileImageInput"
//                         />
//                         <label htmlFor="profileImageInput" style={{ cursor: 'pointer' }}>
//                             <img src={selectedFile ? URL.createObjectURL(selectedFile) : profileImage ? `${url}${profileImage}`: Logo} alt="Profile"className="profile-image"
//                                 style={{
//                                     borderRadius: '50%',
//                                     width: '150px',
//                                     height: '150px',
//                                     objectFit: 'cover',
//                                     border: '3px solid white',
//                                     boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
//                                 }}
//                             />
//                         </label>
//                     </div>

//                     <form onSubmit={handleSubmit} className="row g-3">
//                         <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                             <label className="form-label">First Name</label>
//                             <input type="text" className="form-control" placeholder="First Name"
//                                 value={firstName}
//                                 onChange={(e) => setFirstName(e.target.value)}
//                             />
//                         </div>
//                         <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                             <label className="form-label">Last Name</label>
//                             <input type="text" className="form-control" placeholder="Last Name"
//                                 value={lastName}
//                                 onChange={(e) => setLastName(e.target.value)}
//                             />
//                         </div>
//                         <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                             <label className="form-label">Email</label>
//                             <input type="text" className="form-control" placeholder="Email"
//                                 readOnly
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                             />
//                         </div>

//                         <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                             <label className="form-label">Phone No.</label>
//                             <input type="text" className="form-control" placeholder="Phone No."
//                                 value={phoneno}
//                                 onChange={(e) => setPhoneno(e.target.value)}
//                             />
//                         </div>
//                         <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                             <label className="form-label">Alternate Phone No.</label>
//                             <input type="text" className="form-control" placeholder="Alternate Phone No."
//                                 value={alternetphoneno}
//                                 onChange={(e) => setAlternetPhoneno(e.target.value)}
//                             />
//                         </div>
//                         <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
//                             <label className="form-label">User Type</label>
//                             <input type="text" className="form-control" placeholder="User Type"
//                                 readOnly
//                                 value={userType}
//                                 onChange={(e) => setUserType(e.target.value)}
//                             />
//                         </div>

//                         <div className="col-12 d-flex justify-content-end">
//                             {loading ? <Loader /> : ''}
//                             <button type="submit"
//                                 className={`secondary_btn ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
//                                 disabled={loading}
//                             >
//                                 Update
//                             </button>
//                         </div>
//                     </form>

//                 </div>

//             </div>

//         </>

//     );

// };
// export default ViewProfile;




import React, { useEffect, useState } from 'react';
import useUserStore from '../../store/UserStore';
import axios from 'axios';
import { BASE_URL } from '../../constant/const';
import { url } from '../../constant/const';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../commonForm/Loader';
import Logo from '../../assets/images/dummypro.jpg';


const ViewProfile = () => {

    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneno, setPhoneno] = useState('');
    const [alternetphoneno, setAlternetPhoneno] = useState('');
    const [userType, setUserType] = useState('');
    const [profileImage, setProfileImage] = useState(Logo);
    const [selectedFile, setSelectedFile] = useState(null);
    const [originalFirstName, setOriginalFirstName] = useState('');
    const [originalLastName, setOriginalLastName] = useState('');

    const user_data = useUserStore((state) => state.user);
    const token = user_data;



    async function fetchData() {

        try {

            setLoading(true);

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: token,
                },

            };

            const response = await axios.get(`${BASE_URL}common/profile-view`, config);
            toast.success(response.data.message);

            const data = response.data.data;
            setFirstName(data.first_name);
            setLastName(data.last_name);
            setEmail(data.email);
            setPhoneno(data.phone);
            setAlternetPhoneno(data.alternate_phone);
            setUserType(data.user_type);
            if (data.user_type === "customer") {
                setCompanyName(data.first_name); // Assuming the API returns company name
            }
            if (data.profilePic) {
                // setProfileImage(`${BASE_URL}${data.profilePic}`);
                setProfileImage(data.profilePic);
            } else {
                setProfileImage(Logo); // Fallback to a default logo if no image
            }
            setOriginalFirstName(data.first_name);
            setOriginalLastName(data.last_name);
            setLoading(false);

        } catch (error) {

            if (error.response) {
                toast.warning(error.response.data.message);
            } else {
                toast.error(error.message);
            }
            setLoading(false);

        }

    }

    useEffect(() => {
        fetchData();
    }, [token, BASE_URL]);


    const handleLogout = () => {
        localStorage.removeItem('user-storage'); // Clear localStorage
        toast.success("You have been logged out due to profile changes."); // Notify the user
        window.location.href = '/'; // Redirect to the login page
    };



    const handleSubmit = async (event) => {

        event.preventDefault();

        setLoading(true);

        const submitFirstName = userType === "customer" ? companyName : firstName;
        const formData = new FormData();
        formData.append('first_name', submitFirstName);
        formData.append('last_name', lastName);
        formData.append('phone', phoneno);
        formData.append('alternate_phone', alternetphoneno);

        if (selectedFile) {
            formData.append('attachments', selectedFile);
        }

        try {

            const config = {

                headers: {
                    Authorization: token,
                },

            };
            const response = await axios.put(`${BASE_URL}common/profile-update`, formData, config);
            toast.success(response.data.message);
            // setProfileImage(response.data.data.profilePic);
            if(selectedFile) {
                setProfileImage(URL.createObjectURL(selectedFile));
            }
            else if (response.data.data.profilePic)
            {
                setProfileImage(response.data.data.profilePic)
            }
            else 
            {
                setProfileImage(Logo);
            }
            setSelectedFile(null)
            if (submitFirstName !== originalFirstName || lastName !== originalLastName) {
                handleLogout(); // Automatically log out if names changed
            } else {
                fetchData(); // Reload user data if no changes to name
            }

        } catch (error) {
            if (error.response) {
                toast.warning(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        } finally {
            setLoading(false);
        }

    };



    const handleImageChange = (event) => {

        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setProfileImage(URL.createObjectURL(file));
        }
    };


    return (

        <>

            <ToastContainer />
            <div className="mainBody">
                <div className="top_section">
                    <div className="pagesNamelink">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                                <li className="breadcrumb-item active text-white" aria-current="page">View Profile</li>
                            </ol>
                        </nav>
                        <h4 className="text-white">View Profile</h4>
                    </div>
                </div>

                <div className="mainSection">

                    <div className="profile-image-container" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                            id="profileImageInput"
                        />
                        <label htmlFor="profileImageInput" style={{ cursor: 'pointer' }}>
                            <img src={selectedFile ? URL.createObjectURL(selectedFile) : profileImage ? `${url}${profileImage}`: Logo} alt="Profile"className="profile-image"
                                style={{
                                    borderRadius: '50%',
                                    width: '150px',
                                    height: '150px',
                                    objectFit: 'cover',
                                    border: '3px solid white',
                                    boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
                                }}
                            />
                        </label>
                    </div>

                    <form onSubmit={handleSubmit} className="row g-3">
                    {userType === "customer" ? (
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                <label className="form-label">Company Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Company Name"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                />
                            </div>
                        ) : (
                            <>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <label className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </>
                        )}
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">Email</label>
                            <input type="text" className="form-control" placeholder="Email"
                                readOnly
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">Phone No.</label>
                            <input type="text" className="form-control" placeholder="Phone No."
                                value={phoneno}
                                onChange={(e) => setPhoneno(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">Alternate Phone No.</label>
                            <input type="text" className="form-control" placeholder="Alternate Phone No."
                                value={alternetphoneno}
                                onChange={(e) => setAlternetPhoneno(e.target.value)}
                            />
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <label className="form-label">User Type</label>
                            <input type="text" className="form-control" placeholder="User Type"
                                readOnly
                                value={userType}
                                onChange={(e) => setUserType(e.target.value)}
                            />
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                            {loading ? <Loader /> : ''}
                            <button type="submit"
                                className={`secondary_btn ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={loading}
                            >
                                Update
                            </button>
                        </div>
                    </form>

                </div>

            </div>

        </>

    );

};
export default ViewProfile;